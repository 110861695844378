import React, { useContext, useState, useEffect } from "react";
import { Drawer, ANCHOR, SIZE } from "baseui/drawer";
import { useSearchParams } from "react-router-dom";
import { useDebouncedCallback } from "use-debounce";
import { useQuery } from "react-query";

import { MaxyfiContext } from "../../providers/MaxyfiProvider";
import { Tabs, Tab, FILL } from "baseui/tabs-motion";
import CustomerSearch from "./CustomerSearch/CustomerSearch";
import InvoiceSearch from "./InvoiceSearch/InvoiceSearch";
import { globalsearch } from "../../services/customerSummary";

function GlobalSearch_V2() {
  const [searchParams] = useSearchParams();

  let {
    isGlobalSearch,
    setGlobalFilter,
    currentOrganization,
    setGlobalSearchDebounce,
    debounceValue,
    currentDefaultFormatDetails,
    referenceData,
    setGlobalSelectBy,
    globalSelectBy,
  } = useContext(MaxyfiContext);

  console.log("sdhfjhsdf", globalSelectBy, isGlobalSearch.active_tab);

  const [searchValue, setSearchValue] = useState("");
  const [searchBy, setSearchBy] = useState();
  const [debouncedValue, setDebouncedValue] = useState("");

  useEffect(() => {
    if (
      currentDefaultFormatDetails &&
      currentDefaultFormatDetails.glo_srch_def
    ) {
      const searchTypes =
        referenceData && referenceData["customer_search_type"]
          ? referenceData["customer_search_type"]
          : [];
      const defaultType = searchTypes.find(
        (e) => e.id === currentDefaultFormatDetails.glo_srch_def
      );

      if (globalSelectBy && globalSelectBy.length) {
        setGlobalSelectBy(globalSelectBy);
      } else {
        setGlobalSelectBy(defaultType ? [defaultType] : [searchTypes[0]]);
      }
    }
    // return () => {
    //   setGlobalSelectBy();
    //   setGlobalFilter();
    // };
  }, [currentOrganization, isGlobalSearch.active_tab]);

  const debounceFilter = useDebouncedCallback((filter) => {
    setGlobalSearchDebounce(filter);
  }, 2000);

  useEffect(() => {
    if (isGlobalSearch && isGlobalSearch.value && isGlobalSearch.value.length) {
      debounceFilter(isGlobalSearch.value);
    }
  }, []);

  const { data, isFetched, isError, isLoading, refetch } = useQuery(
    [
      `GLOBAL_SEARCH_CUSTOMER_${debounceValue}_${currentOrganization}`,
      {
        value: debounceValue,
        ...(globalSelectBy && globalSelectBy[0]
          ? { searchBy: globalSelectBy[0].id }
          : {}),
      },
    ],
    async ({ queryKey }) => {
      let { value, searchBy } = queryKey[1];
      return debounceValue.length >= 1
        ? await globalsearch({
            organization: currentOrganization,
            pageSize: 20,
            entity: isGlobalSearch.active_tab == 0 ? "customer" : "invoices",
            filters: {
              search_value: value,
              search_by: searchBy,
            },
          })
        : null;
    },
    {
      refetchOnMount: false,
      refetchOnWindowFocus: false,
    }
  );

  useEffect(() => {
    const q = searchParams.get("q");
    if (q && q.length > 0) {
      debounceFilter(q);
      setGlobalFilter({ ...isGlobalSearch, value: q });
    }
  }, []);

  return (
    <div style={{ height: "100%", overflow: "hidden", width: "330px" }}>
      <Tabs
        fill={FILL.fixed}
        overrides={{
          Root: {
            style: ({ $isActive }) => ({
              height: "100%",
              display: "flex",
              flexDirection: "column",
            }),
          },
          TabBorder: {
            style: ({ $isActive }) => ({
              height: "2px",
            }),
          },
          TabList: {
            style: ({ $theme }) => ({
              height: "50px",
            }),
          },
          TabHighlight: {
            style: ({}) => ({
              borderRadius: "3px",
              bottom: "2px",
              height: "2px",
              backgroundColor: "#516BEB",
            }),
          },
        }}
        activeKey={isGlobalSearch.active_tab}
        onChange={({ activeKey }) => {
          setGlobalSelectBy(null);
          debounceFilter("");
          setGlobalFilter({ active_tab: activeKey, value: "" });
        }}
        activateOnFocus
      >
        <Tab
          title="Consumer Search"
          overrides={{
            Root: {
              style: () => ({}),
            },
            TabPanel: {
              style: () => ({
                paddingTop: "0px",
                paddingBottom: "0px",
                paddingLeft: "0px",
                paddingRight: "0px",
                overflow: "hidden",
              }),
            },
          }}
        >
          <CustomerSearch
            searchValue={searchValue}
            setSearchValue={setSearchValue}
            setSearchBy={setGlobalSelectBy}
            searchBy={globalSelectBy}
            debounceFilter={debounceFilter}
            isLoading={isLoading}
            data={data && data.data && data.data.docs ? data.data.docs : []}
          />
        </Tab>
        <Tab
          title="Invoice Search"
          overrides={{
            TabPanel: {
              style: () => ({
                paddingTop: "0px",
                paddingBottom: "0px",
                paddingLeft: "0px",
                paddingRight: "0px",
                overflow: "hidden",
              }),
            },
          }}
        >
          <InvoiceSearch
            searchValue={searchValue}
            setSearchValue={setSearchValue}
            setSearchBy={setGlobalSelectBy}
            searchBy={globalSelectBy}
            debounceFilter={debounceFilter}
            isLoading={isLoading}
            data={data && data.data && data.data.docs ? data.data.docs : []}
          />
        </Tab>
      </Tabs>
    </div>
  );
}

export default GlobalSearch_V2;
