import { ParagraphLarge } from "baseui/typography";
import React, { useContext, useEffect, useState, useMemo } from "react";
import { KIND, SIZE, TextButton } from "../TextButton";
import DownArrow from "../../utils/DownArrow";
import { PLACEMENT, StatefulPopover } from "baseui/popover";
import { Block } from "baseui/block";
import { Button } from "baseui/button";
import { MaxyfiContext } from "../../providers/MaxyfiProvider";
import {
  ALL_BUSINESS_UNITS,
  ALL_WORK_QUEUE,
} from "../../providers/RBACProvider/permissionList";
import { useDispatch } from "react-redux";
import { FormattedMessage } from "react-intl";
import { CLIENT_PORTAL_ACCESS } from "../../providers/RBACProvider/permissionList";
import { Search } from "baseui/icon";
import { TextBox } from "../TextBox";
import CheckBoxBaseweb from "../CheckBoxBaseweb";
import { EDIT, WORK_QUEUE } from "../../constants";
import { useLocation, useParams } from "react-router-dom";
import _ from "lodash";
import { Icon, Typography } from "../../components_v2";
import ClientGroupAAR from "./ClientGroupAAR";

let client_group_list = [
  {
    id: "66de8ee87ba6311dab3389d7",
    label: "Group 1",
    clnts: [
      "66cf08e2635fe8e497dbcd6c",
      "66cf08f4635fe8e497dbd077",
      "66cf08b9635fe8e497dbc4c4",
    ],
  },
  {
    id: "66de8ee87ba6311dab338967",
    label: "Group 2",
    clnts: ["66cf0900635fe8e497dbd332", "66d3f4b43cd9d84d97298b4f"],
  },
];

let busUnit = [
  {
    id: "66cf08b9635fe8e497dbc418",
    label: "ALL",
    commission_method: "DC",
    client_name: "ALL",
  },
  {
    id: "66cf08e2635fe8e497dbcd6c",
    label: "Agency Add",
    client_number: "",
    commission_method: "DCAA",
    support_email: "",
    admin_email: "",
    client_name: "Agency Add",
  },
  {
    id: "66cf08f4635fe8e497dbd077",
    label: "Client Add",
    client_number: "",
    commission_method: "DCCA",
    support_email: "",
    admin_email: "",
    client_name: "Client Add",
  },
  {
    id: "66cf08b9635fe8e497dbc4c4",
    label: "Client Billing",
    commission_method: "DC",
    client_name: "Client Billing",
  },
  {
    id: "66cf0900635fe8e497dbd332",
    label: "GloveTech",
    client_number: "",
    commission_method: "DC",
    support_email: "",
    admin_email: "",
    client_name: "GloveTech",
  },
  {
    id: "66d3f4b43cd9d84d97298b4f",
    label: "Test strgey",
    client_number: "",
    commission_method: "DCCA",
    support_email: "",
    admin_email: "",
    client_name: "Test strgey",
  },
  {
    id: "66dfe6f4794437fb7252911f",
    label: "Walmart",
    commission_method: "DC",
    client_name: "Walmart",
  },
];

function RoleAccessSelect({ pageIndex, isState = false }) {
  const {
    setFilterType,
    appAccessRest,
    myTeam,
    orgBusinessUnit,
    referenceData,
    orgWorkQueue,
    space,
    groupId,
  } = useContext(MaxyfiContext);
  const location = useLocation();
  const dispatch = useDispatch();

  const [isBusinessUnit, setIsBusinessUnit] = useState("");
  const [searchClient, setSearchClient] = useState("");
  // const [isSearchClientGroup, setIsSearchClientGroup] = useState("");
  const [isClientGroupColp, setIsClientGroupColp] = useState(false);
  const [searchWorkQueue, setSearchWorkQueue] = useState("");
  const [searchTeam, setSearchTeam] = useState("");
  // let partcheck = window.location.pathname;
  let isAllBusinessUnit =
    space &&
    space.role_permissions &&
    space.role_permissions.includes(ALL_BUSINESS_UNITS);
  let isAllWorkQueue =
    space &&
    space.role_permissions &&
    space.role_permissions.includes(ALL_WORK_QUEUE);
  let clientAccess =
    space &&
    space.role_permissions &&
    space.role_permissions.includes(CLIENT_PORTAL_ACCESS);
  const workQueueList = isAllWorkQueue
    ? referenceData["work_queue_list"]
    : orgWorkQueue &&
      orgWorkQueue?.map((wq) => {
        let findWorkQueueLabel = wq.wq
          ? referenceData["work_queue_list"].find((fnb) => fnb?.id == wq?.wq)
          : [];
        return (
          {
            label: _.get(findWorkQueueLabel, "label", ""),
            id: _.get(findWorkQueueLabel, "id", ""),
          } || []
        );
      });

  function clientCollapse() {
    setIsClientGroupColp(!isClientGroupColp);
    localStorage.setItem("group-collapse", !isClientGroupColp ? "YES" : "NO");
  }

  useEffect(() => {
    let getLoaclColp = localStorage.getItem("group-collapse");
    if (getLoaclColp === "YES") {
      setIsClientGroupColp(true);
    } else {
      setIsClientGroupColp(false);
    }
  }, []);

  useEffect(() => {
    if (clientAccess) {
      setFilterType({
        master_filter_type: "BUSINESS_UNIT",
      });
    }
  }, [clientAccess]);

  let businessUnitOp = isAllBusinessUnit
    ? referenceData["business_unit_list"]
    : orgBusinessUnit &&
      orgBusinessUnit?.map((bs) => {
        let findBusinessLabel = bs.business_unit_id
          ? referenceData["business_unit_list"].find(
              (fnb) => fnb?.id == bs?.business_unit_id
            )
          : [];
        return (
          {
            label: findBusinessLabel?.label,
            id: findBusinessLabel?.id,
          } || []
        );
      });
  const clientGroupWithDetails =
    referenceData && referenceData["client_group_list"]
      ? referenceData["client_group_list"].map((group) => {
          const updatedClients = group?.clnts
            ?.map((clientId) => {
              let findClient =
                businessUnitOp &&
                Array.isArray(businessUnitOp) &&
                businessUnitOp.find((unit) => unit.id === clientId);
              return findClient?.id ? findClient : null;
            })
            .filter((client) => client !== null); // Filter out nulls

          return {
            ...group,
            clnts: updatedClients.length > 0 ? updatedClients : [], // Return empty array if no valid client
          };
        })
      : [];

  useEffect(() => {
    if (
      businessUnitOp &&
      Array.isArray(businessUnitOp) &&
      appAccessRest?.master_filter_type == "BUSINESS_UNIT" &&
      appAccessRest?.master_filter_value?.length > 0
    ) {
      let findValue = businessUnitOp.find((e) => {
        return e?.id == appAccessRest?.master_filter_value?.[0];
      });
      setIsBusinessUnit(findValue?.label);
    } else if (
      myTeam &&
      appAccessRest?.master_filter_type == "TEAM" &&
      appAccessRest?.master_filter_value?.length > 0
    ) {
      let findValue = myTeam?.find((e) => {
        return e?.id == appAccessRest?.master_filter_value?.[0];
      });
      setIsBusinessUnit(findValue?.label);
    } else if (
      referenceData &&
      referenceData["work_queue_list"] &&
      appAccessRest?.master_filter_type == WORK_QUEUE &&
      appAccessRest?.master_filter_value?.length > 0
    ) {
      let findValue = referenceData["work_queue_list"].find((e) => {
        return e?.id == appAccessRest?.master_filter_value?.[0];
      });
      setIsBusinessUnit(findValue?.label);
    }
  }, [appAccessRest.master_filter_value]);

  const filteredClients = useMemo(() => {
    let checkedLabelsClients = [];
    let uncheckedLabelsClients = [];
    Array.isArray(businessUnitOp) &&
      businessUnitOp.filter((val) => {
        if (searchClient) {
          if (
            val &&
            val.label &&
            val.label.toLowerCase().includes(searchClient.toLowerCase())
          ) {
            if (appAccessRest?.master_filter_value.includes(val?.id)) {
              checkedLabelsClients.push({
                ...val,
              });
            } else {
              uncheckedLabelsClients.push({
                ...val,
              });
            }
          }
        } else {
          if (appAccessRest?.master_filter_value.includes(val?.id)) {
            checkedLabelsClients.push({
              ...val,
            });
          } else {
            uncheckedLabelsClients.push({
              ...val,
            });
          }
        }
      });
    checkedLabelsClients.sort((a, b) => {
      if (a.name < b.name) return -1;
      if (a.name > b.name) return 1;
      return 0;
    });

    uncheckedLabelsClients.sort((a, b) => {
      if (a.name < b.name) return -1;
      if (a.name > b.name) return 1;
      return 0;
    });
    return [...checkedLabelsClients, ...uncheckedLabelsClients] || [];
  }, [businessUnitOp, searchClient, appAccessRest?.master_filter_value]);

  const filteredClientGroup = useMemo(() => {
    let checkedLabelsClientGroup = [];
    let uncheckedLabelsClientGroup = [];
    Array.isArray(clientGroupWithDetails) &&
      clientGroupWithDetails.filter((val) => {
        if (searchClient) {
          if (
            val &&
            val.label &&
            val.label.toLowerCase().includes(searchClient.toLowerCase())
          ) {
            if (appAccessRest?.master_client_group_value?.includes(val?.id)) {
              checkedLabelsClientGroup.push({
                ...val,
              });
            } else {
              uncheckedLabelsClientGroup.push({
                ...val,
              });
            }
          }
        } else {
          if (appAccessRest?.master_client_group_value?.includes(val?.id)) {
            checkedLabelsClientGroup.push({
              ...val,
            });
          } else {
            uncheckedLabelsClientGroup.push({
              ...val,
            });
          }
        }
      });
    checkedLabelsClientGroup.sort((a, b) => {
      if (a.name < b.name) return -1;
      if (a.name > b.name) return 1;
      return 0;
    });

    uncheckedLabelsClientGroup.sort((a, b) => {
      if (a.name < b.name) return -1;
      if (a.name > b.name) return 1;
      return 0;
    });
    return [...checkedLabelsClientGroup, ...uncheckedLabelsClientGroup] || [];
  }, [
    clientGroupWithDetails,
    searchClient,
    appAccessRest?.master_client_group_value,
  ]);

  const filterWorkQueue = useMemo(() => {
    let checkedLabelsQueue = [];
    let uncheckedLabelsQueue = [];
    Array.isArray(workQueueList) &&
      workQueueList.filter((val) => {
        if (searchWorkQueue) {
          if (
            val &&
            val.label &&
            val.label.toLowerCase().includes(searchWorkQueue.toLowerCase())
          ) {
            if (appAccessRest?.master_filter_value.includes(val?.id)) {
              checkedLabelsQueue.push({
                ...val,
              });
            } else {
              uncheckedLabelsQueue.push({
                ...val,
              });
            }
          }
        } else {
          if (appAccessRest?.master_filter_value.includes(val?.id)) {
            checkedLabelsQueue.push({
              ...val,
            });
          } else {
            uncheckedLabelsQueue.push({
              ...val,
            });
          }
        }
      });
    checkedLabelsQueue.sort((a, b) => {
      if (a.name < b.name) return -1;
      if (a.name > b.name) return 1;
      return 0;
    });

    uncheckedLabelsQueue.sort((a, b) => {
      if (a.name < b.name) return -1;
      if (a.name > b.name) return 1;
      return 0;
    });
    return [...checkedLabelsQueue, ...uncheckedLabelsQueue] || [];
  }, [workQueueList, searchWorkQueue, appAccessRest?.master_filter_value]);

  const filteredTeam = useMemo(() => {
    let checkedLabelsTeam = [];
    let uncheckedLabelsTeam = [];
    myTeam.filter((val) => {
      if (searchTeam) {
        if (
          val &&
          val.label &&
          val.label.toLowerCase().includes(searchTeam.toLowerCase())
        ) {
          if (appAccessRest?.master_filter_value.includes(val?.id)) {
            checkedLabelsTeam.push({
              ...val,
            });
          } else {
            uncheckedLabelsTeam.push({
              ...val,
            });
          }
        }
      } else {
        if (appAccessRest?.master_filter_value.includes(val?.id)) {
          checkedLabelsTeam.push({
            ...val,
          });
        } else {
          uncheckedLabelsTeam.push({
            ...val,
          });
        }
      }
    });

    checkedLabelsTeam.sort((a, b) => {
      if (a.name < b.name) return -1;
      if (a.name > b.name) return 1;
      return 0;
    });

    uncheckedLabelsTeam.sort((a, b) => {
      if (a.name < b.name) return -1;
      if (a.name > b.name) return 1;
      return 0;
    });
    return [...checkedLabelsTeam, ...uncheckedLabelsTeam];
  }, [myTeam, searchTeam, appAccessRest?.master_filter_value]);
  const validRoutes = [
    "debt-collection-dashboard",
    "command-center",
    "invoices",
    "customers",
    "",
  ];

  const currentRoute = _.get(location, "pathname", "").substring(1);
  return (
    <div
      className={
        clientAccess && businessUnitOp && businessUnitOp.length > 1
          ? "acc-rest-container --acc_ablulate_bs"
          : "acc-rest-container --acc_ablulate"
      }
    >
      {clientAccess && businessUnitOp && businessUnitOp.length > 1 ? (
        <div
          className={`section-wraper ${
            appAccessRest?.master_filter_type === "BUSINESS_UNIT"
              ? "acc-rest-active"
              : !businessUnitOp
              ? "disable-field"
              : ""
          }`}
        >
          {appAccessRest?.master_filter_type === "BUSINESS_UNIT" &&
            appAccessRest?.master_filter_value?.length >= 2 && (
              <div className="acc-selected">
                {appAccessRest?.master_filter_value?.length - 1}
              </div>
            )}

          <ParagraphLarge
            onClick={() => {
              if (businessUnitOp && businessUnitOp.length >= 1) {
                setFilterType({
                  master_filter_type: "BUSINESS_UNIT",
                  //   master_filter_value: [],
                });
                if (pageIndex && !isState) {
                  dispatch(pageIndex(0));
                } else if (pageIndex && isState) {
                  pageIndex(0);
                }
              }
            }}
          >
            {appAccessRest?.master_filter_type === "BUSINESS_UNIT" &&
            appAccessRest?.master_filter_value?.length > 0 ? (
              isBusinessUnit
            ) : (
              <FormattedMessage id="business_unit">
                business_unit
              </FormattedMessage>
            )}
          </ParagraphLarge>

          <StatefulPopover
            onClick={() => {
              if (businessUnitOp && businessUnitOp.length >= 1) {
                setFilterType({
                  master_filter_type: "BUSINESS_UNIT",
                  //   master_filter_value: [],
                });
                if (pageIndex && !isState) {
                  dispatch(pageIndex(0));
                } else if (pageIndex && isState) {
                  pageIndex(0);
                }
              }
            }}
            content={() => (
              <div className="acc-rest_wraper">
                {businessUnitOp?.map((e) => {
                  return (
                    <ParagraphLarge
                      title={e?.label}
                      key={e?.id}
                      $style={
                        appAccessRest?.master_filter_value.includes(e?.id) && {
                          fontWeight: "700",
                        }
                      }
                      onClick={() => {
                        if (businessUnitOp && businessUnitOp.length >= 1) {
                          setFilterType({
                            master_filter_type: "BUSINESS_UNIT",
                            master_filter_value: e?.id,
                          });
                          if (pageIndex && !isState) {
                            dispatch(pageIndex(0));
                          } else if (pageIndex && isState) {
                            pageIndex(0);
                          }
                        }
                      }}
                    >
                      {e?.label}
                    </ParagraphLarge>
                  );
                })}
              </div>
            )}
            // overrides={{
            //   Body: {
            //     style: ({ $theme }) => ({
            //       outline: `${$theme.colors.warning200} solid`,
            //       backgroundColor: $theme.colors.warning200,
            //     }),
            //   },
            // }}
            placement={PLACEMENT.bottomRight}
            returnFocus
            autoFocus
          >
            {/* <Button kind={KIND.tertiary} size={SIZE.mini}>
              <DownArrow />
            </Button> */}
            <Icon icon="down_arrow" isPressable />
          </StatefulPopover>
        </div>
      ) : !clientAccess ? (
        <div
          className={`section-wraper ${
            appAccessRest?.master_filter_type === "BUSINESS_UNIT"
              ? "acc-rest-active"
              : !businessUnitOp
              ? "disable-field"
              : ""
          }`}
        >
          {appAccessRest?.master_filter_type === "BUSINESS_UNIT" &&
            appAccessRest?.master_filter_value?.length >= 2 && (
              <div className="acc-selected">
                {appAccessRest?.master_filter_value?.length - 1}
              </div>
            )}

          <ParagraphLarge
            onClick={() => {
              if (businessUnitOp && businessUnitOp.length >= 1) {
                setFilterType({
                  master_filter_type: "BUSINESS_UNIT",
                  //   master_filter_value: [],
                });
                if (pageIndex && !isState) {
                  dispatch(pageIndex(0));
                } else if (pageIndex && isState) {
                  pageIndex(0);
                }
              }
            }}
          >
            {appAccessRest?.master_filter_type === "BUSINESS_UNIT" &&
            appAccessRest?.master_filter_value?.length > 0 ? (
              isBusinessUnit
            ) : (
              <FormattedMessage id="business_unit">
                business_unit
              </FormattedMessage>
            )}
          </ParagraphLarge>

          <StatefulPopover
            onClick={() => {
              if (businessUnitOp && businessUnitOp.length >= 1) {
                setFilterType({
                  master_filter_type: "BUSINESS_UNIT",
                  //   master_filter_value: [],
                });
                if (pageIndex && !isState) {
                  dispatch(pageIndex(0));
                } else if (pageIndex && isState) {
                  pageIndex(0);
                }
              }
            }}
            content={() =>
              businessUnitOp &&
              businessUnitOp.length >= 1 && (
                <div className="acc-rest_wraper">
                  <div className="search-client-box">
                    <TextBox
                      value={searchClient}
                      autoFocus
                      size="mini"
                      placeholder="Search Client"
                      endEnhancer={
                        <Search size="18px" style={{ cursor: "pointer" }} />
                      }
                      onChange={(val) => {
                        let searchClientValue = val.target.value;
                        setSearchClient(searchClientValue);
                      }}
                    />
                  </div>
                  <div className="search-client-data">
                    {Array.isArray(filteredClientGroup) &&
                    filteredClientGroup.length ? (
                      <div style={{ display: "flex", alignItems: "center" }}>
                        {isClientGroupColp ? (
                          <Icon
                            icon="chevron_down"
                            isPressable
                            onClick={() => clientCollapse()}
                          />
                        ) : (
                          <Icon
                            icon="chevron_right"
                            isPressable
                            onClick={() => clientCollapse()}
                          />
                        )}
                        <Typography
                          type="p"
                          className="text-secondary"
                          style={{ margin: "5px 0px" }}
                        >
                          Groups
                        </Typography>
                      </div>
                    ) : (
                      <></>
                    )}
                    {isClientGroupColp ? (
                      <>
                        {filteredClientGroup &&
                          Array.isArray(filteredClientGroup) &&
                          filteredClientGroup.map((e, i) => {
                            return e &&
                              e.clnts &&
                              Array.isArray(e.clnts) &&
                              e?.clnts?.length > 0 ? (
                              <ClientGroupAAR
                                {...e}
                                indx={i}
                                clientGroupWithDetails={filteredClientGroup}
                                isState={isState}
                                pageIndex={pageIndex}
                              />
                            ) : (
                              <></>
                            );
                          })}
                      </>
                    ) : (
                      <></>
                    )}

                    {Array.isArray(filteredClients) &&
                    filteredClients.length ? (
                      <Typography
                        type="p"
                        className="text-secondary"
                        style={{ margin: "5px 0px" }}
                      >
                        Clients
                      </Typography>
                    ) : (
                      <></>
                    )}

                    {filteredClients &&
                      filteredClients.length > 0 &&
                      filteredClients?.map((e, i) => {
                        return (
                          <div
                            className="search-client-list"
                            style={
                              groupId?.disable_client_value?.includes(e?.id)
                                ? { cursor: "no-drop" }
                                : {}
                            }
                            onClick={() => {
                              if (
                                filteredClients &&
                                filteredClients.length >= 1
                              ) {
                                if (
                                  !groupId?.disable_client_value?.includes(
                                    e?.id
                                  )
                                ) {
                                  setFilterType({
                                    master_filter_type: "BUSINESS_UNIT",
                                    master_filter_value: e?.id,
                                  });
                                }

                                if (
                                  !appAccessRest?.master_filter_value?.includes(
                                    e?.id
                                  )
                                ) {
                                  filteredClients.splice(i, 1);
                                  filteredClients.unshift({
                                    ...e,
                                  });
                                }

                                if (
                                  appAccessRest?.master_filter_value?.includes(
                                    e?.id
                                  )
                                ) {
                                  filteredClients.splice(i, 1);
                                  filteredClients.push({
                                    ...e,
                                  });
                                }

                                if (pageIndex && !isState) {
                                  dispatch(pageIndex(0));
                                } else if (pageIndex && isState) {
                                  pageIndex(0);
                                }
                              }
                            }}
                          >
                            {e?.label && (
                              <>
                                <CheckBoxBaseweb
                                  checked={appAccessRest?.master_filter_value?.includes(
                                    e?.id
                                  )}
                                  disabled={groupId?.disable_client_value?.includes(
                                    e?.id
                                  )}
                                ></CheckBoxBaseweb>
                              </>
                            )}
                            <ParagraphLarge
                              title={e?.label}
                              key={e?.id}
                              style={{
                                fontWeight:
                                  appAccessRest?.master_filter_value.includes(
                                    e?.id
                                  )
                                    ? "700"
                                    : "",
                              }}
                            >
                              {e?.label}
                            </ParagraphLarge>
                          </div>
                        );
                      })}
                  </div>
                </div>
              )
            }
            placement={PLACEMENT.bottomRight}
            returnFocus
            autoFocus
          >
            {/* <Button kind={KIND.tertiary} size={SIZE.mini}>
              <DownArrow />
            </Button> */}
            <Icon icon="down_arrow" isPressable />
          </StatefulPopover>
        </div>
      ) : (
        <></>
      )}

      {!clientAccess && (
        <>
          {validRoutes.includes(currentRoute) && (
            <div
              className={`section-wraper ${
                appAccessRest?.master_filter_type === WORK_QUEUE
                  ? "acc-rest-active"
                  : !workQueueList
                  ? "disable-field"
                  : ""
              }`}
            >
              {appAccessRest?.master_filter_type === WORK_QUEUE &&
                appAccessRest?.master_filter_value?.length >= 2 && (
                  <div className="acc-selected">
                    {appAccessRest?.master_filter_value?.length - 1}
                  </div>
                )}

              <ParagraphLarge
                onClick={() => {
                  if (workQueueList && workQueueList.length >= 1) {
                    setFilterType({
                      master_filter_type: WORK_QUEUE,
                      //   master_filter_value: [],
                    });
                    if (pageIndex && !isState) {
                      dispatch(pageIndex(0));
                    } else if (pageIndex && isState) {
                      pageIndex(0);
                    }
                  }
                }}
              >
                {appAccessRest?.master_filter_type === WORK_QUEUE &&
                appAccessRest?.master_filter_value?.length > 0 ? (
                  isBusinessUnit
                ) : (
                  <FormattedMessage id="work_queue">
                    work_queue
                  </FormattedMessage>
                )}
              </ParagraphLarge>
              <StatefulPopover
                onClick={() => {
                  if (workQueueList && workQueueList.length >= 1) {
                    setFilterType({
                      master_filter_type: WORK_QUEUE,
                      //   master_filter_value: [],
                    });
                    if (pageIndex && !isState) {
                      dispatch(pageIndex(0));
                    } else if (pageIndex && isState) {
                      pageIndex(0);
                    }
                  }
                }}
                content={() =>
                  workQueueList &&
                  workQueueList.length >= 1 && (
                    <div className="acc-rest_wraper">
                      <div className="search-client-box">
                        <TextBox
                          value={searchWorkQueue}
                          autoFocus
                          size="mini"
                          placeholder="Search Work Queue"
                          endEnhancer={
                            <Search size="18px" style={{ cursor: "pointer" }} />
                          }
                          onChange={(val) => {
                            let searchClientValue = val.target.value;
                            setSearchWorkQueue(searchClientValue);
                          }}
                        />
                      </div>
                      <div className="search-client-data">
                        {filterWorkQueue &&
                          filterWorkQueue.length > 0 &&
                          filterWorkQueue?.map((e, i) => {
                            return (
                              <div
                                className="search-client-list"
                                onClick={() => {
                                  if (
                                    filterWorkQueue &&
                                    filterWorkQueue.length >= 1
                                  ) {
                                    setFilterType({
                                      master_filter_type: WORK_QUEUE,
                                      master_filter_value: e?.id,
                                    });
                                    if (
                                      !appAccessRest?.master_filter_value.includes(
                                        e?.id
                                      )
                                    ) {
                                      filterWorkQueue.splice(i, 1);
                                      filterWorkQueue.unshift({
                                        ...e,
                                      });
                                    }

                                    if (
                                      appAccessRest?.master_filter_value.includes(
                                        e?.id
                                      )
                                    ) {
                                      filterWorkQueue.splice(i, 1);
                                      filterWorkQueue.push({
                                        ...e,
                                      });
                                    }

                                    if (pageIndex && !isState) {
                                      dispatch(pageIndex(0));
                                    } else if (pageIndex && isState) {
                                      pageIndex(0);
                                    }
                                  }
                                }}
                              >
                                {e?.label && (
                                  <>
                                    <CheckBoxBaseweb
                                      checked={appAccessRest?.master_filter_value.includes(
                                        e?.id
                                      )}
                                    ></CheckBoxBaseweb>
                                  </>
                                )}
                                <ParagraphLarge
                                  key={e?.id}
                                  style={{
                                    fontWeight:
                                      appAccessRest?.master_filter_value.includes(
                                        e?.id
                                      )
                                        ? "700"
                                        : "",
                                  }}
                                >
                                  {e?.label}
                                </ParagraphLarge>
                              </div>
                            );
                          })}
                      </div>
                    </div>
                  )
                }
                placement={PLACEMENT.bottomRight}
                returnFocus
                autoFocus
              >
                {/* <Button kind={KIND.tertiary} size={SIZE.mini}>
              <DownArrow />
            </Button> */}
                <Icon icon="down_arrow" isPressable />
              </StatefulPopover>
            </div>
          )}
          <div
            className={`section-wraper ${
              appAccessRest.master_filter_type == "TEAM"
                ? "acc-rest-active"
                : myTeam.length == 0
                ? "disable-field"
                : " "
            }`}
          >
            {appAccessRest?.master_filter_type === "TEAM" &&
              appAccessRest?.master_filter_value.length >= 2 && (
                <div className="acc-selected">
                  {appAccessRest?.master_filter_value?.length - 1}
                </div>
              )}
            <ParagraphLarge
              onClick={() => {
                if (myTeam.length >= 1) {
                  setFilterType({
                    master_filter_type: "TEAM",
                  });
                  if (pageIndex && !isState) {
                    dispatch(pageIndex(0));
                  } else if (pageIndex && isState) {
                    pageIndex(0);
                  }
                }
              }}
            >
              {appAccessRest?.master_filter_type === "TEAM" &&
              appAccessRest?.master_filter_value?.length > 0 ? (
                isBusinessUnit
              ) : (
                <FormattedMessage id="my_team">my_team</FormattedMessage>
              )}
            </ParagraphLarge>
            <StatefulPopover
              onClick={() => {
                if (myTeam && myTeam.length >= 1) {
                  setFilterType({
                    master_filter_type: "TEAM",
                  });
                  if (pageIndex && !isState) {
                    dispatch(pageIndex(0));
                  } else if (pageIndex && isState) {
                    pageIndex(0);
                  }
                }
              }}
              content={() =>
                myTeam &&
                myTeam.length >= 1 && (
                  <div className="acc-rest_wraper">
                    <div className="search-client-box">
                      <TextBox
                        value={searchTeam}
                        autoFocus
                        size="mini"
                        placeholder="Search Team"
                        endEnhancer={
                          <Search size="18px" style={{ cursor: "pointer" }} />
                        }
                        onChange={(val) => {
                          let searchTeamValue = val.target.value;
                          setSearchTeam(searchTeamValue);
                        }}
                      />
                    </div>
                    <div className="search-client-data">
                      {filteredTeam &&
                        filteredTeam.length > 0 &&
                        filteredTeam?.map((e, i) => {
                          return (
                            <div
                              className="search-client-list"
                              onClick={() => {
                                if (filteredTeam && filteredTeam.length >= 1) {
                                  setFilterType({
                                    master_filter_type: "TEAM",
                                    master_filter_value: e?.id,
                                  });
                                  if (
                                    !appAccessRest?.master_filter_value.includes(
                                      e?.id
                                    )
                                  ) {
                                    filteredTeam.splice(i, 1);
                                    filteredTeam.unshift({
                                      ...e,
                                    });
                                  }

                                  if (
                                    appAccessRest?.master_filter_value.includes(
                                      e?.id
                                    )
                                  ) {
                                    filteredTeam.splice(i, 1);
                                    filteredTeam.push({
                                      ...e,
                                    });
                                  }

                                  if (pageIndex && !isState) {
                                    dispatch(pageIndex(0));
                                  } else if (pageIndex && isState) {
                                    pageIndex(0);
                                  }
                                }
                              }}
                            >
                              {e?.label && (
                                <>
                                  <CheckBoxBaseweb
                                    checked={appAccessRest?.master_filter_value.includes(
                                      e?.id
                                    )}
                                  ></CheckBoxBaseweb>
                                </>
                              )}
                              <ParagraphLarge
                                key={e?.id}
                                style={{
                                  fontWeight:
                                    appAccessRest?.master_filter_value.includes(
                                      e?.id
                                    )
                                      ? "700"
                                      : "",
                                }}
                              >
                                {e?.label}
                              </ParagraphLarge>
                            </div>
                          );
                        })}
                    </div>
                  </div>
                )
              }
              placement={PLACEMENT.bottomRight}
              returnFocus
              autoFocus
            >
              {/* <Button kind={KIND.tertiary} size={SIZE.mini}>
                <DownArrow />
              </Button> */}

              <Icon icon="down_arrow" isPressable />
            </StatefulPopover>
          </div>
          <div
            className={`section-wraper --slef-modifi ${
              appAccessRest?.master_filter_type == "SELF" && "acc-rest-active"
            }`}
          >
            <ParagraphLarge
              onClick={() => {
                setFilterType({
                  master_filter_type: "SELF",
                });
                if (pageIndex && !isState) {
                  dispatch(pageIndex(0));
                } else if (pageIndex && isState) {
                  pageIndex(0);
                }
              }}
            >
              <FormattedMessage id="self">self</FormattedMessage>
            </ParagraphLarge>
          </div>
        </>
      )}
    </div>
  );
}

export default RoleAccessSelect;
