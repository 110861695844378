import React, { useContext, useEffect } from "react";
import { NavLink, useParams, useSearchParams } from "react-router-dom";
import logo from "../../assets/img/logo.png";
import { Modal } from "baseui/modal";
import "./sideBar.css";
import warningImage from "../../assets/img/warning.png";
import DashBoard from "../../components/svg/DashBoard";
import Customer from "../../components/svg/Customer";
import Invo from "../../components/svg/Invo";
import CreditNoteIcon from "../../components/svg/CreditNotesIcon";
import Work from "../../components/svg/Work";
import { FormattedMessage, useIntl } from "react-intl";
import UploadIcon from "../../components/svg/UploadIcon";
import ProfileIcon from "../../components/svg/ProfileIcon";
import SettingsIcon from "../../components/svg/SettingsIcon";
import ReportIcon from "../../components/svg/ReportIcon";
import Misc from "../../assets/img/svg/Misc";
import { StatefulPopover, PLACEMENT, TRIGGER_TYPE } from "baseui/popover";
import { KIND, SIZE } from "baseui/button";
import { ParagraphSmall } from "baseui/typography";
import { TextButton } from "../../components/TextButton";
import { MaxyfiContext } from "../../providers/MaxyfiProvider";
import { ModalContext } from "../../providers/ModalProvider";
import { useLocation, useNavigate } from "react-router-dom";
import QuestionMark from "../../assets/img/svg/questionMark";
import OrgSwitchSync from "../../components/OrgSwitchSync/OrgSwitchSync";
import { useDispatch } from "react-redux";
import { useKeycloak } from "@react-keycloak/web";
import { Helmet } from "react-helmet";

import { setNavlinkReset } from "../../redux/customerSummary/action";
import { setInitialFormData } from "../../redux/invoiceSummary/action";
import { resetCreditNote } from "../../redux/CreditNote/action";
import Notifications from "../../assets/img/svg/Notifications";
import { useState } from "react";
import NotificationDrawer from "../../components/Notifications/NotificationDrawer";
import { notificationStatus } from "../../services/users";
import { useQuery } from "react-query";
import RBACWrapper from "../../providers/RBACProvider/RBACWrapper";
import {
  ACTIVITY_REPORT,
  ADHOC_UPLOAD,
  AGING_SUMMARY_REPORT,
  BUSINESS_UNIT_MAINTENANCE,
  CHECKIN_REPORT,
  CLIENT_DASHBOARD,
  CLIENT_PORTAL_ACCESS,
  COMMUNICATION_TEMPLATE_MAINTENANCE,
  CREDIT_LIMIT_REPORT,
  CREDIT_NOTE,
  CUSTOMERANALYSIS,
  CUSTOMER_OVERVIEW,
  Contact_Status_Maintenance,
  DASHBOARD,
  DISPUTE_REPORT,
  GLOBAL_SEARCH_PERMISSION,
  INVOICE_ITEM_MAINTENANCE,
  INVOICE_OVERVIEW,
  NEEDS_ACTION_REPORT,
  NOTES_REPORT,
  ORGANIZATION_SETTINGS,
  PAYMENT_PLAN_INSTALMENT,
  PAYMENT_PLAN_REPORT,
  PAYMENT_REPORT,
  PROMISE_REPORT,
  RECURRING_REPORT,
  REPORTS,
  REVENUE_BILLING,
  REVENUE_DASHBOARD,
  COMMAND_CENTER_DASHBOARD,
  ROLE_MAINTENANCE,
  SKIP_TRACE_REPORT,
  UPLOAD_CLIENT_CUSTOMER_INVOICE,
  USER_DASHBOARD,
  USER_MAINTENANCE,
  WORKFLOW_MAINTENANCE,
  LETTER_STATUS,
  DEBT_COLLECTION_DASHBOARD,
  SMS_ANALYTICS,
  HOLIDAY_BLACKLIST_MAINTENANCE,
  MINI_MIRANDA_CONFIGURATION,
  REQUEST_INFORMATION,
  LOCATION_TRACKER,
  COMPLIANCE_BREACH_REPORT,
  LEGAL_MOTION_REPORT,
  LEGAL_SUMMARY_REPORT,
  LEGAL_DEBT_REPORT,
  LEGAL_TIMESHEET_REPORT,
  REVIEW_GROUP_REPORT,
  CONSUMER_STATUS_MAINTENANANCE,
  VIEW_CONSUMER_STATUS,
  PERFORMANCE_DASHBOARD,
  MAINTAIN_KPI_ROLE,
  KPI_MENU_CODE,
  BANKRUPTCY_MAINTENANCE,
  CHECK_MAINTENANCE,
  STICKY_MAINTENANCE,
  ADJUSTMENT_REPORT,
  EMAIL_ANALYTICS,
  COM_UN_SUBSCRIBE_REPORT,
  DOWNLOAD_REPORT,
  ALL_REPORTS_DOWNLOAD_ACCESS,
  STRATEGY_MAINTAINANCE,
  REPORT_MAINTAINANCE_ALL,
} from "../../providers/RBACProvider/permissionList";
import SearchIcon from "../../components/svg/SearchIcon";
import DotSvg from "../../assets/img/svg/DotSvg";
import { setInitialCheckMtFormData } from "../../redux/checkMaintenance/action";
import getUserManualPath from "../../utils/getUserManualPath";

function IconCompo({ Icon, path, name, isClose, isOpen, ...rest }) {
  const dispatch = useDispatch();

  let { isGlobalSearch, setGlobalFilter } = useContext(MaxyfiContext);

  const [getSearchParams, setGetSearchParams] = useSearchParams();

  const getParams = getSearchParams.get("q");

  // useEffect(() => {
  //   if (getParams) {
  //     setGlobalFilter();
  //   }
  //   // return () => setIsOpens(false);
  // }, [getParams]);

  return rest.search ? (
    <div className="icon-parent">
      <div
        className="icon-child"
        onClick={() => {
          setGlobalFilter();
          if (isOpen) {
            isClose();
          }
        }}
      >
        <SearchIcon
          fill={isGlobalSearch ? "#fff" : "#787878"}
          className="icn"
        />
        {/* <Icon fill={rest.search ? "#fff" : "#000"} className="icn" /> */}
      </div>
    </div>
  ) : (
    <NavLink
      to={path}
      className={({ isActive }) =>
        isActive ? "icon-parent hoverclass" : "icon-parent"
      }
    >
      <div
        className="icon-child"
        onClick={() => {
          // dispatch(getInitialCustomerSummary());
          dispatch(setNavlinkReset());
          dispatch(setInitialFormData());
          dispatch(setInitialCheckMtFormData());
          dispatch(resetCreditNote());
          if (isOpen) {
            isClose();
          }
        }}
      >
        <Icon fill={"#F0F3F8"} className="icn" />
      </div>
    </NavLink>
  );
  // </StatefulTooltip>
}

function IconCompoPop({ userRole }) {
  const navigator = useNavigate();
  // const [gearActive, setGearActive] = useState(false)
  const { handleModal } = useContext(ModalContext);
  const { keycloak, initialized } = useKeycloak();

  let { currentDefaultFormatDetails, referenceData, space } =
    useContext(MaxyfiContext);

  const activityReport =
    space && space?.role_permissions?.includes(ACTIVITY_REPORT);

  const adjReport =
    space && space?.role_permissions?.includes(ADJUSTMENT_REPORT);

  const promiseReport =
    space && space?.role_permissions?.includes(PROMISE_REPORT);

  const disputeReport =
    space && space?.role_permissions?.includes(DISPUTE_REPORT);

  const paymentReport =
    space && space?.role_permissions?.includes(PAYMENT_REPORT);

  const notesReport = space && space?.role_permissions?.includes(NOTES_REPORT);

  const creditLimitReport =
    space && space?.role_permissions?.includes(CREDIT_LIMIT_REPORT);

  const agingSummaryReport =
    space && space?.role_permissions?.includes(AGING_SUMMARY_REPORT);
  const breachComplianceReport =
    space && space?.role_permissions?.includes(COMPLIANCE_BREACH_REPORT);
  const needsActionReport =
    space && space?.role_permissions?.includes(NEEDS_ACTION_REPORT);

  const checkInReport =
    space && space?.role_permissions?.includes(CHECKIN_REPORT);

  const recurringReport =
    space && space?.role_permissions?.includes(RECURRING_REPORT);

  const reviewGropingReport =
    space && space?.role_permissions?.includes(REVIEW_GROUP_REPORT);
  const unsubscribeReport =
    space && space?.role_permissions?.includes(COM_UN_SUBSCRIBE_REPORT);

  const userMaintenancePermission =
    space && space?.role_permissions?.includes(USER_MAINTENANCE);
  const letterStatus =
    space && space?.role_permissions?.includes(LETTER_STATUS);
  const roleMaintenancePermission =
    space && space?.role_permissions?.includes(ROLE_MAINTENANCE);

  const workflowMaintenancePermission =
    space && space?.role_permissions?.includes(WORKFLOW_MAINTENANCE);

  const communicationTemplateMaintenance =
    space &&
    space?.role_permissions?.includes(COMMUNICATION_TEMPLATE_MAINTENANCE);
  const strategicTemplateMaintenance =
    space && space?.role_permissions?.includes(STRATEGY_MAINTAINANCE);
  const reportTemplateMaintenance =
    space && space?.role_permissions?.includes(REPORT_MAINTAINANCE_ALL);
  console.log(reportTemplateMaintenance, "YUYUYUYUYU___");
  const invoiceItemMaintenance =
    space && space?.role_permissions?.includes(INVOICE_ITEM_MAINTENANCE);

  const revenueBilling =
    space && space?.role_permissions?.includes(REVENUE_BILLING);
  const businessUnitMaintenance =
    space && space?.role_permissions?.includes(BUSINESS_UNIT_MAINTENANCE);

  const reportDownloadPermission =
    space && space?.role_permissions?.includes(ALL_REPORTS_DOWNLOAD_ACCESS);
  const paymentPlanReport =
    space && space?.role_permissions?.includes(PAYMENT_PLAN_REPORT);
  const paymentPlanInstalmentReport =
    space && space?.role_permissions?.includes(PAYMENT_PLAN_INSTALMENT);

  const organizationSettings =
    space && space?.role_permissions?.includes(ORGANIZATION_SETTINGS);

  const contactStatusMain =
    space && space?.role_permissions?.includes(Contact_Status_Maintenance);
  const skiptraceReport =
    space && space?.role_permissions?.includes(SKIP_TRACE_REPORT);

  const holidayBlackListMaintenance =
    space && space?.role_permissions?.includes(HOLIDAY_BLACKLIST_MAINTENANCE);

  const miniMiranda =
    space && space.role_permissions.includes(MINI_MIRANDA_CONFIGURATION);

  const requestInformation =
    space && space.role_permissions.includes(REQUEST_INFORMATION);

  const locationTracker =
    space && space.role_permissions.includes(LOCATION_TRACKER);

  const consumerStatusManintenance =
    space && space.role_permissions.includes(VIEW_CONSUMER_STATUS);

  const kpiManitenance =
    space && space.role_permissions.includes(KPI_MENU_CODE);

  const legalMotionReport =
    space && space.role_permissions.includes(LEGAL_MOTION_REPORT);

  const legalSummaryReport =
    space && space.role_permissions.includes(LEGAL_SUMMARY_REPORT);

  const legalDebtReport =
    space && space.role_permissions.includes(LEGAL_DEBT_REPORT);

  const legalTimeSheetReport =
    space && space.role_permissions.includes(LEGAL_TIMESHEET_REPORT);

  const bankruptcyMaitenance =
    space && space.role_permissions.includes(BANKRUPTCY_MAINTENANCE);

  const checkMaintenance =
    space && space?.role_permissions?.includes(CHECK_MAINTENANCE);
  const stickyNotesMaintainence =
    space && space.role_permissions?.includes(STICKY_MAINTENANCE);
  let settingArray = [
    businessUnitMaintenance,
    revenueBilling,
    communicationTemplateMaintenance,
    workflowMaintenancePermission,
    organizationSettings,
    roleMaintenancePermission,
    userMaintenancePermission,
    holidayBlackListMaintenance,
    miniMiranda,
    consumerStatusManintenance,
    bankruptcyMaitenance,
    strategicTemplateMaintenance,
    reportTemplateMaintenance,
  ];

  let miscArray = [
    requestInformation,
    invoiceItemMaintenance,
    letterStatus,
    contactStatusMain,
    locationTracker,
  ];

  let settingResult = settingArray?.includes(true);
  let miscResult = miscArray?.includes(true);

  return (
    <>
      <StatefulPopover
        placement={PLACEMENT.right}
        content={[
          ...(activityReport
            ? [{ name: "Activity", path: "/report/activity" }]
            : []),
          ...(promiseReport
            ? [{ name: "Promise To Pay", path: "/report/promise-to-pay" }]
            : []),
          ...(disputeReport
            ? [{ name: "Dispute", path: "/report/dispute" }]
            : []),
          ...(paymentReport
            ? [{ name: "Payment", path: "/report/payment" }]
            : []),
          ...(notesReport ? [{ name: "Notes", path: "/report/note" }] : []),

          ...(creditLimitReport
            ? [{ name: "Credit Limit", path: "/report/credit-limit" }]
            : []),
          ...(agingSummaryReport
            ? [{ name: "Aging Summary", path: "/report/aging-summary" }]
            : []),
          ...(breachComplianceReport
            ? [
                {
                  name: "Breach Report ",
                  path: "/report/compliance-breach",
                },
              ]
            : []),
          ...(needsActionReport
            ? [{ name: "Needs Action", path: "/report/needs-action" }]
            : []),
          ...(paymentPlanReport
            ? [{ name: "Payment Plan", path: "/report/payment-plan" }]
            : []),
          ...(skiptraceReport
            ? [{ name: "Skip Trace", path: "/report/skip-trace" }]
            : []),
          ...(paymentPlanInstalmentReport
            ? [
                {
                  name: "Payment Plan Installment",
                  path: "/report/payment-plan-installment",
                },
              ]
            : []),
          ...(adjReport
            ? [{ name: "Adjustment Report", path: "/report/adjustment" }]
            : []),
          ...(currentDefaultFormatDetails.accounting_book === "CSV" &&
          checkInReport
            ? [{ name: "Checkin Report", path: "/report/checkin" }]
            : []),

          ...(currentDefaultFormatDetails.accounting_book === "CSV" &&
          recurringReport
            ? [{ name: "Recurring Report", path: "/report/recurring" }]
            : []),
          ...(legalMotionReport
            ? [{ name: "Legal Motion", path: "/report/legal-motion" }]
            : []),
          ...(legalTimeSheetReport
            ? [{ name: "Legal Timesheet", path: "/report/legal-timesheet" }]
            : []),
          ...(legalSummaryReport
            ? [{ name: "Legal Summary", path: "/report/legal-summary" }]
            : []),
          ...(legalDebtReport
            ? [{ name: "Legal Debt", path: "/report/legal-debt" }]
            : []),
          ...(currentDefaultFormatDetails.accounting_book === "CSV" &&
          reviewGropingReport
            ? [{ name: "Review Grouping", path: "/report/review-grouping" }]
            : []),
          ...(unsubscribeReport
            ? [{ name: "Unsubscribe Report", path: "/report/un-subscribe" }]
            : []),
          ...(reportDownloadPermission
            ? [{ name: "Report Download", path: "/report-download" }]
            : []),
          ...(referenceData["custom_reports"] || []).map((report) => ({
            name: report.label,
            path: `/report/${report.id}`,
          })),
        ].map((e) => (
          <ParagraphSmall
            $style={{
              cursor: "pointer",
              padding: "10px",
              // textAlign: "center"
            }}
            className="org-name"
            onClick={() => {
              navigator(e.path);
            }}
          >
            {e.name}
          </ParagraphSmall>
        ))}
        accessibilityType={"tooltip"}
        triggerType={TRIGGER_TYPE.hover}
        overrides={{
          Inner: {
            style: () => ({
              backgroundColor: "#FFFFFF",
              borderRadius: "5px",
              background: "#ffffff",
              border: "0.5px solid #CDCED9",
            }),
          },
          Body: {
            style: () => ({
              backgroundColor: "#FFFFFF",
              borderRadius: "5px",
              zIndex: 2,
              maxHeight: "400px",
              overflowY: "scroll",
            }),
          },
          Arrow: {
            style: ({ $theme }) => ({
              // zIndex: "9999",
            }),
          },
        }}
      >
        <div className="multi-apotion">
          <RBACWrapper role={REPORTS} type="PERMISSION">
            <div className="icon-child --trip_dot">
              <ReportIcon />
              <div className="drop-down">
                <DotSvg />
              </div>
            </div>
          </RBACWrapper>
        </div>
      </StatefulPopover>

      <StatefulPopover
        placement={PLACEMENT.right}
        content={[
          ...(businessUnitMaintenance
            ? [{ name: "Business_Unit_Maintenance", path: "/business-unit" }]
            : []),
          ...(revenueBilling
            ? [{ name: "Revenue_and_Billing", path: "/revenue-billing" }]
            : []),
          ...(requestInformation
            ? [
                {
                  name: "request_information_title",
                  path: "/request-information",
                },
              ]
            : []),
          ...(contactStatusMain
            ? [
                {
                  name: "contact_status_maintenane",
                  path: "/contact-status-maintenance",
                },
              ]
            : []),
          ...(letterStatus
            ? [{ name: "Letter Status Maintenance", path: "/letterStatus" }]
            : []),
          ...(bankruptcyMaitenance
            ? [
                {
                  name: "bankruptcy_maintenance",
                  path: "/bankruptcy-maintenance",
                },
              ]
            : []),
          ...(checkMaintenance
            ? [{ name: "check_maintenance", path: "/nacha-maintenance" }]
            : []),

          ...(stickyNotesMaintainence
            ? [
                {
                  name: "sticky_notes_maintenance",
                  path: "/sticky-notes-maintenance",
                },
              ]
            : []),

          ...(locationTracker
            ? [
                {
                  name: "Location Tracking",
                  path: "/location-tracking",
                },
              ]
            : []),
        ].map((e) => (
          <ParagraphSmall
            $style={{
              cursor: "pointer",
              padding: "10px",
              // textAlign: "center"
            }}
            className="org-name"
            onClick={() => {
              navigator(e.path);
            }}
          >
            <FormattedMessage id={e.name}>{e.name}</FormattedMessage>
          </ParagraphSmall>
        ))}
        accessibilityType={"tooltip"}
        triggerType={TRIGGER_TYPE.hover}
        overrides={{
          Inner: {
            style: () => ({
              backgroundColor: "#FFFFFF",
              borderRadius: "5px",
              background: "#ffffff",
              border: "0.5px solid #CDCED9",
            }),
          },
          Body: {
            style: () => ({
              backgroundColor: "#FFFFFF",
              borderRadius: "5px",
              zIndex: 2,
            }),
          },
          Arrow: {
            style: ({ $theme }) => ({
              // zIndex: "9999",
            }),
          },
        }}
      >
        <div className="multi-apotion">
          {/* <RBACWrapper role={REPORTS} type="PERMISSION"> */}
          {miscResult ? (
            <div className="icon-child --trip_dot">
              <Misc size={20} />
              <div className="drop-down">
                <DotSvg />
              </div>
            </div>
          ) : (
            <></>
          )}
          {/* </RBACWrapper> */}
        </div>
      </StatefulPopover>

      <StatefulPopover
        content={[
          ...(communicationTemplateMaintenance
            ? [{ name: "Communication_Template", path: "/templates" }]
            : []),
          ...(workflowMaintenancePermission
            ? [{ name: "Workflow_Maintenance", path: "/workflows" }]
            : []),
          ...(strategicTemplateMaintenance
            ? [{ name: "Strategy Maintenance", path: "/strategy-maintenance" }]
            : []),
          ...(reportTemplateMaintenance
            ? [{ name: "Report Maintenance", path: "/report-maintenance" }]
            : []),
          ...(holidayBlackListMaintenance
            ? [
                {
                  name: "holiday_blackout_maintenance",
                  path: "/holiday-blackout-maintenance",
                },
              ]
            : []),
          ...(consumerStatusManintenance
            ? [
                {
                  name: "consumer_status_maintenance",
                  path: "/consumer-status-maintenance",
                },
              ]
            : []),
          ...(miniMiranda
            ? [{ name: "mini_miranda", path: "/mini-miranda-configuration" }]
            : []),

          ...(kpiManitenance
            ? [
                {
                  name: "kpi_maintenance",
                  path: "/kpi_maintenance",
                },
              ]
            : []),
          ...((currentDefaultFormatDetails.accounting_book === "CSV" ||
            currentDefaultFormatDetails.accounting_book === "ROTESSA") &&
          invoiceItemMaintenance
            ? [{ name: "Invoice_Item", path: "/invoice-item" }]
            : []),
          ...(roleMaintenancePermission
            ? [{ name: "Role_Maintenance", path: "/role-maintenance" }]
            : []),
          ...(userMaintenancePermission
            ? [{ name: "User_Maintenance", path: "/users" }]
            : []),
          ...(organizationSettings
            ? [{ name: "Organization_Settings", path: "/settings" }]
            : []),
        ].map((e) => (
          <ParagraphSmall
            $style={{
              cursor: "pointer",
              padding: "10px",
              // textAlign: "center"
            }}
            // className={e.name === "Organization Settings" && "org-name"}
            className="org-name"
            onClick={() => {
              navigator(e.path);
            }}
          >
            <FormattedMessage id={e.name}>{e.name}</FormattedMessage>
          </ParagraphSmall>
        ))}
        accessibilityType={"tooltip"}
        triggerType={TRIGGER_TYPE.hover}
        overrides={{
          Inner: {
            style: () => ({
              backgroundColor: "#FFFFFF",
              borderRadius: "5px",
              background: "#ffffff",
              border: "0.5px solid #CDCED9",
            }),
          },
          Body: {
            style: () => ({
              backgroundColor: "#FFFFFF",
              borderRadius: "5px",
              zIndex: 2,
            }),
          },
          Arrow: {
            style: ({ $theme }) => ({
              // zIndex: "9999",
            }),
          },
        }}
      >
        {/* {userRole && userRole.label === "Super Admin" && ( */}
        <div className="multi-apotion">
          {/* <RBACWrapper
            role={
              INVOICE_ITEM_MAINTENANCE ||
              BUSINESS_UNIT_MAINTENANCE ||
              REVENUE_BILLING ||
              COMMUNICATION_TEMPLATE_MAINTENANCE ||
              WORKFLOW_MAINTENANCE ||
              ORGANIZATION_SETTINGS ||
              ROLE_MAINTENANCE ||
              USER_MAINTENANCE
            }
            type="PERMISSION"
          > */}
          {settingResult ? (
            <div className="icon-child --trip_dot">
              <SettingsIcon />
              <div className="drop-down">
                <DotSvg />
              </div>
            </div>
          ) : (
            <></>
          )}
        </div>
        {/* )} */}
      </StatefulPopover>

      <StatefulPopover
        content={[
          { name: "Profile", path: "/profile" },
          { name: "Logout", path: "logout" },
        ].map((e) => (
          <ParagraphSmall
            $style={{
              cursor: "pointer",
              padding: "10px 20px 10px 10px",
              // textAlign: "left",
            }}
            className={e.name === "Profile" && "org-name"}
            onClick={() => {
              if (e.path === "logout") {
                handleModal({
                  title: "You are about to Logout?",
                  // content: "This will Take you to Home Page",
                  successCallback: () => {
                    // localStorage.removeItem("rT");
                    // window.location.href = "https://maxyfi.com";
                    keycloak.logout({ redirectUri: "https://www.maxyfi.com" });
                  },
                  formContent: () => {
                    return <></>;
                  },
                  cancelCallback: () => {},
                  isChildren: false,
                  image: warningImage,
                  buttonText: "Yes",
                  closeButtonText: "Cancel",
                  isCloseAble: false,
                });
              } else {
                navigator(e.path);
              }
            }}
          >
            {e.name}
          </ParagraphSmall>
        ))}
        accessibilityType={"tooltip"}
        triggerType={TRIGGER_TYPE.hover}
        overrides={{
          Inner: {
            style: () => ({
              backgroundColor: "#FFFFFF",
              borderRadius: "5px",
              background: "#ffffff",
              border: "0.5px solid #CDCED9",
            }),
          },
          Body: {
            style: () => ({
              backgroundColor: "#FFFFFF",
              borderRadius: "5px",
              top: "-10px",
              zIndex: 2,
            }),
          },
          Arrow: {
            style: ({ $theme }) => ({
              // zIndex: "9999",
            }),
          },
        }}
      >
        <div className="multi-apotion">
          <div className="icon-child --trip_dot">
            <ProfileIcon />
            <div className="drop-down">
              <DotSvg />
            </div>
          </div>
        </div>
      </StatefulPopover>
    </>
  );
}

export const ContactModal = (props) => {
  const { space, currentDefaultFormatDetails } = useContext(MaxyfiContext);
  const isClientLogin =
    space && space?.role_permissions?.includes(CLIENT_PORTAL_ACCESS);

  return (
    <>
      <Modal
        isOpen={props.isOpen}
        // onClose={props.close}
        overrides={{
          Root: {
            style: ({ $theme }) => ({
              textAlign: "center",
            }),
          },
          Close: {
            style: ({ $theme }) => ({
              display: "none",
            }),
          },
          Dialog: {
            style: ({ $theme }) => ({
              padding: "20px 10px",

              width: "600px",
              borderTopLeftRadius: "5px",
              borderTopRightRadius: "5px",
              borderBottomLeftRadius: "5px",
              borderBottomRightRadius: "5px",
              boxShadow: "0px 4px 4px 0px #33386050 ",
            }),
          },
        }}
      >
        <div
          style={{
            fontSize: "15px",
            fontWeight: "400",
            color: "#333860",
            paddingBottom: "15px",
          }}
        >
          Email your Concerns / Queries to{" "}
          <span
            style={{
              fontSize: "15px",
              fontWeight: "700",
              color: "#333860",
            }}
          >
            {isClientLogin
              ? currentDefaultFormatDetails?.client_support_email
              : currentDefaultFormatDetails?.support_email}
          </span>
        </div>
        <div
          style={{
            fontSize: "15px",
            fontWeight: "400",
            color: "#333860",
            paddingBottom: "15px",
          }}
        >
          To assist us in resolving the issue, kindly attach a screenshot of the
          error message and provide a concise description of the problem
        </div>
        <div
          style={{
            width: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <div
            style={{
              width: "120px",
            }}
          >
            <TextButton
              // kind={KIND.tertiary}
              kind={KIND.primary}
              size={SIZE.compact}
              fullWidth
              onClick={() => props.setIsOpen(false)}
            >
              Close
            </TextButton>
          </div>
        </div>
      </Modal>
    </>
  );
};

function SideBar(props) {
  let location = useLocation();
  const navigator = useNavigate();
  const { customerId } = useParams();

  let maxyfiContextValue = useContext(MaxyfiContext);

  const {
    currentOrganization,
    referenceData,
    switchCurrentOrganization,
    users,
    userPersonalization,
    userInfo,
    space,
    currentDefaultFormatDetails,
    appAccessRest,
  } = maxyfiContextValue;

  const adhocUploadPermission =
    space && space?.role_permissions?.includes(ADHOC_UPLOAD);

  const clientUploadPermission =
    space && space?.role_permissions?.includes(UPLOAD_CLIENT_CUSTOMER_INVOICE);

  const creditNotePermission =
    space && space?.role_permissions?.includes(CREDIT_NOTE);

  const invoicePermission =
    space && space?.role_permissions?.includes(INVOICE_OVERVIEW);

  const customerPermission =
    space && space?.role_permissions?.includes(CUSTOMER_OVERVIEW);

  const dashboardPermission =
    space && space?.role_permissions?.includes(USER_DASHBOARD);

  const revenueDashboardPermission =
    space && space?.role_permissions?.includes(REVENUE_DASHBOARD);
  const commandCenterDashboardPermission =
    space && space?.role_permissions?.includes(COMMAND_CENTER_DASHBOARD);

  const customerAnalysis =
    space && space?.role_permissions?.includes(CUSTOMERANALYSIS);
  const globalsearchpermission =
    space && space?.role_permissions?.includes(GLOBAL_SEARCH_PERMISSION);

  const bussinessDasboard =
    space && space?.role_permissions?.includes(CLIENT_DASHBOARD);

  const collectionDashboard =
    space && space?.role_permissions?.includes(DEBT_COLLECTION_DASHBOARD);

  const performanceDashboard =
    space && space?.role_permissions?.includes(PERFORMANCE_DASHBOARD);

  const smsAnalyticsDashboard =
    space && space?.role_permissions?.includes(SMS_ANALYTICS);
  const emailAnalyticsDashboard =
    space && space?.role_permissions?.includes(EMAIL_ANALYTICS);
  const isClientLogin =
    space && space?.role_permissions?.includes(CLIENT_PORTAL_ACCESS);

  const [isOpen, setIsOpen] = React.useState(false);
  const [notificationIsOpen, setNotificationIsOpen] = React.useState(false);

  const [isCount, setIsCount] = React.useState(0);

  const [isNotifiCount, setIsNotifiCount] = useState("");
  function close() {
    setIsOpen(!isOpen);
  }
  function notificationClose() {
    setNotificationIsOpen(!notificationIsOpen);
  }

  // useEffect(async () => {
  //   await notificationCount({ org_id: currentOrganization }).then((res) => {
  //     setIsNotifiCount(res);
  //   });
  //   try {
  //     setInterval(async () => {
  //       await notificationCount({ org_id: currentOrganization }).then((res) => {
  //         setIsNotifiCount(res);
  //       });
  //     }, 60000);
  //   } catch (e) {
  //
  //   }
  // }, []);

  const { isLoading, data, refetch, isFetching } = useQuery(
    [
      `notificationCount ${currentOrganization}`,
      {
        filters: {
          ...appAccessRest,
          statusType: "bell",
        },
      },
    ],
    async ({ queryKey }) => {
      let { filters } = queryKey[1];
      return await notificationStatus({
        org_id: currentOrganization,
        filters,
      });
    },
    {
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      refetchIntervalInBackground: true,
      refetchInterval: 120000,
    }
  );

  // const [state, setState] = React.useState(false);

  const userRole = referenceData?.user_role?.find(
    ({ id }) => id === userInfo?.role
  );

  const HELP_CENTER = process.env.REACT_APP_USER_MANUAL;

  const openInNewTab = (url) => {
    window.open(url, "_blank", "noopener,noreferrer");
  };

  let AllValues = [
    // ...(dashboardPermission
    //   ? [
    //       {
    //         Icon: DashBoard,
    //         path: "/",
    //         name: "Dashboard",
    //         order: 1,
    //         isClose: close,
    //       },
    //     ]
    //   : []),

    ...(customerPermission
      ? [
          {
            Icon: Customer,
            path: "/customers",
            name: "Customers",
            order: 2,
            isClose: close,
          },
        ]
      : []),
    ...(invoicePermission
      ? [
          {
            Icon: Invo,
            path: "/invoices",
            name: "Invoices",
            order: 3,
            isClose: close,
          },
        ]
      : []),

    // { Icon: Work, path: "/workflows", name: "Workflows", order: 5 },
    // {
    //   Icon: TemplatesIcon,
    //   path: "/templates",
    //   name: "Templates",
    //   order: 6,
    // },

    ...(adhocUploadPermission
      ? [
          {
            Icon: UploadIcon,
            path: "/uploads",
            name: "Upload",
            order: 7,
            isClose: close,
          },
        ]
      : []),
    ...(clientUploadPermission
      ? [
          {
            Icon: UploadIcon,
            path: "/client-uploads",
            name: "Client Upload",
            order: 8,
            isClose: close,
          },
        ]
      : []),
    ...(globalsearchpermission
      ? [
          {
            Icon: SearchIcon,
            path: "",
            name: "Search",
            order: 1,
            search: true,
            isClose: close,
          },
        ]
      : []),
  ];

  const csvRouteList = [];

  const accountingRouteList = [
    ...(creditNotePermission
      ? [
          {
            Icon: CreditNoteIcon,
            path: "/credit-notes",
            name: "Credit Notes",
            order: 4,
            isClose: close,
          },
        ]
      : []),
  ];
  const accountingRouteListS = [
    ...(creditNotePermission
      ? [
          {
            Icon: CreditNoteIcon,
            path: "/credit-notes",
            name: "New DashBoard",
            order: 4,
            isClose: close,
          },
        ]
      : []),
  ];

  // let destination = location.pathname.split("/");
  // let checkingDestination =
  //   destination[1] === "" ? "dashboard" : destination[1];
  // openInNewTab(`${HELP_CENTER}${checkingDestination}`);

  const findCurrentOrgName = referenceData?.organizations?.find(
    ({ id }) => id === currentOrganization
  );

  let intl = useIntl();
  let dispatch = useDispatch();

  return (
    <>
      <ContactModal isOpen={isOpen} close={close} setIsOpen={setIsOpen} />

      <Helmet>
        <meta charSet="utf-8" />
        <title>
          {currentDefaultFormatDetails && currentDefaultFormatDetails.label}
        </title>
        <link
          rel="icon"
          href={
            currentDefaultFormatDetails && currentDefaultFormatDetails.org_logo
          }
        />
      </Helmet>
      {/* <ContactModal isOpen={isOpen} close={close} setIsOpen={setIsOpen} /> */}
      <div
        className="sidebar-wrapper"
        style={{ backgroundColor: isClientLogin ? "#001964" : "#2a3042" }}
      >
        <div className="sidebar-top">
          <div className="siderbar_logo_fix">
            <img
              src={
                currentDefaultFormatDetails &&
                currentDefaultFormatDetails.org_logo
                  ? currentDefaultFormatDetails.org_logo
                  : logo
              }
              alt=""
              style={{ width: "100%" }}
            />
          </div>
          <div className="sidebar-switch__container">
            <OrgSwitchSync />
          </div>
        </div>

        <div className="sidebar-middle">
          {/* {findCurren
          tOrgName?.accounting_book === "maxyfi"
            ? */}

          <StatefulPopover
            placement={PLACEMENT.right}
            content={[
              ...(dashboardPermission
                ? [
                    {
                      path: "/",
                      name: "Dashboard",
                      order: 1,
                      isClose: close,
                    },
                  ]
                : []),
              ...(bussinessDasboard
                ? [
                    {
                      path: "/business-dashboard",
                      name: "Client Dashboard",
                      order: 2,
                      isClose: close,
                    },
                  ]
                : []),
              ...(customerAnalysis
                ? [
                    {
                      path: "/CustomerAnalysis",
                      name: " Consumer Action Analysis",
                      isClose: close,
                    },
                  ]
                : []),
              ...(smsAnalyticsDashboard
                ? [
                    {
                      path: "/sms-analytics",
                      name: "SMS Analytics",
                      isClose: close,
                    },
                  ]
                : []),

              ...(revenueDashboardPermission
                ? [
                    {
                      name: "Revenue Dashboard",
                      path: "/revenue-dashboard",
                      isClose: close,
                    },
                  ]
                : []),
              ...(commandCenterDashboardPermission
                ? [
                    {
                      name: "Command Center Dashboard",
                      path: "/command-center",
                      isClose: close,
                    },
                  ]
                : []),
              ...(collectionDashboard
                ? [
                    {
                      name: "Debt Collection Dashboard",
                      path: "/debt-collection-dashboard",
                      isClose: close,
                    },
                  ]
                : []),

              ...(performanceDashboard
                ? [
                    {
                      name: "Performance Dashboard",
                      path: "/performance-dashboard",
                      isClose: close,
                    },
                  ]
                : []),
              ...(emailAnalyticsDashboard
                ? [
                    {
                      path: "/email-analytics",
                      name: "Email Analytics",
                      isClose: close,
                    },
                  ]
                : []),
            ].map((e) => (
              <ParagraphSmall
                $style={{
                  cursor: "pointer",
                  padding: "10px",
                  // textAlign: "center"
                }}
                className="org-name"
                onClick={() => {
                  navigator(e.path);
                  dispatch(setNavlinkReset());
                  dispatch(setInitialFormData());
                  dispatch(setInitialCheckMtFormData());
                }}
              >
                {e.name}
              </ParagraphSmall>
            ))}
            accessibilityType={"tooltip"}
            triggerType={TRIGGER_TYPE.hover}
            overrides={{
              Inner: {
                style: () => ({
                  backgroundColor: "#FFFFFF",
                  borderRadius: "5px",
                  background: "#ffffff",
                  border: "0.5px solid #CDCED9",
                }),
              },
              Body: {
                style: () => ({
                  backgroundColor: "#FFFFFF",
                  borderRadius: "5px",
                  zIndex: 2,
                }),
              },
              Arrow: {
                style: ({ $theme }) => ({
                  // zIndex: "9999",
                }),
              },
            }}
          >
            <div className="multi-apotion">
              <RBACWrapper role={DASHBOARD} type="PERMISSION">
                <div className="icon-child --trip_dot">
                  <DashBoard />
                  <div className="drop-down">
                    <DotSvg />
                  </div>
                </div>
              </RBACWrapper>
            </div>
          </StatefulPopover>
          {
            [
              ...AllValues,
              ...(findCurrentOrgName?.accounting_book === "CSV"
                ? csvRouteList
                : accountingRouteList),
            ]

              .sort((a, b) => a.order - b.order)
              .map((props) => {
                return (
                  <>
                    <IconCompo {...props} isOpen={isOpen} />
                    {/* <Search open={props.search} /> */}
                  </>
                );
              })
            // : quickBookValue.map((props) => {
            //     return <IconCompo {...props} />;
            //   })
          }
          <IconCompoPop userRole={userRole} />
        </div>

        <div className="sidebar-bottom">
          <div
            className={`icon-parent --notify_wraper ${
              isOpen && "--notification_active"
            }`}
            onClick={() => {
              // close();
              // setIsCount(isCount + 1);

              setNotificationIsOpen(true);
            }}
          >
            <div className="icon-child">
              <Notifications />
            </div>
            {data && data.data && data.data.is_active_notification && (
              <div className="notifi_dot"></div>
            )}
          </div>
          <>
            {notificationIsOpen ? (
              <div style={{ zIndex: 2 }}>
                <NotificationDrawer
                  isOpen={notificationIsOpen}
                  setIsOpen={setNotificationIsOpen}
                  close={notificationClose}
                  isCount={isCount}
                  refetch={refetch}
                  tabCounts={data && data.data && data.data.docs}
                />
              </div>
            ) : (
              <></>
            )}
          </>

          <StatefulPopover
            content={() => (
              <>
                <ParagraphSmall
                  className={"org-name"}
                  $style={{
                    cursor: "pointer",
                    padding: "10px",
                    textAlign: "left",
                    // className={e.name === "Profile" && "org-name"}
                  }}
                  onClick={() => setIsOpen(true)}
                >
                  Contact us
                </ParagraphSmall>

                <ParagraphSmall
                  $style={{
                    cursor: "pointer",
                    padding: "10px",
                    textAlign: "left",
                  }}
                  onClick={() => {
                    if (
                      currentDefaultFormatDetails.client_user_manual_url ===
                        HELP_CENTER ||
                      currentDefaultFormatDetails.user_manual_url ===
                        HELP_CENTER
                    ) {
                      let destination = location.pathname.split("/");
                      let checkingDestination;
                      if (customerId) {
                        checkingDestination = "consumer-overview-20";
                      } else {
                        checkingDestination = getUserManualPath(destination[1]);
                      }
                      // destination[1] === "" ? "dashboard" : destination[1];
                      openInNewTab(`${HELP_CENTER}/${checkingDestination}`);
                    } else if (
                      isClientLogin &&
                      currentDefaultFormatDetails.client_user_manual_url !==
                        HELP_CENTER
                    ) {
                      openInNewTab(
                        `${currentDefaultFormatDetails.client_user_manual_url}`
                      );
                    } else if (
                      !isClientLogin &&
                      currentDefaultFormatDetails.user_manual_url !==
                        HELP_CENTER
                    ) {
                      openInNewTab(
                        `${currentDefaultFormatDetails.user_manual_url}`
                      );
                    } else {
                    }
                  }}
                >
                  User Manual
                </ParagraphSmall>
              </>
            )}
            accessibilityType={"tooltip"}
            triggerType={TRIGGER_TYPE.hover}
            overrides={{
              Inner: {
                style: () => ({
                  backgroundColor: "#FFFFFF",
                  borderRadius: "5px",
                  background: "#ffffff",
                  border: "0.5px solid #CDCED9",
                }),
              },
              Body: {
                style: () => ({
                  backgroundColor: "#FFFFFF",
                  borderRadius: "5px",
                  marginBottom: "10px",
                  zIndex: 2,
                }),
              },
              Arrow: {
                style: ({ $theme }) => ({
                  // zIndex: "9999",
                }),
              },
            }}
          >
            <div className="icon-parent">
              <div className="icon-child">
                <QuestionMark />
              </div>
            </div>
          </StatefulPopover>

          {/* <IconCompoPop /> */}
        </div>
      </div>
    </>
  );
}

export default SideBar;

const date = new Date();
const showTime = date.getUTCDate() + ":" + date.getUTCMonth();
