import React, { useContext, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { MaxyfiContext } from "../../../providers/MaxyfiProvider";
import useFormat from "../../../hooks/useFormat";
import { setDrawerState } from "../../../redux/actions";
import { useMutation } from "react-query";
import { manualGroupingCat, manualGroupingLink } from "../../../services";
import { toast } from "react-toastify";
import queryClient from "../../../providers/queryClient";
import { CUSTOMER_OVERVIEW_ } from "../../../constants";
import TitleHead from "../../../components_v2/TitleHead";
import { SelectBox, SIZE } from "../../../components/SelectBox";
import { Icon, Typography } from "../../../components_v2";
import { TextBox } from "../../../components/TextBox";
import ManualGroupingCard from "./Grouping/ManualGrouping/ManualGroupingCard";
import { KIND, TextButton } from "../../../components/TextButton";
import Loader from "../../../components/Loader";
import { Controller, useFieldArray, useForm } from "react-hook-form";
import { useIntl } from "react-intl";
import CheckBoxBaseweb from "../../../components/CheckBoxBaseweb";
import { LABEL_PLACEMENT } from "baseui/checkbox";
import getSelectValues from "../../../utils/getSelectValues";
import { updateReportDownloadService } from "../../../services/reportMaintanace";
import _ from "lodash";
import setSelectValues from "../../../utils/setSelectValues";
const ReportSettingDrawer = ({
  closeDrawers,
  setDrawerWidth,
  allColumns,
  singleleReportData,
}) => {
  console.log(singleleReportData, "singleleReportData");
  const { customerId } = useParams();
  const intl = useIntl();
  let dispatch = useDispatch();
  const { referenceData, currentOrganization } = useContext(MaxyfiContext);
  const [handleReset, setHandleReset] = useState(false);
  // Function to add a new row
  const { id } = useParams();

  const {
    control,
    register,
    handleSubmit,
    reset,
    watch,
    setValue,
    setError,
    clearErrors,

    formState: { errors, isSubmitting },
  } = useForm({
    defaultValues: {
      // dlr: ",",
      hdr: [
        {
          st: "0",
          fld: [],
          value: "",
          frmt: [],
          len: null,
          aln: [{ id: "L", label: "Left" }],
          pad: " ",
        },
      ],
      bdy: [
        {
          st: "0",
          fld: [],
          value: "",
          frmt: [],
          len: null,
          aln: [{ id: "L", label: "Left" }],
          pad: " ",
        },
      ],
      tail: [
        {
          st: "0",
          fld: [],
          value: "",
          frmt: [],
          len: null,
          aln: [{ id: "L", label: "Left" }],
          pad: " ",
        },
      ],
    },
  });

  const {
    fields: hdrFields,
    append: appendHdr,
    update: updateHdr,
    remove: removeHdrIndex,
  } = useFieldArray({
    control,
    name: "hdr",
  });
  const {
    fields: bdyFields,
    append: appendBdy,
    remove: removeBdyIndex,
    update: updateBdy,
  } = useFieldArray({
    control,
    name: "bdy",
  });
  const {
    fields: tailFields,
    append: appendTail,
    remove: removeTailIndex,
    update: updateTail,
  } = useFieldArray({
    control,
    name: "tail",
  });

  useEffect(() => {
    const defaultField = {
      st: "0",
      fld: [],
      value: "",
      frmt: [],
      len: null,
      aln: [{ id: "L", label: "Left" }],
      pad: " ",
    };

    if (_.get(singleleReportData, "data.doc.dwld_stg.nam", "")) {
      reset({
        nam: _.get(singleleReportData, "data.doc.dwld_stg.nam", ""),
        typ: setSelectValues(
          _.get(singleleReportData, "data.doc.dwld_stg.typ", []),
          false
        ),
        ihd: _.get(singleleReportData, "data.doc.dwld_stg.ihd", false),
        dlr: _.get(singleleReportData, "data.doc.dwld_stg.dlr", ","),
        hdr:
          _.get(singleleReportData, "data.doc.dwld_stg.hdr", []).length > 0
            ? _.get(singleleReportData, "data.doc.dwld_stg.hdr", []).map(
                (hdr) => ({
                  aln: hdr?.aln ? setSelectValues(hdr?.aln, false) : [],
                  fld: hdr?.fld ? setSelectValues(hdr?.fld, false) : [],
                  frmt: hdr?.frmt ? setSelectValues(hdr?.frmt, false) : [],
                  len: hdr?.len,
                  pad: hdr?.pad ? hdr?.pad : " ",
                  st: hdr?.st,
                  value: hdr?.value ? hdr?.value : "",
                })
              )
            : [defaultField],
        bdy:
          _.get(singleleReportData, "data.doc.dwld_stg.bdy", []).length > 0
            ? _.get(singleleReportData, "data.doc.dwld_stg.bdy", []).map(
                (bdy) => ({
                  aln: bdy?.aln ? setSelectValues(bdy?.aln, false) : [],
                  fld: bdy?.fld ? setSelectValues(bdy?.fld, false) : [],
                  frmt: bdy?.frmt ? setSelectValues(bdy?.frmt, false) : [],
                  len: bdy?.len,
                  pad: bdy?.pad ? bdy?.pad : " ",
                  st: bdy?.st,
                  value: bdy?.value ? bdy?.value : "",
                })
              )
            : [defaultField],
        tail:
          _.get(singleleReportData, "data.doc.dwld_stg.tail", []).length > 0
            ? _.get(singleleReportData, "data.doc.dwld_stg.tail", []).map(
                (tail) => ({
                  aln: tail?.aln ? setSelectValues(tail?.aln, false) : [],
                  fld: tail?.fld ? setSelectValues(tail?.fld, false) : [],
                  frmt: tail?.frmt ? setSelectValues(tail?.frmt, false) : [],
                  len: tail?.len,
                  pad: tail?.pad ? tail?.pad : " ",
                  st: tail?.st,
                  value: tail?.value ? tail?.value : "",
                })
              )
            : [defaultField],
      });
    } else {
      reset({
        hdr: [
          {
            st: "0",
            fld: [],
            value: "",
            frmt: [],
            len: null,
            aln: [{ id: "L", label: "Left" }],
            pad: " ",
          },
        ],
        bdy: [
          {
            st: "0",
            fld: [],
            value: "",
            frmt: [],
            len: null,
            aln: [{ id: "L", label: "Left" }],
            pad: " ",
          },
        ],
        tail: [
          {
            st: "0",
            fld: [],
            value: "",
            frmt: [],
            len: null,
            aln: [{ id: "L", label: "Left" }],
            pad: " ",
          },
        ],
      });
    }
  }, [singleleReportData, reset, handleReset]);

  const updateDownloadSettingMutation = useMutation(
    (reportData) =>
      updateReportDownloadService({
        reportId: id,
        organization: currentOrganization,
        ...reportData,
      }),
    {
      onError: (error) => {
        if (error.response) {
          let { data } = error.response;
          let errorData = data.message;
          toast.error(errorData);
        }
      },
      onSuccess: (data) => {
        queryClient.refetchQueries(
          `SINGLE_REPORT_${id}_${currentOrganization}`
        );
        queryClient.invalidateQueries(`${CUSTOMER_OVERVIEW_}${customerId}`);
        closeDrawers();
      },
    }
  );

  useEffect(() => {
    if (watch("bdycheck") || watch("hdrcheck") || watch("tailcheck")) {
      setDrawerWidth(true);
    } else {
      setDrawerWidth(false);
    }
  }, [watch("bdycheck") || watch("hdrcheck") || watch("tailcheck")]);

  // Watch the fields to detect changes
  useEffect(() => {
    // Set hdrcheck to true if hdrFields length is greater than 0
    if (_.get(singleleReportData, "data.doc.dwld_stg.hdr", []).length > 0) {
      setValue("hdrcheck", true);
    } else {
      setValue("hdrcheck", false);
    }

    // Set bdycheck to true if bdyFields length is greater than 0
    if (_.get(singleleReportData, "data.doc.dwld_stg.bdy", []).length > 0) {
      setValue("bdycheck", true);
    } else {
      setValue("bdycheck", false);
    }

    // Set tailcheck to true if tailFields length is greater than 0
    if (_.get(singleleReportData, "data.doc.dwld_stg.tail", []).length > 0) {
      setValue("tailcheck", true);
    } else {
      setValue("tailcheck", false);
    }
  }, [_.get(singleleReportData, "data.doc.dwld_stg", {})]);

  // Populate the initial values if singleleReportData is available
  // useEffect(() => {
  //   const reportData = _.get(singleleReportData, "data.docdwld_stg", {});

  //   if (reportData.hdr?.length > 0) {
  //     setValue("hdr", reportData.hdr);
  //     setValue("hdrcheck", true);
  //   }
  //   if (reportData.bdy?.length > 0) {
  //     setValue("bdy", reportData.bdy);
  //     setValue("bdycheck", true);
  //   }
  //   if (reportData.tail?.length > 0) {
  //     setValue("tail", reportData.tail);
  //     setValue("tailcheck", true);
  //   }
  // }, [singleleReportData, setValue]);

  const onSubmit = async (data) => {
    // Transform the values of the specified keys using getSelectValues
    const transformValues = (obj, keys) => {
      for (const key of keys) {
        if (obj[key] && Array.isArray(obj[key])) {
          obj[key] = getSelectValues(obj[key], false); // Applying transformation to the key values
        }
      }
    };

    // Transform values for hdr, bdy, tail
    const processData = (data) => {
      const keysToTransform = ["typ", "fld", "frmt", "aln"];

      // Iterate over the arrays hdr, bdy, tail
      ["hdr", "bdy", "tail", "fld"].forEach((section) => {
        if (data[section]) {
          data[section] = data[section].map((item) => {
            transformValues(item, keysToTransform);
            return item;
          });
        }
      });

      let values = {
        nam: data.nam,
        typ: getSelectValues(data.typ, false),
        ...(data?.hdr &&
        data?.hdrcheck &&
        getSelectValues(data.typ, false) === "FW"
          ? { hdr: data.hdr }
          : {}),
        ...(data?.bdy &&
        data?.bdycheck &&
        getSelectValues(data.typ, false) === "FW"
          ? { bdy: data.bdy }
          : {}),
        ...(data?.tail &&
        data?.tailcheck &&
        getSelectValues(data.typ, false) === "FW"
          ? { tail: data.tail }
          : {}),

        ...(getSelectValues(data.typ, false) === "DT"
          ? { ihd: data?.ihd }
          : {}),
        ...(getSelectValues(data.typ, false) === "DT"
          ? { dlr: data?.dlr }
          : {}),
      };

      return values;
    };

    // Process the form data
    const processedData = processData(data);

    await updateDownloadSettingMutation.mutateAsync(processedData);
  };
  const lastIndex = hdrFields.length - 1; // Get last index
  const lastSt = watch(`hdr[${lastIndex}].st`);

  const mergedColumnArray = useMemo(() => {
    return allColumns.concat(
      referenceData["fixed_width_file_header_field_type"].filter(
        (refItem) => !allColumns.some((colItem) => colItem.id === refItem.id)
      )
    );
  }, [allColumns, referenceData]);

  const mergedTailArray = mergedColumnArray.filter(
    (refItem) => refItem.type === "AMOUNT"
  );

  const concateTailArrayAmount = mergedTailArray.concat(
    referenceData["fixed_width_file_tailer_field_type"]
  );
  return (
    <div>
      <TitleHead title="Download Settings" />
      <form
        style={{
          textAlign: "center",
          marginTop: "15px",
          padding: "10px 20px",
        }}
        onSubmit={handleSubmit(onSubmit)}
      >
        <div style={{ display: "flex", gap: "10px" }}>
          <div style={{ width: "280px" }}>
            <Controller
              name="nam"
              control={control}
              rules={{
                required: "Required",
                pattern: {
                  value: /^[^\s]+\.(csv|txt)$/, // Regex pattern for .csv and .txt files
                  message:
                    "No spaces allowed, only .csv or .txt files are permitted",
                },
              }}
              render={({ field }) => (
                <TextBox
                  size={SIZE.mini}
                  value={field.value}
                  error={errors[field.name] && errors[field.name].message}
                  requiredAstric={true}
                  onChange={(e) => {
                    field.onChange(e.target.value);
                  }}
                  label={intl.formatMessage({
                    id: "file_name",
                  })}
                  placeholder={intl.formatMessage({
                    id: "file_name",
                  })}
                />
              )}
            />
          </div>

          <div style={{ width: "280px" }}>
            <Controller
              name="typ"
              control={control}
              rules={{ required: "Required" }}
              render={({ field }) => (
                <SelectBox
                  {...field}
                  name={field.name}
                  clearable={false}
                  backspaceRemoves={false}
                  size={SIZE.mini}
                  requiredAstric={true}
                  error={errors[field.name] && errors[field.name].message}
                  label={intl.formatMessage({
                    id: "file_type",
                  })}
                  placeholder={intl.formatMessage({
                    id: "file_type",
                  })}
                  value={field.value}
                  onChange={(e) => {
                    field.onChange(e.value);
                  }}
                  options={
                    referenceData && referenceData["report_file_type"]
                      ? referenceData["report_file_type"]
                      : []
                  }
                />
              )}
            />
          </div>
        </div>
        {Array.isArray(watch("typ")) &&
          getSelectValues(watch("typ")) === "FW" && (
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-start",
                gap: "10px",
              }}
            >
              <Typography type="p" className="text-secondary" subType="regular">
                Set Your Preferences
              </Typography>
              <div
                style={{ display: "flex", gap: "20px", alignItems: "center" }}
              >
                <Typography
                  className="regular"
                  type="p"
                  style={{ textAlign: "start", width: "50px" }}
                >
                  Header
                </Typography>

                <Controller
                  name="hdrcheck"
                  control={control}
                  defaultValues={false}
                  render={({ field }) => (
                    <div
                      style={{
                        textAlign: "center",
                      }}
                    >
                      <CheckBoxBaseweb
                        requiredAstric={true}
                        overrides={{
                          Root: {
                            style: ({ $theme }) => ({
                              alignItems: "center",
                            }),
                          },
                          Checkmark: {
                            style: ({ $theme, $checked }) => ({
                              height: "18px",
                              width: "18px",
                              backgroundColor: $checked ? "#516BEB" : null,
                              borderRadius: $theme.borders.radius200,
                              borderBottomColor: $checked
                                ? $theme.colors.primary
                                : `#CDCED9`,
                              borderTopColor: $checked
                                ? $theme.colors.primary
                                : `#CDCED9`,
                              borderRightColor: $checked
                                ? $theme.colors.primary
                                : `#CDCED9`,
                              borderLeftColor: $checked
                                ? $theme.colors.primary
                                : `#CDCED9`,
                              borderBottomWidth: "0.5px",
                              borderTopWidth: "0.5px",
                              borderRightWidth: "0.5px",
                              borderLeftWidth: "0.5px",
                              borderStyle: "solid",
                              backgroundImage: $checked
                                ? "url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTEiIGhlaWdodD0iOCIgdmlld0JveD0iMCAwIDExIDgiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CjxsaW5lIHgxPSIxLjQxNDIxIiB5MT0iNCIgeDI9IjQiIHkyPSI2LjU4NTc5IiBzdHJva2U9IiNmZmZmZmYiIHN0cm9rZS13aWR0aD0iMiIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIi8+CjxsaW5lIHgxPSI0IiB5MT0iNi41ODU3OSIgeDI9IjkuNTg1NzkiIHkyPSIxIiBzdHJva2U9IiNmZmZmZmYiIHN0cm9rZS13aWR0aD0iMiIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIi8+Cjwvc3ZnPgo=)"
                                : "none",
                              backgroundSize: "auto",
                            }),
                          },
                        }}
                        // labelPlacement={LABEL_PLACEMENT.left}
                        // {...i?.getToggleHiddenProps()}
                        {...field}
                        checked={field.value}
                        labelPlacement={LABEL_PLACEMENT.right}
                        onChange={(e) => field.onChange(e.target.checked)}
                      ></CheckBoxBaseweb>
                    </div>
                  )}
                />
              </div>
              {watch("hdrcheck") ? (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "flex-start",
                    flexDirection: "column",
                  }}
                >
                  <div
                    className="report-maintanenace-table-container"
                    style={{ margin: "0px" }}
                  >
                    <div className="status-maintanenace-table">
                      <table>
                        <thead>
                          <tr>
                            <th rowspan="1">
                              <Typography className="text-typo">
                                Start
                              </Typography>
                            </th>
                            <th rowspan="1">
                              <Typography className="text-typo">
                                {" "}
                                Field
                              </Typography>
                            </th>
                            <th colspan="1">
                              <Typography className="text-typo">
                                {" "}
                                Value{" "}
                              </Typography>
                            </th>
                            <th colspan="1">
                              <Typography className="text-typo">
                                {" "}
                                Format{" "}
                              </Typography>
                            </th>
                            <th colspan="1">
                              <Typography className="text-typo">
                                {" "}
                                Length{" "}
                              </Typography>
                            </th>
                            <th colspan="1">
                              <Typography className="text-typo">
                                {" "}
                                Align{" "}
                              </Typography>
                            </th>
                            <th colspan="1">
                              <Typography
                                className="text-typo"
                                style={{ textAlign: "center" }}
                              >
                                {" "}
                                Padding{" "}
                              </Typography>
                            </th>
                            <th colspan="1">
                              <Typography
                                className="text-typo"
                                style={{ textAlign: "center" }}
                              >
                                {""}
                              </Typography>
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {hdrFields.map((item, index) => (
                            <tr key={index}>
                              <td>
                                <div
                                  style={{
                                    width: "70px",
                                    padding: "10px 10px 0px",
                                  }}
                                >
                                  <Controller
                                    name={`hdr[${index}].st`}
                                    rules={{ required: "Required" }}
                                    control={control}
                                    render={({ field }) => (
                                      <TextBox
                                        size={SIZE.mini}
                                        value={field.value}
                                        onChange={(e) => {
                                          field.onChange(e.target.value);
                                        }}
                                        error={
                                          errors?.hdr?.[index]?.st &&
                                          errors.hdr[index].st.message
                                        }
                                      />
                                    )}
                                  />
                                </div>
                              </td>
                              <td>
                                <div
                                  style={{
                                    width: "144px",
                                    padding: "10px 10px 0px",
                                  }}
                                >
                                  <Controller
                                    name={`hdr[${index}].fld`}
                                    rules={{ required: "Required" }}
                                    control={control}
                                    render={({ field }) => (
                                      <SelectBox
                                        size={SIZE.compact}
                                        clearable={false}
                                        {...field}
                                        error={
                                          errors?.hdr?.[index]?.fld &&
                                          errors.hdr[index].fld.message
                                        }
                                        // label={intl.formatMessage({
                                        //   id: "format",
                                        // })}
                                        // placeholder={intl.formatMessage({
                                        //   id: "format",
                                        // })}
                                        value={field.value}
                                        onChange={(e) => {
                                          field.onChange(e.value);
                                        }}
                                        options={
                                          referenceData[
                                            "fixed_width_file_header_field_type"
                                          ] || []
                                        }
                                      />
                                    )}
                                  />
                                </div>
                              </td>
                              <td>
                                <div
                                  style={{
                                    width: "70px",
                                    padding: "10px 10px 0px",
                                  }}
                                >
                                  <Controller
                                    name={`hdr[${index}].value`}
                                    control={control}
                                    render={({ field }) => (
                                      <TextBox
                                        size={SIZE.mini}
                                        value={field.value}
                                        onChange={(e) => {
                                          field.onChange(e.target.value);
                                        }}
                                        disabled={
                                          getSelectValues(
                                            watch(`hdr[${index}].fld`)
                                          ) === "FX"
                                            ? false
                                            : true
                                        }
                                      />
                                    )}
                                  />
                                </div>
                              </td>
                              <td>
                                <div
                                  style={{
                                    width: "150px",
                                    padding: "10px 10px 0px",
                                  }}
                                >
                                  <Controller
                                    name={`hdr[${index}].frmt`}
                                    control={control}
                                    render={({ field }) => (
                                      <SelectBox
                                        size={SIZE.compact}
                                        clearable={false}
                                        {...field}
                                        error={
                                          errors[field.name] &&
                                          errors[field.name].message
                                        }
                                        label={intl.formatMessage({
                                          id: "format",
                                        })}
                                        placeholder={intl.formatMessage({
                                          id: "format",
                                        })}
                                        value={field.value}
                                        onChange={(e) => {
                                          field.onChange(e.value);
                                        }}
                                        disabled={
                                          getSelectValues(
                                            watch(`hdr[${index}].fld`)
                                          ) === "TDY"
                                            ? false
                                            : true
                                        }
                                        options={
                                          referenceData[
                                            "fixed_width_date_format"
                                          ] || []
                                        }
                                      />
                                    )}
                                  />
                                </div>
                              </td>
                              <td>
                                <div
                                  style={{
                                    width: "70px",
                                    padding: "10px 10px 0px",
                                  }}
                                >
                                  <Controller
                                    name={`hdr[${index}].len`}
                                    rules={{ required: "Required" }}
                                    control={control}
                                    render={({ field }) => (
                                      <TextBox
                                        size={SIZE.mini}
                                        {...field}
                                        type="number"
                                        value={field.value}
                                        onChange={(e) => {
                                          field.onChange(e.target.value);
                                        }}
                                        error={
                                          errors?.hdr?.[index]?.len &&
                                          errors.hdr[index].len.message
                                        }
                                      />
                                    )}
                                  />
                                </div>
                              </td>
                              <td>
                                <div
                                  style={{
                                    width: "150px",
                                    padding: "10px 10px 0px",
                                  }}
                                >
                                  <Controller
                                    name={`hdr[${index}].aln`}
                                    rules={{ required: "Required" }}
                                    control={control}
                                    render={({ field }) => (
                                      <SelectBox
                                        size={SIZE.compact}
                                        clearable={false}
                                        {...field}
                                        error={
                                          errors?.hdr?.[index]?.aln &&
                                          errors.hdr[index].aln.message
                                        }
                                        value={field.value}
                                        onChange={(e) => {
                                          field.onChange(e.value);
                                        }}
                                        options={
                                          referenceData["align_type"] || []
                                        }
                                      />
                                    )}
                                  />
                                </div>
                              </td>
                              <td>
                                <div
                                  style={{
                                    width: "144px",
                                    padding: "10px 10px 0px",
                                  }}
                                >
                                  <Controller
                                    name={`hdr[${index}].pad`}
                                    rules={{ required: "Required" }}
                                    control={control}
                                    render={({ field }) => (
                                      <TextBox
                                        size={SIZE.mini}
                                        value={field.value}
                                        onChange={(e) => {
                                          field.onChange(e.target.value);
                                        }}
                                        error={
                                          errors?.hdr?.[index]?.pad &&
                                          errors.hdr[index].pad.message
                                        }
                                      />
                                    )}
                                  />
                                </div>
                              </td>
                              <td>
                                <div
                                  style={{
                                    width: "50px",
                                    padding: "10px 10px 10px",
                                  }}
                                  onClick={() => {
                                    if (index > 0) {
                                      removeHdrIndex(index);
                                    } else {
                                      setValue("hdrcheck", false);
                                    }
                                  }}
                                >
                                  <Icon
                                    icon="delete_outline"
                                    color={"#FD372A"}
                                    isPressable
                                  />
                                </div>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  </div>

                  <div style={{ textAlign: "start" }}>
                    <TextButton
                      type="button"
                      kind={KIND.tertiary}
                      size={SIZE.mini}
                      disabled={
                        watch(`hdr[${hdrFields.length - 1}].st`) ===
                          undefined ||
                        watch(`hdr[${hdrFields.length - 1}].st`) === null ||
                        watch(`hdr[${hdrFields.length - 1}].st`) === ""
                      }
                      // onClick={() =>
                      //   appendHdr({
                      //     st: "",
                      //     fld: [],
                      //     value: "",
                      //     frmt: [],
                      //     len: "",
                      //     aln: [],
                      //     pad: "",
                      //   })
                      // }
                      onClick={() => {
                        // Get the last st value
                        const lastLen = watch(`hdr[${lastIndex}].len`); // Get the last len value
                        // Determine the next `st` value
                        const nextSt =
                          lastSt !== undefined && lastLen && lastLen !== ""
                            ? parseInt(lastSt) + parseInt(lastLen)
                            : parseInt(lastSt) + 1;

                        appendHdr({
                          st: nextSt.toString(), // Set the next `st` value
                          fld: [],
                          value: "",
                          frmt: [],
                          len: null,
                          aln: [{ id: "L", label: "Left" }],
                          pad: " ",
                        });
                      }}
                    >
                      <Icon icon="plus" color={"#516BEB"} />
                      <span className="new_payment_plan">Add New</span>
                    </TextButton>
                  </div>
                </div>
              ) : null}

              <div
                style={{ display: "flex", gap: "20px", alignItems: "center" }}
              >
                <Typography
                  className="regular"
                  type="p"
                  style={{ textAlign: "start", width: "50px" }}
                >
                  Body
                </Typography>

                <Controller
                  name="bdycheck"
                  control={control}
                  defaultValues={false}
                  render={({ field }) => (
                    <div
                      style={{
                        textAlign: "center",
                      }}
                    >
                      <CheckBoxBaseweb
                        requiredAstric={true}
                        overrides={{
                          Root: {
                            style: ({ $theme }) => ({
                              alignItems: "center",
                            }),
                          },
                          Checkmark: {
                            style: ({ $theme, $checked }) => ({
                              height: "18px",
                              width: "18px",
                              backgroundColor: $checked ? "#516BEB" : null,
                              borderRadius: $theme.borders.radius200,
                              borderBottomColor: $checked
                                ? $theme.colors.primary
                                : `#CDCED9`,
                              borderTopColor: $checked
                                ? $theme.colors.primary
                                : `#CDCED9`,
                              borderRightColor: $checked
                                ? $theme.colors.primary
                                : `#CDCED9`,
                              borderLeftColor: $checked
                                ? $theme.colors.primary
                                : `#CDCED9`,
                              borderBottomWidth: "0.5px",
                              borderTopWidth: "0.5px",
                              borderRightWidth: "0.5px",
                              borderLeftWidth: "0.5px",
                              borderStyle: "solid",
                              backgroundImage: $checked
                                ? "url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTEiIGhlaWdodD0iOCIgdmlld0JveD0iMCAwIDExIDgiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CjxsaW5lIHgxPSIxLjQxNDIxIiB5MT0iNCIgeDI9IjQiIHkyPSI2LjU4NTc5IiBzdHJva2U9IiNmZmZmZmYiIHN0cm9rZS13aWR0aD0iMiIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIi8+CjxsaW5lIHgxPSI0IiB5MT0iNi41ODU3OSIgeDI9IjkuNTg1NzkiIHkyPSIxIiBzdHJva2U9IiNmZmZmZmYiIHN0cm9rZS13aWR0aD0iMiIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIi8+Cjwvc3ZnPgo=)"
                                : "none",
                              backgroundSize: "auto",
                            }),
                          },
                        }}
                        // labelPlacement={LABEL_PLACEMENT.left}
                        // {...i?.getToggleHiddenProps()}
                        {...field}
                        checked={field.value}
                        labelPlacement={LABEL_PLACEMENT.right}
                        onChange={(e) => field.onChange(e.target.checked)}
                      ></CheckBoxBaseweb>
                    </div>
                  )}
                />
              </div>
              {watch("bdycheck") ? (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "flex-start",
                    flexDirection: "column",
                  }}
                >
                  <div
                    className="report-maintanenace-table-container"
                    style={{ margin: "0px" }}
                  >
                    <div className="status-maintanenace-table">
                      <table>
                        <thead>
                          <tr>
                            <th rowspan="1">
                              <Typography className="text-typo">
                                Start
                              </Typography>
                            </th>
                            <th rowspan="1">
                              <Typography className="text-typo">
                                {" "}
                                Field
                              </Typography>
                            </th>
                            <th colspan="1">
                              <Typography className="text-typo">
                                {" "}
                                Value{" "}
                              </Typography>
                            </th>
                            <th colspan="1">
                              <Typography className="text-typo">
                                {" "}
                                Format{" "}
                              </Typography>
                            </th>
                            <th colspan="1">
                              <Typography className="text-typo">
                                {" "}
                                Length{" "}
                              </Typography>
                            </th>
                            <th colspan="1">
                              <Typography className="text-typo">
                                {" "}
                                Align{" "}
                              </Typography>
                            </th>
                            <th colspan="1">
                              <Typography
                                className="text-typo"
                                style={{ textAlign: "center" }}
                              >
                                {" "}
                                Padding{" "}
                              </Typography>
                            </th>
                            <th colspan="1">
                              <Typography
                                className="text-typo"
                                style={{ textAlign: "center" }}
                              >
                                {""}
                              </Typography>
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {bdyFields.map((item, index) => (
                            <tr key={index}>
                              <td>
                                <div
                                  style={{
                                    width: "70px",
                                    padding: "10px 10px 0px",
                                  }}
                                >
                                  <Controller
                                    name={`bdy[${index}].st`}
                                    rules={{ required: "Required" }}
                                    control={control}
                                    render={({ field }) => (
                                      <TextBox
                                        size={SIZE.mini}
                                        value={field.value}
                                        onChange={(e) => {
                                          field.onChange(e.target.value);
                                        }}
                                        error={
                                          errors?.bdy?.[index]?.st &&
                                          errors.bdy[index].st.message
                                        }
                                      />
                                    )}
                                  />
                                </div>
                              </td>
                              <td>
                                <div
                                  style={{
                                    width: "144px",
                                    padding: "10px 10px 0px",
                                  }}
                                >
                                  <Controller
                                    name={`bdy[${index}].fld`}
                                    rules={{ required: "Required" }}
                                    control={control}
                                    render={({ field }) => (
                                      <SelectBox
                                        size={SIZE.compact}
                                        clearable={false}
                                        {...field}
                                        error={
                                          errors?.bdy?.[index]?.fld &&
                                          errors.bdy[index].fld.message
                                        }
                                        // label={intl.formatMessage({
                                        //   id: "format",
                                        // })}
                                        // placeholder={intl.formatMessage({
                                        //   id: "format",
                                        // })}
                                        value={field.value}
                                        onChange={(e) => {
                                          field.onChange(e.value);
                                        }}
                                        options={mergedColumnArray.map(
                                          (col) => ({
                                            id: col.id, // The 'id' for SelectBox option
                                            label: col.literal_id
                                              ? col.literal_id
                                              : col.label, // The 'label' for SelectBox option
                                            type: col.type, // The 'type' for SelectBox option
                                          })
                                        )}
                                      />
                                    )}
                                  />
                                </div>
                              </td>
                              <td>
                                <div
                                  style={{
                                    width: "70px",
                                    padding: "10px 10px 0px",
                                  }}
                                >
                                  <Controller
                                    name={`bdy[${index}].value`}
                                    control={control}
                                    render={({ field }) => (
                                      <TextBox
                                        size={SIZE.mini}
                                        value={field.value}
                                        onChange={(e) => {
                                          field.onChange(e.target.value);
                                        }}
                                      />
                                    )}
                                  />
                                </div>
                              </td>
                              <td>
                                <div
                                  style={{
                                    width: "150px",
                                    padding: "10px 10px 0px",
                                  }}
                                >
                                  <Controller
                                    name={`bdy[${index}].frmt`}
                                    control={control}
                                    render={({ field }) => {
                                      const fldValue = watch(
                                        `bdy[${index}].fld`
                                      ); // Watch the field value
                                      const isbdyDisabled =
                                        fldValue &&
                                        Array.isArray(fldValue) &&
                                        fldValue.length > 0
                                          ? fldValue[0]?.id === "TDY" // Check if the id is 'TDY'
                                            ? false // If true, disable is false
                                            : mergedColumnArray.some(
                                                (item) =>
                                                  item.id === fldValue[0]?.id &&
                                                  item.type === "DATE"
                                              ) // Check if the id matches and type is "AMOUNT"
                                            ? false // If both conditions match, disable is true
                                            : mergedColumnArray.some(
                                                (item) =>
                                                  item.id === fldValue[0]?.id &&
                                                  item.type === "AMOUNT"
                                              )
                                            ? false
                                            : true
                                          : true; // If fldtailValue is invalid, disable is true

                                      return (
                                        <SelectBox
                                          size={SIZE.compact}
                                          clearable={false}
                                          {...field}
                                          error={
                                            errors?.bdy?.[index]?.frmt &&
                                            errors.bdy[index].frmt.message
                                          }
                                          label={intl.formatMessage({
                                            id: "format",
                                          })}
                                          placeholder={intl.formatMessage({
                                            id: "format",
                                          })}
                                          value={field.value}
                                          onChange={(e) => {
                                            field.onChange(e.value);
                                          }}
                                          options={
                                            mergedColumnArray.some(
                                              (item) =>
                                                item.id === fldValue[0]?.id &&
                                                item.type === "AMOUNT"
                                            )
                                              ? referenceData[
                                                  "fixed_width_amount_format"
                                                ]
                                              : referenceData[
                                                  "fixed_width_date_format"
                                                ]
                                          }
                                          disabled={isbdyDisabled}
                                        />
                                      );
                                    }}
                                  />
                                </div>
                              </td>
                              <td>
                                <div
                                  style={{
                                    width: "70px",
                                    padding: "10px 10px 0px",
                                  }}
                                >
                                  <Controller
                                    name={`bdy[${index}].len`}
                                    rules={{ required: "Required" }}
                                    control={control}
                                    render={({ field }) => (
                                      <TextBox
                                        size={SIZE.mini}
                                        value={field.value}
                                        type="number"
                                        onChange={(e) => {
                                          field.onChange(e.target.value);
                                        }}
                                        error={
                                          errors?.bdy?.[index]?.len &&
                                          errors.bdy[index].len.message
                                        }
                                      />
                                    )}
                                  />
                                </div>
                              </td>
                              <td>
                                <div
                                  style={{
                                    width: "150px",
                                    padding: "10px 10px 0px",
                                  }}
                                >
                                  <Controller
                                    name={`bdy[${index}].aln`}
                                    control={control}
                                    rules={{ required: "Required" }}
                                    render={({ field }) => (
                                      <SelectBox
                                        size={SIZE.compact}
                                        clearable={false}
                                        {...field}
                                        error={
                                          errors?.bdy?.[index]?.aln &&
                                          errors.bdy[index].aln.message
                                        }
                                        value={field.value}
                                        onChange={(e) => {
                                          field.onChange(e.value);
                                        }}
                                        options={
                                          referenceData["align_type"] || []
                                        }
                                      />
                                    )}
                                  />
                                </div>
                              </td>
                              <td>
                                <div
                                  style={{
                                    width: "144px",
                                    padding: "10px 10px 0px",
                                  }}
                                >
                                  <Controller
                                    name={`bdy[${index}].pad`}
                                    rules={{ required: "Required" }}
                                    control={control}
                                    render={({ field }) => (
                                      <TextBox
                                        size={SIZE.mini}
                                        value={field.value}
                                        onChange={(e) => {
                                          field.onChange(e.target.value);
                                        }}
                                        error={
                                          errors?.bdy?.[index]?.pad &&
                                          errors.bdy[index].pad.message
                                        }
                                      />
                                    )}
                                  />
                                </div>
                              </td>
                              <td>
                                <div
                                  style={{
                                    width: "50px",
                                    padding: "10px 10px 10px",
                                  }}
                                  onClick={() => {
                                    if (index > 0) {
                                      removeBdyIndex(index);
                                    } else {
                                      setValue("bdycheck", false);
                                    }
                                  }}
                                >
                                  <Icon
                                    icon="delete_outline"
                                    color={"#FD372A"}
                                    isPressable
                                  />
                                </div>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  </div>
                  <div style={{ textAlign: "start" }}>
                    <TextButton
                      type="button"
                      kind={KIND.tertiary}
                      size={SIZE.mini}
                      disabled={
                        watch(`bdy[${bdyFields.length - 1}].st`) ===
                          undefined ||
                        watch(`bdy[${bdyFields.length - 1}].st`) === null ||
                        watch(`bdy[${bdyFields.length - 1}].st`) === ""
                      }
                      // onClick={() =>
                      //   appendBdy({
                      //     st: "",
                      //     fld: [],
                      //     value: "",
                      //     frmt: [],
                      //     len: "",
                      //     aln: [],
                      //     pad: "",
                      //   })
                      // }
                      onClick={() => {
                        const lastIndex = bdyFields.length - 1; // Get last index
                        const lastSt = watch(`bdy[${lastIndex}].st`); // Get the last st value
                        const lastLen = watch(`bdy[${lastIndex}].len`); // Get the last len value

                        // Determine the next `st` value
                        const nextSt =
                          lastSt !== undefined && lastLen && lastLen !== ""
                            ? parseInt(lastSt) + parseInt(lastLen)
                            : parseInt(lastSt) + 1;

                        appendBdy({
                          st: nextSt.toString(), // Set the next `st` value
                          fld: [],
                          value: "",
                          frmt: [],
                          len: "",
                          aln: [{ id: "L", label: "Left" }],
                          pad: " ",
                        });
                      }}
                    >
                      <Icon icon="plus" color={"#516BEB"} />
                      <span className="new_payment_plan">Add New</span>
                    </TextButton>
                  </div>
                </div>
              ) : null}
              <div
                style={{ display: "flex", gap: "20px", alignItems: "center" }}
              >
                <Typography
                  className="regular"
                  type="p"
                  style={{ textAlign: "start", width: "50px" }}
                >
                  Trailer
                </Typography>

                <Controller
                  name="tailcheck"
                  control={control}
                  defaultValues={false}
                  render={({ field }) => (
                    <div
                      style={{
                        textAlign: "center",
                      }}
                    >
                      <CheckBoxBaseweb
                        requiredAstric={true}
                        overrides={{
                          Root: {
                            style: ({ $theme }) => ({
                              alignItems: "center",
                            }),
                          },
                          Checkmark: {
                            style: ({ $theme, $checked }) => ({
                              height: "18px",
                              width: "18px",
                              backgroundColor: $checked ? "#516BEB" : null,
                              borderRadius: $theme.borders.radius200,
                              borderBottomColor: $checked
                                ? $theme.colors.primary
                                : `#CDCED9`,
                              borderTopColor: $checked
                                ? $theme.colors.primary
                                : `#CDCED9`,
                              borderRightColor: $checked
                                ? $theme.colors.primary
                                : `#CDCED9`,
                              borderLeftColor: $checked
                                ? $theme.colors.primary
                                : `#CDCED9`,
                              borderBottomWidth: "0.5px",
                              borderTopWidth: "0.5px",
                              borderRightWidth: "0.5px",
                              borderLeftWidth: "0.5px",
                              borderStyle: "solid",
                              backgroundImage: $checked
                                ? "url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTEiIGhlaWdodD0iOCIgdmlld0JveD0iMCAwIDExIDgiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CjxsaW5lIHgxPSIxLjQxNDIxIiB5MT0iNCIgeDI9IjQiIHkyPSI2LjU4NTc5IiBzdHJva2U9IiNmZmZmZmYiIHN0cm9rZS13aWR0aD0iMiIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIi8+CjxsaW5lIHgxPSI0IiB5MT0iNi41ODU3OSIgeDI9IjkuNTg1NzkiIHkyPSIxIiBzdHJva2U9IiNmZmZmZmYiIHN0cm9rZS13aWR0aD0iMiIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIi8+Cjwvc3ZnPgo=)"
                                : "none",
                              backgroundSize: "auto",
                            }),
                          },
                        }}
                        // labelPlacement={LABEL_PLACEMENT.left}
                        // {...i?.getToggleHiddenProps()}
                        {...field}
                        checked={field.value}
                        labelPlacement={LABEL_PLACEMENT.right}
                        onChange={(e) => field.onChange(e.target.checked)}
                      ></CheckBoxBaseweb>
                    </div>
                  )}
                />
              </div>
              {watch("tailcheck") ? (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "flex-start",
                    flexDirection: "column",
                  }}
                >
                  <div
                    className="report-maintanenace-table-container"
                    style={{ margin: "0px" }}
                  >
                    <div className="status-maintanenace-table">
                      <table>
                        <thead>
                          <tr>
                            <th rowspan="1">
                              <Typography className="text-typo">
                                Start
                              </Typography>
                            </th>
                            <th rowspan="1">
                              <Typography className="text-typo">
                                {" "}
                                Field
                              </Typography>
                            </th>
                            <th colspan="1">
                              <Typography className="text-typo">
                                {" "}
                                Value{" "}
                              </Typography>
                            </th>
                            <th colspan="1">
                              <Typography className="text-typo">
                                {" "}
                                Format{" "}
                              </Typography>
                            </th>
                            <th colspan="1">
                              <Typography className="text-typo">
                                {" "}
                                Length{" "}
                              </Typography>
                            </th>
                            <th colspan="1">
                              <Typography className="text-typo">
                                {" "}
                                Align{" "}
                              </Typography>
                            </th>
                            <th colspan="1">
                              <Typography
                                className="text-typo"
                                style={{ textAlign: "center" }}
                              >
                                {" "}
                                Padding{" "}
                              </Typography>
                            </th>
                            <th colspan="1">
                              <Typography
                                className="text-typo"
                                style={{ textAlign: "center" }}
                              >
                                {""}
                              </Typography>
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {tailFields.map((item, index) => (
                            <tr key={index}>
                              <td>
                                <div
                                  style={{
                                    width: "70px",
                                    padding: "10px 10px 0px",
                                  }}
                                >
                                  <Controller
                                    name={`tail[${index}].st`}
                                    rules={{ required: "Required" }}
                                    control={control}
                                    render={({ field }) => (
                                      <TextBox
                                        size={SIZE.mini}
                                        value={field.value}
                                        onChange={(e) => {
                                          field.onChange(e.target.value);
                                        }}
                                        error={
                                          errors?.tail?.[index]?.st &&
                                          errors.tail[index].st.message
                                        }
                                      />
                                    )}
                                  />
                                </div>
                              </td>
                              <td>
                                <div
                                  style={{
                                    width: "144px",
                                    padding: "10px 10px 0px",
                                  }}
                                >
                                  <Controller
                                    name={`tail[${index}].fld`}
                                    rules={{ required: "Required" }}
                                    control={control}
                                    render={({ field }) => (
                                      <SelectBox
                                        size={SIZE.compact}
                                        clearable={false}
                                        {...field}
                                        error={
                                          errors?.tail?.[index]?.fld &&
                                          errors.tail[index].fld.message
                                        }
                                        // label={intl.formatMessage({
                                        //   id: "format",
                                        // })}
                                        // placeholder={intl.formatMessage({
                                        //   id: "format",
                                        // })}
                                        value={field.value}
                                        onChange={(e) => {
                                          field.onChange(e.value);
                                        }}
                                        options={concateTailArrayAmount.map(
                                          (col) => ({
                                            id: col.id, // The 'id' for SelectBox option
                                            label: col.literal_id
                                              ? col.literal_id
                                              : col.label, // The 'label' for SelectBox option
                                            type: col.type, // The 'type' for SelectBox option
                                          })
                                        )}
                                      />
                                    )}
                                  />
                                </div>
                              </td>
                              <td>
                                <div
                                  style={{
                                    width: "70px",
                                    padding: "10px 10px 0px",
                                  }}
                                >
                                  <Controller
                                    name={`tail[${index}].value`}
                                    control={control}
                                    render={({ field }) => (
                                      <TextBox
                                        size={SIZE.mini}
                                        value={field.value}
                                        onChange={(e) => {
                                          field.onChange(e.target.value);
                                        }}
                                      />
                                    )}
                                  />
                                </div>
                              </td>
                              <td>
                                <div
                                  style={{
                                    width: "150px",
                                    padding: "10px 10px 0px",
                                  }}
                                >
                                  <Controller
                                    name={`tail[${index}].frmt`}
                                    control={control}
                                    render={({ field }) => {
                                      const fldtailValue = watch(
                                        `tail[${index}].fld`
                                      ); // Watch the field value  console.log(fldtailValue, "dsfdfdfdf");
                                      const isTailDisabled =
                                        fldtailValue &&
                                        Array.isArray(fldtailValue) &&
                                        fldtailValue.length > 0
                                          ? fldtailValue[0]?.id === "TDY" // Check if the id is 'TDY'
                                            ? false // If true, disable is false
                                            : concateTailArrayAmount.some(
                                                (item) =>
                                                  item.id ===
                                                    fldtailValue[0]?.id &&
                                                  item.type === "AMOUNT"
                                              ) // Check if the id matches and type is "AMOUNT"
                                            ? false // If both conditions match, disable is true
                                            : true // Otherwise, disable is false
                                          : true; // If fldtailValue is invalid, disable is true

                                      return (
                                        <SelectBox
                                          size={SIZE.compact}
                                          clearable={false}
                                          {...field}
                                          error={
                                            errors?.tail?.[index]?.frmt &&
                                            errors.tail[index].frmt.message
                                          }
                                          label={intl.formatMessage({
                                            id: "format",
                                          })}
                                          placeholder={intl.formatMessage({
                                            id: "format",
                                          })}
                                          value={field.value}
                                          onChange={(e) => {
                                            field.onChange(e.value);
                                          }}
                                          options={
                                            concateTailArrayAmount.some(
                                              (item) =>
                                                item.id ===
                                                  fldtailValue[0]?.id &&
                                                item.type === "AMOUNT"
                                            )
                                              ? referenceData[
                                                  "fixed_width_amount_format"
                                                ]
                                              : referenceData[
                                                  "fixed_width_date_format"
                                                ]
                                          }
                                          disabled={isTailDisabled}
                                        />
                                      );
                                    }}
                                  />
                                </div>
                              </td>
                              <td>
                                <div
                                  style={{
                                    width: "70px",
                                    padding: "10px 10px 0px",
                                  }}
                                >
                                  <Controller
                                    name={`tail[${index}].len`}
                                    rules={{ required: "Required" }}
                                    control={control}
                                    render={({ field }) => (
                                      <TextBox
                                        size={SIZE.mini}
                                        value={field.value}
                                        onChange={(e) => {
                                          field.onChange(e.target.value);
                                        }}
                                        error={
                                          errors?.tail?.[index]?.len &&
                                          errors.tail[index].len.message
                                        }
                                        type="number"
                                      />
                                    )}
                                  />
                                </div>
                              </td>
                              <td>
                                <div
                                  style={{
                                    width: "150px",
                                    padding: "10px 10px 0px",
                                  }}
                                >
                                  <Controller
                                    name={`tail[${index}].aln`}
                                    control={control}
                                    rules={{ required: "Required" }}
                                    render={({ field }) => (
                                      <SelectBox
                                        size={SIZE.compact}
                                        clearable={false}
                                        {...field}
                                        error={
                                          errors?.tail?.[index]?.aln &&
                                          errors.tail[index].aln.message
                                        }
                                        value={field.value}
                                        onChange={(e) => {
                                          field.onChange(e.value);
                                        }}
                                        options={
                                          referenceData["align_type"] || []
                                        }
                                      />
                                    )}
                                  />
                                </div>
                              </td>
                              <td>
                                <div
                                  style={{
                                    width: "144px",
                                    padding: "10px 10px 0px",
                                  }}
                                >
                                  <Controller
                                    name={`tail[${index}].pad`}
                                    rules={{ required: "Required" }}
                                    control={control}
                                    render={({ field }) => (
                                      <TextBox
                                        size={SIZE.mini}
                                        value={field.value}
                                        onChange={(e) => {
                                          field.onChange(e.target.value);
                                        }}
                                        error={
                                          errors?.tail?.[index]?.pad &&
                                          errors.tail[index].pad.message
                                        }
                                      />
                                    )}
                                  />
                                </div>
                              </td>
                              <td>
                                <div
                                  style={{
                                    width: "50px",
                                    padding: "10px 10px 10px",
                                  }}
                                  onClick={() => {
                                    if (index > 0) {
                                      removeTailIndex(index);
                                    } else {
                                      setValue("tailcheck", false);
                                    }
                                  }}
                                >
                                  <Icon
                                    icon="delete_outline"
                                    color={"#FD372A"}
                                    isPressable
                                  />
                                </div>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  </div>
                  <div style={{ textAlign: "start" }}>
                    <TextButton
                      type="button"
                      kind={KIND.tertiary}
                      size={SIZE.mini}
                      disabled={
                        watch(`tail[${tailFields.length - 1}].st`) ===
                          undefined ||
                        watch(`tail[${tailFields.length - 1}].st`) === null ||
                        watch(`tail[${tailFields.length - 1}].st`) === ""
                      }
                      // onClick={() =>
                      //   appendTail({
                      //     st: watch(`tail[${tailFields.length-1}].st`),
                      //     fld: [],
                      //     value: "",
                      //     frmt: [],
                      //     len: "",
                      //     aln: [],
                      //     pad: "",
                      //   })
                      // }
                      onClick={() => {
                        const lastIndex = tailFields.length - 1; // Get last index
                        const lastSt = watch(`tail[${lastIndex}].st`); // Get the last st value
                        const lastLen = watch(`tail[${lastIndex}].len`); // Get the last len value

                        // Determine the next `st` value
                        const nextSt =
                          lastSt !== undefined && lastLen && lastLen !== ""
                            ? parseInt(lastSt) + parseInt(lastLen)
                            : parseInt(lastSt) + 1;

                        appendTail({
                          st: nextSt.toString(), // Set the next `st` value
                          fld: [],
                          value: "",
                          frmt: [],
                          len: null,
                          aln: [{ id: "L", label: "Left" }],
                          pad: " ",
                        });
                      }}
                    >
                      <Icon icon="plus" color={"#516BEB"} />
                      <span className="new_payment_plan">Add New</span>
                    </TextButton>
                  </div>
                </div>
              ) : null}
            </div>
          )}

        {Array.isArray(watch("typ")) &&
          getSelectValues(watch("typ")) === "DT" && (
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-start",
                gap: "10px",
              }}
            >
              <Typography type="p" className="text-secondary" subType="regular">
                Set Your Preferences
              </Typography>
              <div
                style={{ display: "flex", gap: "20px", alignItems: "center" }}
              >
                <Typography
                  className="regular"
                  type="p"
                  style={{ textAlign: "start" }}
                >
                  Include Headers
                </Typography>

                <Controller
                  name="ihd"
                  control={control}
                  defaultValues={false}
                  render={({ field }) => (
                    <div
                      style={{
                        textAlign: "center",
                      }}
                    >
                      <CheckBoxBaseweb
                        requiredAstric={true}
                        overrides={{
                          Root: {
                            style: ({ $theme }) => ({
                              alignItems: "center",
                            }),
                          },
                          Checkmark: {
                            style: ({ $theme, $checked }) => ({
                              height: "18px",
                              width: "18px",
                              backgroundColor: $checked ? "#516BEB" : null,
                              borderRadius: $theme.borders.radius200,
                              borderBottomColor: $checked
                                ? $theme.colors.primary
                                : `#CDCED9`,
                              borderTopColor: $checked
                                ? $theme.colors.primary
                                : `#CDCED9`,
                              borderRightColor: $checked
                                ? $theme.colors.primary
                                : `#CDCED9`,
                              borderLeftColor: $checked
                                ? $theme.colors.primary
                                : `#CDCED9`,
                              borderBottomWidth: "0.5px",
                              borderTopWidth: "0.5px",
                              borderRightWidth: "0.5px",
                              borderLeftWidth: "0.5px",
                              borderStyle: "solid",
                              backgroundImage: $checked
                                ? "url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTEiIGhlaWdodD0iOCIgdmlld0JveD0iMCAwIDExIDgiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CjxsaW5lIHgxPSIxLjQxNDIxIiB5MT0iNCIgeDI9IjQiIHkyPSI2LjU4NTc5IiBzdHJva2U9IiNmZmZmZmYiIHN0cm9rZS13aWR0aD0iMiIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIi8+CjxsaW5lIHgxPSI0IiB5MT0iNi41ODU3OSIgeDI9IjkuNTg1NzkiIHkyPSIxIiBzdHJva2U9IiNmZmZmZmYiIHN0cm9rZS13aWR0aD0iMiIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIi8+Cjwvc3ZnPgo=)"
                                : "none",
                              backgroundSize: "auto",
                            }),
                          },
                        }}
                        // labelPlacement={LABEL_PLACEMENT.left}
                        // {...i?.getToggleHiddenProps()}
                        {...field}
                        checked={field.value}
                        labelPlacement={LABEL_PLACEMENT.right}
                        onChange={(e) => field.onChange(e.target.checked)}
                      ></CheckBoxBaseweb>
                    </div>
                  )}
                />
              </div>
              <div
                style={{ display: "flex", alignItems: "center", gap: "20px" }}
              >
                <Typography
                  type="p"
                  className="regular"
                  style={{
                    marginBottom: "12px",
                    minWidth: "97px",
                    textAlign: "start",
                  }}
                >
                  Delimiter
                </Typography>
                <Controller
                  name="dlr"
                  defaultValue={","}
                  control={control}
                  rules={{ required: "Required" }}
                  render={({ field }) => (
                    <TextBox
                      size={SIZE.mini}
                      value={field.value}
                      onChange={(e) => {
                        field.onChange(e.target.value);
                      }}
                      label={intl.formatMessage({
                        id: "enter_comma",
                      })}
                      placeholder={intl.formatMessage({
                        id: "enter_comma",
                      })}
                      error={errors && errors.dlr && errors.dlr.message}
                      maxLength={1}
                    />
                  )}
                />
              </div>
            </div>
          )}

        <div
          style={{
            display: "flex",
            alignItems: "center",
            gap: "15px",
            justifyContent: "flex-end",
          }}
        >
          <TextButton
            type="button"
            kind={KIND.tertiary}
            size={SIZE.mini}
            style={{ color: "#516BEB", padding: "10px 35px" }}
            onClick={() => {
              let defaultFields = {
                st: "0",
                fld: [],
                value: "",
                frmt: [],
                len: null,
                aln: [{ id: "L", label: "Left" }],
                pad: " ",
              };
              let hdrChecks =
                _.get(singleleReportData, "data.doc.dwld_stg.hdr", []).length >
                0;
              let bdyChecks =
                _.get(singleleReportData, "data.doc.dwld_stg.bdy", []).length >
                0;
              let tailChecks =
                _.get(singleleReportData, "data.doc.dwld_stg.tail", []).length >
                0;
              reset({
                nam: _.get(singleleReportData, "data.doc.dwld_stg.nam", ""),
                typ: setSelectValues(
                  _.get(singleleReportData, "data.doc.dwld_stg.typ", []),
                  false
                ),
                hdrcheck: hdrChecks,
                bdycheck: bdyChecks,
                tailcheck: tailChecks,
                ihd: _.get(singleleReportData, "data.doc.dwld_stg.ihd", false),
                dlr: _.get(singleleReportData, "data.doc.dwld_stg.dlr", ","),
                hdr:
                  _.get(singleleReportData, "data.doc.dwld_stg.hdr", [])
                    .length > 0
                    ? _.get(
                        singleleReportData,
                        "data.doc.dwld_stg.hdr",
                        []
                      ).map((hdr) => ({
                        aln: hdr?.aln ? setSelectValues(hdr?.aln, false) : [],
                        fld: hdr?.fld ? setSelectValues(hdr?.fld, false) : [],
                        frmt: hdr?.frmt
                          ? setSelectValues(hdr?.frmt, false)
                          : [],
                        len: hdr?.len,
                        pad: hdr?.pad ? hdr?.pad : " ",
                        st: hdr?.st,
                        value: hdr?.value ? hdr?.value : "",
                      }))
                    : [defaultFields],
                bdy:
                  _.get(singleleReportData, "data.doc.dwld_stg.bdy", [])
                    .length > 0
                    ? _.get(
                        singleleReportData,
                        "data.doc.dwld_stg.bdy",
                        []
                      ).map((bdy) => ({
                        aln: bdy?.aln ? setSelectValues(bdy?.aln, false) : [],
                        fld: bdy?.fld ? setSelectValues(bdy?.fld, false) : [],
                        frmt: bdy?.frmt
                          ? setSelectValues(bdy?.frmt, false)
                          : [],
                        len: bdy?.len,
                        pad: bdy?.pad ? bdy?.pad : " ",
                        st: bdy?.st,
                        value: bdy?.value ? bdy?.value : "",
                      }))
                    : [defaultFields],
                tail:
                  _.get(singleleReportData, "data.doc.dwld_stg.tail", [])
                    .length > 0
                    ? _.get(
                        singleleReportData,
                        "data.doc.dwld_stg.tail",
                        []
                      ).map((tail) => ({
                        aln: tail?.aln ? setSelectValues(tail?.aln, false) : [],
                        fld: tail?.fld ? setSelectValues(tail?.fld, false) : [],
                        frmt: tail?.frmt
                          ? setSelectValues(tail?.frmt, false)
                          : [],
                        len: tail?.len,
                        pad: tail?.pad ? tail?.pad : " ",
                        st: tail?.st,
                        value: tail?.value ? tail?.value : "",
                      }))
                    : [defaultFields],
              });
              reset();
            }}
          >
            Reset
          </TextButton>
          <TextButton
            type="submit"
            size={SIZE.mini}
            style={{ padding: "10px 35px" }}
            isLoading={isSubmitting}
          >
            Save
          </TextButton>
        </div>
      </form>
    </div>
  );
};

export default ReportSettingDrawer;
