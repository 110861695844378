import React, { useContext, useRef, useMemo } from "react";
import { useMutation, useQuery } from "react-query";
import { useParams } from "react-router-dom";
import { useIntl } from "react-intl";
import moment from "moment";
import _ from "lodash";

import { Icon, RDText, Typography } from "../../components_v2";

import { MaxyfiContext } from "../../providers/MaxyfiProvider";
import {
  deleteAdjustment,
  getCustomerOverviewTimelineList,
  getDisputeContent,
} from "../../services";
import DateFormate from "../../components_v2/DateFormate";
import RDRapper from "../../components_v2/RDRapper";
import CurrencyFormate from "../../components_v2/CurrencyFormate";
import queryClient from "../../providers/queryClient";
import ChipFilterNew from "../../components/ChipFilterNew";
import { useState } from "react";
import CheckBoxBaseweb from "../../components/CheckBoxBaseweb";
import { ParagraphSmall } from "baseui/typography";
import { LABEL_PLACEMENT, STYLE_TYPE } from "baseui/checkbox";
import { StatefulTooltip, PLACEMENT } from "baseui/tooltip";
import {
  ACT_RETURN_VISIT,
  CO_INVOICES_,
  CO_TIMELINE_,
  CUSTOMER_OVERVIEW_,
  EDIT,
  FIELD_UNSUCCESSFUL,
  FORWARD,
  REPLY,
  REPLY_ALL,
  TL_NOTES,
  WORKFLOW_STRATEGY,
} from "../../constants";
import {
  ADJUSTMENT_DRAWER,
  EMAIL_TIMELINE_LAYOUT,
  REPLAY_SMS_DRAWER,
  TIMELINE_DRAWER,
  WHATSAPP_CHAT,
  emailDataToDrawer,
  setDrawerState,
  smsDataToDrawer,
} from "../../redux/actions";
import { useDispatch, useSelector } from "react-redux";
import DateComponent from "../../components_v2/DateComponent";
import { DATE_ORG } from "../../constants";
import dateFn from "../../components_v2/DateFn";
import DateFn from "../../components_v2/DateFn";
import useFormat from "../../hooks/useFormat";
import {
  timelineChatRefetch,
  timelineWhatsappDrawer,
} from "../../redux/customerOverview/timeline/action";
import WhatsAppChat from "../../components/WhatsAppChat/WhatsAppChatV2";
import { toast } from "react-toastify";
import { StatefulPopover, TRIGGER_TYPE } from "baseui/popover";
import { KIND, TextButton } from "../../components/TextButton";
import RBACWrapper from "../../providers/RBACProvider/RBACWrapper";
import {
  DELETE_ADJUSTMENT,
  DISABLE_NOTES,
  DISABLE_TIMELINE,
  EDIT_ADJUSTMENT,
  EMAIL_REPLY,
  SMS_REPLY,
} from "../../providers/RBACProvider/permissionList";
import { currencyList } from "../../utils_v2/CurrencyToSymbol";

const InvAdjCon = (props) => {
  const { action_taken, name = "", content } = props;
  const { customerId } = useParams();
  const format = useFormat();
  const { currentOrganization } = useContext(MaxyfiContext);
  const onSuccessRefetch = () => {
    queryClient.refetchQueries([`${CUSTOMER_OVERVIEW_}${customerId}`]);
    queryClient.refetchQueries([`${CO_TIMELINE_}${customerId}`]);
    queryClient.refetchQueries([`${CO_INVOICES_}${customerId}`]);
  };
  let dispatch = useDispatch();
  const setDrawer = (type, data) => {
    if (type) {
      dispatch(setDrawerState({ active: true, type, data }));
    }
  };
  const removeAdjustment = useMutation(
    (adjData) =>
      deleteAdjustment({
        ...adjData,
        organization: currentOrganization,
        invoiceId: _.get(props, "content.portal_payment_invoice_id[0]", ""),
      }),
    {
      onError: (error, variables, context) => {
        if (error.response) {
          let { data } = error.response;
          let errorData = data.message;
          toast.error(errorData);
        }

        //Need to handle if no error response is send from backend
      },
      onSuccess: (data, variables, context) => {
        onSuccessRefetch();
      },
    }
  );
  return (
    <div className="adj_con_timeline_wraper">
      <TimelineCondensedWrapper
        {...props}
        icon={"adjustment"}
        name={_.get(props, "name", "")}
        content={`${_.get(
          props,
          "content.portal_payment_invoice_number[0]",
          ""
        )} ${
          _.get(props, "content.portal_payment_client_references[0]", "")
            ? "/"
            : ""
        } ${_.get(
          props,
          "content.portal_payment_client_references[0]",
          ""
        )} adjusted by amount ${_.get(
          props,
          "content.portal_payment_amount.currency",
          ""
        )} ${format.currency({
          amount: _.get(props, "content.portal_payment_amount.value", ""),
        })}`}
      />
      {_.get(props, "del_by", "") ? (
        <></>
      ) : (
        <div className="adj_con_timeline_icon_wraper">
          <RBACWrapper role={EDIT_ADJUSTMENT} type="PERMISSION">
            <Icon
              icon="edit"
              isPressable
              size={18}
              color="#ADADAD"
              onClick={() =>
                setDrawer(ADJUSTMENT_DRAWER, {
                  invID: _.get(
                    props,
                    "content.portal_payment_invoice_id[0]",
                    ""
                  ),
                  drawerType: EDIT,
                  ...props,
                })
              }
            />
          </RBACWrapper>
          <RBACWrapper role={DELETE_ADJUSTMENT} type="PERMISSION">
            <StatefulPopover
              triggerType={TRIGGER_TYPE.hover}
              autoFocus={false}
              content={({ close }) => (
                <div className="adj_popover_container">
                  <div className="adj_popover_head_container">
                    <Icon icon="alert" color="#FD372A" />
                    <Typography type="p">Do you want to continue?</Typography>
                  </div>
                  <Typography
                    type="p"
                    subType="regular"
                    className="text-secondary"
                  >
                    This will delete the adjustment entry
                  </Typography>
                  <div className="adj_popover_footer_container">
                    <TextButton
                      size="mini"
                      kind={KIND.tertiary}
                      type="button"
                      onClick={() => close()}
                    >
                      <Typography type="p" subType="regular">
                        Back
                      </Typography>
                    </TextButton>
                    <TextButton
                      size="mini"
                      kind={KIND.secondary}
                      onClick={() => {
                        removeAdjustment.mutateAsync({
                          timelineId: _.get(props, "_id", ""),
                        });
                        close();
                      }}
                      type="error"
                    >
                      <Typography
                        type="p"
                        subType="regular"
                        className="text-error"
                      >
                        Confirm
                      </Typography>
                    </TextButton>
                  </div>
                </div>
              )}
            >
              <div>
                <Icon icon="delete" color="#ADADAD" isPressable size={18} />
              </div>
            </StatefulPopover>
          </RBACWrapper>
        </div>
      )}
    </div>
  );
};

const TimelineCondensedWrapper = (props) => {
  const {
    currentDefaultFormatDetails,
    referenceData,
    userInfo,
    currentOrganization,
  } = useContext(MaxyfiContext);
  const [isHovered, setIsHovered] = useState(false);

  let dispatch = useDispatch();

  const setDrawer = (type, data) => {
    if (type) {
      dispatch(setDrawerState({ active: true, type, data }));
    }
  };

  const {
    comment = "",
    content = "",
    containerClassName = "",
    executed_by,
    executed_on,
    icon,
    iconClassName = "",
    name = "",
    nameClassName = "",
    audio = "",
    color = "#333860",
    is_receiver = false,
    isReply = false,
    disputeForward = false,
    subjectBodyDispatch,
    whatsupIcon = false,
    isForward = false,
    isReplyAll = false,
    maniData = {},
    subData,
    type,
    note_type,
    isOverView = true,
    isReplayOnly = false,
    customer_id,
    _id,
  } = props;

  const executedBy =
    referenceData &&
    referenceData["users"] &&
    Array.isArray(referenceData["users"])
      ? referenceData["users"].find((e) => e.id === executed_by)
      : null;

  return (
    <div
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      className={`co-timeline-condensed_card --timeline_wraper_position ${containerClassName} ${
        (isReply || disputeForward) && "--timeline_wraper_position"
      } `}
    >
      <div style={{ display: "flex", width: "95px" }}>
        <div style={{ width: "20px" }}>
          {note_type === "INFORMATIONAL" ||
          note_type === "ALERT" ||
          note_type === "CRITICAL" ? (
            <>
              <Icon
                icon={"unpin"}
                color={color}
                size={18}
                className={iconClassName}
              />
            </>
          ) : (
            <></>
          )}
        </div>
        <div className="co-timeline-condensed_element">
          <Typography
            type="p"
            subType="regular"
            className="co-timeline-condensed_content"
          >
            {moment
              .utc(executed_on)
              .format(currentDefaultFormatDetails.date_format)}
          </Typography>
        </div>
      </div>
      <div className="co-timeline-condensed_element co-timeline-condensed_element--name">
        {icon == "alert" ? (
          <>
            {
              <Icon
                icon={"note"}
                color={color}
                size={18}
                className={iconClassName}
              />
            }
          </>
        ) : (
          <>
            {" "}
            {icon === "next_action" ? (
              <>
                <div style={{ width: "18px", paddingLeft: "1px" }}>
                  <Icon
                    icon={icon}
                    color={color}
                    size={14}
                    className={iconClassName}
                  />
                </div>
              </>
            ) : (
              <>
                <Icon
                  icon={icon}
                  color={color}
                  size={18}
                  className={iconClassName}
                />
              </>
            )}
          </>
        )}
        {/* {s && icon ? (
          <Icon icon={icon} color={color} size={18} className={iconClassName} />
        ) : (
          <></>
        )} */}
        <Typography
          type="p"
          subType="regular"
          className={`co-timeline-condensed_content ${nameClassName}`}
        >
          <StatefulTooltip content={() => <div> {name ? name : ""} </div>}>
            {name}
          </StatefulTooltip>
        </Typography>
      </div>
      <div
        className="co-timeline-condensed_element co-timeline-condensed_element--description"
        style={{ display: "flex", alignItems: "center", gap: "5px" }}
      >
        <Typography
          type="p"
          subType="regular"
          className="co-timeline-condensed_content"
        >
          {audio ? (
            <audio
              style={{ height: "25px" }}
              controls
              controlsList="nodownload noplaybackrate"
            >
              <source src={audio} type="audio/mpeg" />
              <source src={audio} type="audio/ogg" />
              <source src={audio} type="audio/wav" />
            </audio>
          ) : (
            ""
          )}
          {content}
        </Typography>
      </div>

      {icon === "whatsapp" && (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            gap: "2px",
            paddingRight: "10px",
            paddingLeft: "10px",
          }}
        >
          {/* <Icon icon="view_icon" isPressable /> */}
          <Icon
            icon="sms_outline"
            size={16}
            isPressable
            onClick={() => {
              setDrawer(WHATSAPP_CHAT, {
                phoneNumber: _.get(props, "whatsupData.content.to", [])
                  ? _.get(props, "whatsupData.content.to[0].value", "")
                  : "",
              });
              // dispatch(
              //   timelineWhatsappDrawer({
              //     phoneNumber: _.get(props, "whatsupData.content.to", [])
              //       ? _.get(props, "whatsupData.content.to", [])[0]?.value
              //       : "",
              //   })
              // );
              // dispatch(timelineChatRefetch());
            }}
          />
        </div>
      )}
      {/* {disputeForward && (
        <div
          style={
            isHovered ? { backgroundColor: "#cdced9", borderRadius: "5px" } : {}
          }
        >
          <Icon
            style={{ visibility: isHovered ? "visible" : "hidden" }}
            icon="forward"
            isPressable
            onClick={() => {
              setDrawer(EMAIL_TIMELINE_LAYOUT, {
                emailType: FORWARD,
                type: subjectBodyDispatch,
              });
            }}
          />
        </div>
      )} */}

      <div
        className={`co_timeline_email_reply_container ${
          type === "whatsapp" ? "--view_icon_postion" : ""
        }`}
      >
        {isOverView ? (
          <div className="view-condensed-timline">
            <Icon
              icon="view_icon"
              size={18}
              color="#516BEB"
              isPressable
              onClick={() => {
                if (props?.setSelectedId) {
                  props?.setSelectedId(props?._id);
                }
              }}
            />
          </div>
        ) : (
          <></>
        )}

        {isReply && (
          <>
            <div className="co_timeline_reply_action">
              <Icon
                icon="replay"
                isPressable
                onClick={() => {
                  dispatch(
                    emailDataToDrawer({
                      emailType: REPLY,
                      emailData: maniData,
                    })
                  );

                  setDrawer(EMAIL_TIMELINE_LAYOUT, {
                    emailType: REPLY,
                    type: maniData,
                    subData: subData,
                  });
                }}
              />
            </div>
            <div className="co_timeline_reply_action">
              <Icon
                icon="replay_all"
                isPressable
                onClick={() => {
                  dispatch(
                    emailDataToDrawer({
                      emailType: REPLY_ALL,
                      emailData: maniData,
                      subData: subData,
                    })
                  );

                  setDrawer(EMAIL_TIMELINE_LAYOUT, {
                    emailType: REPLY_ALL,
                    type: maniData,
                    subData: subData,
                  });
                }}
              />
            </div>
          </>
        )}
        {isReplayOnly && (
          <>
            <div className="co_timeline_reply_action">
              <Icon
                icon="replay"
                isPressable
                onClick={() => {
                  dispatch(smsDataToDrawer(props?.allContent));
                  setDrawer(REPLAY_SMS_DRAWER, props?.allContent);
                }}
              />
            </div>
          </>
        )}

        {isReply || disputeForward ? (
          <div className="co_timeline_reply_action">
            <Icon
              icon="forward"
              isPressable
              onClick={async () => {
                if (disputeForward) {
                  return await getDisputeContent({
                    organization: currentOrganization,
                    customerId: customer_id,
                    disputeId: _id,
                  })
                    .then((res) => {
                      dispatch(
                        emailDataToDrawer({
                          emailType: FORWARD,
                          emailData: {},

                          subData: undefined,
                        })
                      );

                      setDrawer(EMAIL_TIMELINE_LAYOUT, {
                        emailType: FORWARD,
                        subData: undefined,
                        ...(_.get(res, "data.doc.template_id", "")
                          ? {
                              template_id: _.get(
                                res,
                                "data.doc.template_id",
                                ""
                              ),
                            }
                          : {}),

                        type: {
                          ...props.content,
                          type: DISPUTE,
                          disputeSubject: _.get(res, "data.doc.subject", ""),
                          disputeBody: _.get(res, "data.doc.value", ""),
                          disputeTemID: _.get(res, "data.doc.template_id", ""),
                          disputeId: _id,
                        },
                      });
                    })
                    .catch((err) =>
                      toast.error(_.get(err, "response.data.message", ""))
                    );
                } else {
                  dispatch(
                    emailDataToDrawer({
                      emailType: FORWARD,
                      emailData: maniData,
                      subData: subData,
                    })
                  );

                  setDrawer(EMAIL_TIMELINE_LAYOUT, {
                    emailType: FORWARD,
                    type: maniData,
                    subData: subData,
                    type: isReply ? maniData : subjectBodyDispatch,
                  });
                }
              }}
            />
          </div>
        ) : (
          <></>
        )}
      </div>

      <div className="co-timeline-condensed_element co-timeline-condensed_element--info">
        {type != "notes" && comment && comment.length > 0 ? (
          // <Icon icon="comment" color="#333860" size={18} />
          <StatefulTooltip
            placement={PLACEMENT.leftBottom}
            showArrow
            overrides={{
              Arrow: {
                style: ({ $theme }) => ({
                  outline: `#16164B solid`,
                  backgroundColor: "#16164B",
                }),
              },
              Inner: {
                style: ({ $theme }) => ({
                  outline: `#16164B solid`,
                  backgroundColor: "#16164B",
                }),
              },
            }}
            content={() => (
              <div style={{ backgroundColor: "#16164B" }}>
                <Typography
                  type="p"
                  subType="regular"
                  style={{ color: "white" }}
                >
                  {type === "app_payment" ? "Payment Desc" : "Comments"} :{" "}
                  {comment
                    ? comment.replace(/(<([^>]+)>)/gi, "").replace("&amp;", "")
                    : ""}
                </Typography>
              </div>
            )}
            returnFocus
            autoFocus
          >
            <Icon icon="comment" color="#333860" size={18} />
          </StatefulTooltip>
        ) : (
          <></>
        )}

        <Typography
          type="p"
          subType="regular"
          className="co-timeline-condensed_content"
        >
          {executed_by && executedBy?.label
            ? executedBy?.label
            : is_receiver
            ? "Consumer"
            : "System"}
        </Typography>
      </div>
    </div>
  );
};

const deliveryStatus = {
  PARTIALLY_DELIVERED_AND_READ: {
    title: "Partially Delivered and Read",
    textClassName: "",
    iconClassName: "mx-icon--path-fill-success",
  },
  PARTIALLY_DELIVERED: {
    title: "Partially Delivered",
    textClassName: "",
    iconClassName: "mx-icon--path-fill-success",
  },
  BOUNCED: {
    title: "Bounced",
    textClassName: "text-error",
    iconClassName: "mx-icon--path-fill-error",
  },
  FAILED: {
    title: "Failed",
    textClassName: "text-error",
    iconClassName: "mx-icon--path-fill-error",
  },
  FAILURE: {
    title: "Failed",
    textClassName: "text-error",
    iconClassName: "mx-icon--path-fill-error",
  },
  DELIVERED: {
    title: "Delivered",
    textClassName: "",
    iconClassName: "mx-icon--path-fill-success",
  },
  SUCCESS: {
    title: "Delivered",
    textClassName: "",
    iconClassName: "mx-icon--path-fill-success",
  },
  IN_PROGRESS: {
    title: "In Progress",
  },
  READ: {
    title: "Read",
    textClassName: "",
    iconClassName: "mx-icon--path-fill-success",
  },
  SENT: {
    title: "Sent",
    textClassName: "",
    iconClassName: "mx-icon--path-fill-success",
  },
};

let CallOutcome = ({ data, calloutcome }) => {
  const {
    referenceData,
    currentOrganization,
    currentDefaultFormatDetails,
    currencyDecimalSeparator,
    currencyGroupStyle,
    currencySeparator,
    users,
    space,
    userInfo,
  } = useContext(MaxyfiContext);
  const format = useFormat();
  switch (calloutcome) {
    case CALL_BACK_LATER: {
      return (
        <>
          <TimelineCondensedWrapper
            {...data}
            icon="callback_later"
            name="Call Back Later"
            content={`${
              data && data?.action_taken === "CL_RESDUL"
                ? "Rescheduled -"
                : data && data?.action_taken === "Deleted"
                ? "Deleted -"
                : ""
            }  Call Back Later on ${format.date({
              isOrg: true,
              value: _.get(data, "content.callback_date", "-"),
            })} ${
              _.get(data, "content.callback_time", "") ? "at" : " "
            } ${format.rd({
              name: "time",
              id: _.get(data, "content.callback_time", ""),
            })}
             `}
          />
        </>
      );
    }
    case CALL_DISPUTE: {
      const { name, content } = data;
      const orgRef = referenceData?.organizations?.find(
        ({ id }) => id === currentOrganization
      );
      let disputeStatus = referenceData["dispute_status"];
      const disputeInvoices = data?.content?.dispute_invoices
        ?.map((i) => i.toString())
        .toString()
        .replaceAll(",", ", ");

      let refType = referenceData["dispute_type"];
      const timeZone = orgRef?.time_zone;

      const findingStatus = disputeStatus?.find(
        (i) => i.id === data?.content?.dispute_status
      );
      const findingValue = refType?.find(
        (i) => i.id === data?.content?.dispute_type
      );
      const disputeRaisedOn = moment
        .utc(data.executed_on)
        ?.tz(timeZone)
        ?.format(currentDefaultFormatDetails.date_format);

      const timezoneBasedDate = moment
        .utc(data?.content?.dispute_review_date)
        ?.tz(timeZone)
        ?.format(currentDefaultFormatDetails.date_format);
      const subjectBodyDispatch = {
        ...data.content,
        disputeSubject: `${
          _.get(data, "cna", "") ? _.get(data, "cna", "") : "-"
        } : Dispute : ${
          findingValue && findingValue?.label ? findingValue.label : "-"
        } Raised on ${disputeRaisedOn}`,
        disputeBody: `Hi,<br /><br />Find below the details of the Dispute Raised by ${
          _.get(data, "cna", "") ? _.get(data, "cna", "") : "-"
        }<br />Dispute Type: ${
          findingValue && findingValue?.label ? findingValue?.label : "-"
        }<br />Dispute Details: ${
          _.get(data, "content.dispute_details", "")
            ? _.get(data, "content.dispute_details", "")
            : "-"
        }<br />Disputed Invoice Numbers: ${
          disputeInvoices ? disputeInvoices : "-"
        }<br /><br />Dispute Status : ${
          findingStatus?.label ? findingStatus?.label : "-"
        }<br />Dispute Review Date : ${
          timezoneBasedDate ? timezoneBasedDate : "-"
        }<br />User Comments :  ${
          data.comment ? data.comment : "-"
        }<br /><br />Regards<br />${
          userInfo && userInfo?.display_name ? userInfo?.display_name : "-"
        }`,
      };

      return (
        <TimelineCondensedWrapper
          {...data}
          icon="dispute"
          name={`Dispute ${
            _.get(data, "content.dispute_status", "") ? "-" : ""
          } ${_.capitalize(_.get(data, "content.dispute_status", ""))}`}
          disputeForward={
            space && space?.role_permissions?.includes(EMAIL_REPLY)
          }
          subjectBodyDispatch={subjectBodyDispatch}
          content={`${
            content.dispute_type
              ? format.rd({ name: "dispute_type", id: content.dispute_type })
              : ""
          } ${_.get(content, "dispute_review_date", "") ? "&" : ""} ${
            _.get(data, "content.dispute_review_date", null)
              ? `Review Date - ${format.date({
                  value: _.get(data, "content.dispute_review_date", null),
                })}`
              : ""
          } (${
            _.get(content, "dispute_invoices", [])
              ? `${_.get(content, "dispute_invoices", []).length} invoice ${
                  _.get(data, "content.amt", null)
                    ? `, ${
                        currencyList[
                          currentDefaultFormatDetails?.default_currency
                        ]
                      } ${format.currency({
                        amount: _.get(data, "content.amt", null),
                      })}`
                    : ""
                }`
              : ""
          })`}
        />
      );
    }
    case CALL_UNSUCCESSFUL: {
      return (
        <TimelineCondensedWrapper
          {...data}
          icon="call_unsuccessfull"
          name="Call - Unsuccessfull"
          content={`${
            data && data?.content && data?.content?.followup_date
              ? `
              Follow-up Call on ${format.date({
                isOrg: true,
                value: data?.content?.followup_date,
              })}
            `
              : `
              Reason -
              ${
                data?.content?.call_unsuccessful_reason
                  ? `${format.rd({
                      id: data?.content?.call_unsuccessful_reason,
                      name: "call_unsuccessful_reason",
                    })}`
                  : data &&
                    data?.content &&
                    data?.content?.call_unsuccessful_reason
              }
              `
          }`}
        />
      );
    }
    case FIELDVISIT: {
      return (
        <TimelineCondensedWrapper
          {...data}
          icon="field_visit"
          name="Call - Field Visit"
          content={`Reason - Field Visit`}
        />
      );
    }
    case TL_NOTES: {
      return (
        <TimelineCondensedWrapper
          {...data}
          icon="note"
          content={`Comment - ${
            _.get(data, "comment")
              ? _.get(data, "comment").replace(/&amp;/g, "&")
              : " - "
          }`}
        />
      );
    }
    case PAYMENT: {
      const { name = "", action_taken } = data;
      return (
        <TimelineCondensedWrapper
          {...data}
          icon="payment"
          name={`Payment-${action_taken}`}
          content={`Call - ${_.get(
            data,
            "content.portal_payment_gateway_name"
          )} Amount: ${_.get(
            data,
            "content.portal_payment_amount.currency"
          )} ${format.currency({
            amount: _.get(data, "content.portal_payment_amount.value"),
          })}  ${_.get(data, "action_taken")}  `}
        />
      );
    }

    case PAYMENT_PLAN: {
      const { name = "", content } = data;

      return (
        <TimelineCondensedWrapper
          {...data}
          icon="promise_to_pay"
          name={`Payment Plan`}
          content={`${_.get(
            data,
            "content.payment_plan_frequency"
          )} Payment Plan with Installment amount ${_.get(
            data,
            "content.payment_plan_instalation_amount.currency"
          )} ${format.currency({
            amount: _.get(
              data,
              "content.payment_plan_instalation_amount.value"
            ),
          })} `}
        />
      );
    }

    case PROMISETOPAY: {
      const { name = "", content } = data;
      const status =
        deliveryStatus && deliveryStatus[content?.promise_status]
          ? deliveryStatus[content?.promise_status]
          : null;
      return (
        <TimelineCondensedWrapper
          {...data}
          icon="promise_to_pay"
          name={`Promise - ${_.get(status, "title", "")}`}
          content={`Promised Payment of ${_.get(
            data,
            "content.promise_amount.currency"
          )} ${format.currency({
            amount: _.get(data, "content.promise_amount.value"),
          })} by ${format.date({
            isOrg: true,
            value: content?.promise_date,
          })}`}
        />
      );
    }

    case PROTALPAYMENT: {
      return (
        <TimelineCondensedWrapper
          {...data}
          icon="promise_to_pay"
          name="App - Payment"
          comment={`Call - ${_.get(
            data,
            "content.portal_payment_gateway_name"
          )} Amount: ${_.get(
            data,
            "content.portal_payment_amount.currency"
          )} ${format.currency({
            amount: _.get(data, "content.portal_payment_amount.value"),
          })}`}
        />
      );
    }

    default:
      return null;
  }
};
let FieldOutCome = ({ data, visitOutcome }) => {
  const {
    referenceData,
    currentOrganization,
    currentDefaultFormatDetails,
    currencyDecimalSeparator,
    currencyGroupStyle,
    currencySeparator,
    users,
    space,
    userInfo,
  } = useContext(MaxyfiContext);
  const format = useFormat();
  switch (visitOutcome) {
    case CALL_BACK_LATER: {
      return (
        <>
          <TimelineCondensedWrapper
            {...data}
            icon="callback_later"
            name="Field Visit"
            content={`Call Back Later on ${format.date({
              isOrg: true,
              value: data?.executed_on,
            })} at  ${format.rd({
              id: _.get(data, "content.callback_time", ""),
              name: "time",
            })}
             `}
          />
        </>
      );
    }
    case CALL_DISPUTE: {
      const { name, content } = data;
      const orgRef = referenceData?.organizations?.find(
        ({ id }) => id === currentOrganization
      );
      let disputeStatus = referenceData["dispute_status"];
      const disputeInvoices = data?.content?.dispute_invoices
        ?.map((i) => i.toString())
        .toString()
        .replaceAll(",", ", ");

      let refType = referenceData["dispute_type"];
      const timeZone = orgRef?.time_zone;

      const findingStatus = disputeStatus?.find(
        (i) => i.id === data?.content?.dispute_status
      );
      const findingValue = refType?.find(
        (i) => i.id === data?.content?.dispute_type
      );
      const disputeRaisedOn = moment
        .utc(data?.executed_on)
        ?.tz(timeZone)
        ?.format(currentDefaultFormatDetails?.date_format);

      const timezoneBasedDate = moment
        .utc(data?.content?.dispute_review_date)
        ?.tz(timeZone)
        ?.format(currentDefaultFormatDetails?.date_format);
      const subjectBodyDispatch = {
        ...data.content,
        disputeSubject: `${
          _.get(data, "cna", "") ? _.get(data, "cna", "") : "-"
        } : Dispute : ${
          findingValue && findingValue.label ? findingValue.label : "-"
        } Raised on ${disputeRaisedOn}`,
        disputeBody: `Hi,<br /><br />Find below the details of the Dispute Raised by ${
          _.get(data, "cna", "") ? _.get(data, "cna", "") : "-"
        }<br />Dispute Type: ${
          findingValue && findingValue?.label ? findingValue?.label : "-"
        }<br />Dispute Details: ${
          _.get(data, "content.dispute_details", "")
            ? _.get(data, "content.dispute_details", "")
            : "-"
        }<br />Disputed Invoice Numbers: ${
          disputeInvoices ? disputeInvoices : "-"
        }<br /><br />Dispute Status : ${
          findingStatus?.label ? findingStatus?.label : "-"
        }<br />Dispute Review Date : ${
          timezoneBasedDate ? timezoneBasedDate : "-"
        }<br />User Comments :  ${
          data.comment ? data.comment : "-"
        }<br /><br />Regards<br />${
          userInfo && userInfo?.display_name ? userInfo?.display_name : "-"
        }`,
      };

      return (
        <TimelineCondensedWrapper
          {...data}
          icon="dispute"
          name={`Dispute ${
            _.get(data, "content.dispute_status", "") ? "-" : ""
          } ${_.capitalize(_.get(data, "content.dispute_status", ""))}`}
          disputeForward={
            space && space?.role_permissions?.includes(EMAIL_REPLY)
          }
          subjectBodyDispatch={subjectBodyDispatch}
          content={`${
            content.dispute_type
              ? format.rd({ name: "dispute_type", id: content.dispute_type })
              : ""
          } ${_.get(content, "dispute_review_date", "") ? "&" : ""} ${
            _.get(data, "content.dispute_review_date", null)
              ? `Review Date - ${format.date({
                  value: _.get(data, "content.dispute_review_date", null),
                })}`
              : ""
          } (${
            _.get(content, "dispute_invoices", [])
              ? `${_.get(content, "dispute_invoices", []).length} invoice ${
                  _.get(data, "content.amt", null)
                    ? `, ${
                        currencyList[
                          currentDefaultFormatDetails?.default_currency
                        ]
                      } ${format.currency({
                        amount: _.get(data, "content.amt", null),
                      })}`
                    : ""
                }`
              : ""
          })`}
        />
      );
    }
    case CALL_UNSUCCESSFUL: {
      return (
        <TimelineCondensedWrapper
          {...data}
          icon="call_unsuccessfull"
          name="Field Visit"
          content={`${
            data && data?.content && data?.content?.followup_date
              ? `
              Follow-up Call on ${format.date({
                isOrg: true,
                value: data?.content?.followup_date,
              })}
            `
              : `
              Reason -
              ${
                data?.content?.call_unsuccessful_reason
                  ? `${format.rd({
                      id: data?.content?.call_unsuccessful_reason,
                      name: "call_unsuccessful_reason",
                    })}`
                  : data &&
                    data?.content &&
                    data?.content?.call_unsuccessful_reason
              }
              `
          }`}
        />
      );
    }
    case ACT_RETURN_VISIT: {
      return (
        <TimelineCondensedWrapper
          {...data}
          icon="field_visit"
          name="Field Visit - Return Visit"
          content={`Field Visit Date on ${moment(
            data?.content?.return_visit_date
          )
            .tz(currentDefaultFormatDetails.time_zone)
            .format(currentDefaultFormatDetails.date_format)} ${format.rd({
            id: _.get(data, "content.return_visit_time", ""),
            name: "time",
          })} ${data?.content?.visit_address?.line_1} 
            ${data?.content?.visit_address?.line_2} 
            ${data?.content?.visit_address?.city}
            ${data?.content?.visit_address?.state}
            ${data?.content?.visit_address?.country}
            ${data?.content?.custom_location}`}
        />
      );
    }
    case TL_NOTES: {
      return (
        <TimelineCondensedWrapper
          {...data}
          icon="note"
          name="Field Visit"
          content={`Comment -  ${
            _.get(data, "comment")
              ? _.get(data, "comment").replace(/&amp;/g, "&")
              : " - "
          }`}
        />
      );
    }
    case PAYMENT: {
      const { name = "", action_taken } = data;
      return (
        <TimelineCondensedWrapper
          {...data}
          icon="payment"
          name={`Field Visit-${_.get(data, "action_taken", "-")}`}
          content={`Payment - ${_.get(
            data,
            "content.portal_payment_method",
            "-"
          )} Amount: ${_.get(
            data,
            "content.portal_payment_amount.currency",
            "-"
          )} ${format.currency({
            amount: _.get(data, "content.portal_payment_amount.value", "-"),
          })}  ${_.get(data, "action_taken", "-")}  `}
        />
      );
    }

    case PAYMENT_PLAN: {
      const { name = "", content } = data;

      return (
        <TimelineCondensedWrapper
          {...data}
          icon="promise_to_pay"
          name={`Field Visit`}
          content={`${_.get(
            data,
            "content.payment_plan_frequency"
          )} Payment Plan with Installment amount ${_.get(
            data,
            "content.payment_plan_instalation_amount.currency"
          )} ${format.currency({
            amount: _.get(
              data,
              "content.payment_plan_instalation_amount.value"
            ),
          })} `}
        />
      );
    }

    case PROMISETOPAY: {
      const { name = "", content } = data;
      const status =
        deliveryStatus && deliveryStatus[content?.promise_status]
          ? deliveryStatus[content?.promise_status]
          : null;
      return (
        <TimelineCondensedWrapper
          {...data}
          icon="promise_to_pay"
          name={`Field Visit - ${_.get(status, "title")}`}
          content={`Promised Payment of ${_.get(
            data,
            "content.promise_amount.currency"
          )} ${format.currency({
            amount: _.get(data, "content.promise_amount.value"),
          })} by ${format.date({
            isOrg: true,
            value: content?.promise_date,
          })}`}
        />
      );
    }
    default:
      return null;
  }
};

/* TIMELINE ACTIONS */
const APP_PAYMENT = "app_payment";
const BUSINESS_UNIT = "business_unit";
const CALL = "call";
const CALL_BACK_LATER = "CALL_BACK_LATER";
const CALL_DISPUTE = "RECORD_DISPUTE";
const CALL_RECORDING = "call_recording";
const CALL_UNSUCCESSFUL = "UNSUCCESSFUL";
const CONTACTS = "contacts";
const CUSTOMER_NAME_UPDATE = "CUSTOMER_NAME_UPDATE";
const CUSTOMER_STATUS = "customer_status";
const CUSTOMERPORTAL = "customer_portal";
const DISPUTE = "dispute";
const EMAIL = "email";
const FIELDVISIT = "FIELD_VISIT";
const HOLDACTION = "hold_action";
const INVOICE_UPDATE = "INVOICE_UPDATE";
const LETTER = "letter";
const NEXTACTION = "NEXT_ACTION";
const NOTES = "notes";
const INSTANT_PAYMENT = "payment";
const PAYMENT = "PAYMENT";
const PROMISETOPAY = "PROMISE_TO_PAY";
const PAYMENT_PLAN = "PAYMENT_PLAN";
const PORTAL_PAYMENT = "portal_payment";
const PROTALPAYMENT = "PROTALPAYMENT";
const RELEASED = "release_action";
const RESCHEDULE_NEXT_ACTION_DATE = "RESCHEDULE_NEXT_ACTION_DATE";
const RM_ASSIGNED = "rel_manager";
const UNASSIGNED_RM = "UA_RM";
const SKIPPEDTIMELINE = "action_skipped";
const SMS = "sms";
const WHATSAPP = "whatsapp";
const WORKFLOW = "workflow";
const UPDATE_PROMISE_DATE = "UPDATE_PROMISE_DATE";
const UPDATE_PAYMENT_PLAN_DATE = "UPDATE_PAYMENT_PLAN_DATE";
const PORTAL_PAYMENT_PLAN = "PORTAL_PAYMENT_PLAN";
const GENERIC_ACTION = "ACTION_CAPTURE";
const INSTANT_PROCESS_PAYMENT = "instant_payment";
const EDIT_PROCESS_ACTION = "PS_GRP";
const EDIT_CONSUMER_ACTION = "CS_GRP";
export const BANKRUCPTCY = "BNKR";
const INVA_ADJ = "INVA_ADJ";
const REVERSE_PAYMENT_TL = "RV_PAY";

let GenericActionOutCome = ({ data }) => {
  const format = useFormat();
  const {
    currentDefaultFormatDetails,
    referenceData,
    currentOrganization,
    userInfo,
    space,
  } = useContext(MaxyfiContext);
  switch (_.get(data, "content.outcome", "")) {
    case CALL_BACK_LATER: {
      return (
        <>
          <TimelineCondensedWrapper
            {...data}
            icon="callback_later"
            name={_.get(data, "action_source", "")}
            content={`Call Back Later on ${format.date({
              isOrg: true,
              value: _.get(data, "content.callback_date", ""),
            })} at   ${format.rd({
              id: _.get(data, "content.callback_time", ""),
              name: "time",
            })}
             `}
          />
        </>
      );
    }
    case CALL_DISPUTE: {
      const { name, content } = data;
      const orgRef = referenceData?.organizations?.find(
        ({ id }) => id === currentOrganization
      );
      let disputeStatus = referenceData["dispute_status"];
      const disputeInvoices = data?.content?.dispute_invoices
        ?.map((i) => i.toString())
        .toString()
        .replaceAll(",", ", ");

      let refType = referenceData["dispute_type"];
      const timeZone = orgRef?.time_zone;

      const findingStatus = disputeStatus?.find(
        (i) => i.id === data?.content?.dispute_status
      );
      const findingValue = refType?.find(
        (i) => i.id === data?.content?.dispute_type
      );
      const disputeRaisedOn = moment
        .utc(data.executed_on)
        ?.tz(timeZone)
        ?.format(currentDefaultFormatDetails.date_format);

      const timezoneBasedDate = moment
        .utc(data?.content?.dispute_review_date)
        ?.tz(timeZone)
        ?.format(currentDefaultFormatDetails.date_format);
      const subjectBodyDispatch = {
        ...data.content,
        disputeSubject: `${
          _.get(data, "cna", "") ? _.get(data, "cna", "") : "-"
        } : Dispute : ${
          findingValue && findingValue.label ? findingValue.label : "-"
        } Raised on ${disputeRaisedOn}`,
        disputeBody: `Hi,<br /><br />Find below the details of the Dispute Raised by ${
          _.get(data, "cna", "") ? _.get(data, "cna", "") : "-"
        }<br />Dispute Type: ${
          findingValue && findingValue?.label ? findingValue?.label : "-"
        }<br />Dispute Details: ${
          _.get(data, "content.dispute_details", "")
            ? _.get(data, "content.dispute_details", "")
            : "-"
        }<br />Disputed Invoice Numbers: ${
          disputeInvoices ? disputeInvoices : "-"
        }<br /><br />Dispute Status : ${
          findingStatus?.label ? findingStatus?.label : "-"
        }<br />Dispute Review Date : ${
          timezoneBasedDate ? timezoneBasedDate : "-"
        }<br />User Comments :  ${
          data.comment ? data.comment : "-"
        }<br /><br />Regards<br />${
          userInfo && userInfo?.display_name ? userInfo?.display_name : "-"
        }`,
      };

      return (
        <TimelineCondensedWrapper
          {...data}
          icon="dispute"
          name={`Dispute ${
            _.get(data, "content.dispute_status", "") ? "-" : ""
          } ${_.capitalize(_.get(data, "content.dispute_status", ""))}`}
          disputeForward={
            space && space?.role_permissions?.includes(EMAIL_REPLY)
          }
          subjectBodyDispatch={subjectBodyDispatch}
          content={`${
            content.dispute_type
              ? format.rd({ name: "dispute_type", id: content.dispute_type })
              : ""
          } ${_.get(content, "dispute_review_date", "") ? "&" : ""} ${
            _.get(data, "content.dispute_review_date", null)
              ? `Review Date - ${format.date({
                  value: _.get(data, "content.dispute_review_date", null),
                })}`
              : ""
          } (${
            _.get(content, "dispute_invoices", [])
              ? `${_.get(content, "dispute_invoices", []).length} invoice ${
                  _.get(data, "content.amt", null)
                    ? `, ${
                        currencyList[
                          currentDefaultFormatDetails?.default_currency
                        ]
                      } ${format.currency({
                        amount: _.get(data, "content.amt", null),
                      })}`
                    : ""
                }`
              : ""
          })`}
        />
      );
    }
    case FIELDVISIT: {
      return (
        <TimelineCondensedWrapper
          {...data}
          icon="field_visit"
          name={`${_.get(data, "action_source", "")} - Field Visit`}
          content={`Reason - Field Visit`}
        />
      );
    }
    case TL_NOTES: {
      return (
        <TimelineCondensedWrapper
          {...data}
          icon="note"
          name={`${_.get(data, "action_source", "")} - Note`}
          content={`Comment -  ${
            _.get(data, "comment")
              ? _.get(data, "comment").replace(/&amp;/g, "&")
              : " - "
          }`}
        />
      );
    }
    case PAYMENT: {
      const { name = "", action_taken } = data;
      return (
        <TimelineCondensedWrapper
          {...data}
          icon="payment"
          name={`${_.get(data, "action_source", "")} - Payment`}
          content={`${_.get(
            data,
            "content.portal_payment_gateway_name",
            ""
          )} Amount: ${_.get(
            data,
            "content.portal_payment_amount.currency",
            ""
          )} ${format.currency({
            amount: _.get(data, "content.portal_payment_amount.value", 0),
          })}  ${_.get(data, "action_taken", "")}  `}
        />
      );
    }

    case PAYMENT_PLAN: {
      const { name = "", content } = data;

      return (
        <TimelineCondensedWrapper
          {...data}
          icon="promise_to_pay"
          name={`${_.get(data, "action_source", "")} - Payment Plan`}
          content={`${_.get(
            data,
            "content.payment_plan_frequency",
            ""
          )} Payment Plan with Installment amount ${_.get(
            data,
            "content.payment_plan_instalation_amount.currency",
            ""
          )} ${format.currency({
            amount: _.get(
              data,
              "content.payment_plan_instalation_amount.value",
              0
            ),
          })} `}
        />
      );
    }

    case PROMISETOPAY: {
      const { name = "", content } = data;
      const status =
        deliveryStatus && deliveryStatus[content?.promise_status]
          ? deliveryStatus[content?.promise_status]
          : null;

      return (
        <TimelineCondensedWrapper
          {...data}
          icon="promise_to_pay"
          name={`${_.get(data, "action_source", "")} - ${_.get(
            status,
            "title",
            ""
          )}`}
          content={`Promised Payment of ${_.get(
            data,
            "content.promise_amount.currency",
            ""
          )} ${format.currency({
            amount: _.get(data, "content.promise_amount.value", 0),
          })} by ${format.date({
            value: _.get(content, "promise_date", ""),
          })}`}
        />
      );
    }

    default:
      return null;
  }
};
let EditProcessActionOutcome = ({ data }) => {
  return (
    <>
      <TimelineCondensedWrapper
        {...data}
        icon="consumer_group"
        name={_.get(data, "name", "")}
        content={
          _.get(data, "content.grp_act") === "ADD"
            ? `Added to the Group - ID ${_.get(data, "content.a_gid", "-")}`
            : `Remove from the Group - ID ${_.get(data, "content.r_gid", "-")}`
        }
      />
    </>
  );
};

export const TimelineCondensed = (props) => {
  const { type, ...rest } = props;
  const {
    currentDefaultFormatDetails,
    referenceData,
    currentOrganization,
    space,
    userInfo,
  } = useContext(MaxyfiContext);
  const orgRef = _.get(referenceData, "organizations", []).find(
    ({ id }) => id === currentOrganization
  );
  const disableNotes =
    space && !space?.role_permissions?.includes(DISABLE_NOTES);
  // let disputeStatus = referenceData["dispute_status"];
  // const disputeInvoices = props?.content?.dispute_invoices
  //   ?.map((i) => i.toString())
  //   .toString()
  //   .replaceAll(",", ", ");

  // let refType = referenceData["dispute_type"];
  const timeZone = orgRef?.time_zone;

  // const findingStatus = disputeStatus?.find(
  //   (i) => i.id === props?.content?.dispute_status
  // );
  // const findingValue = refType?.find(
  //   (i) => i.id === props?.content?.dispute_type
  // );
  // const disputeRaisedOn = moment
  //   .utc(props?.executed_on)
  //   ?.tz(timeZone)
  //   ?.format(currentDefaultFormatDetails.date_format);

  // const timezoneBasedDate = moment
  //   .utc(props?.content?.dispute_review_date)
  //   ?.tz(timeZone)
  //   ?.format(currentDefaultFormatDetails.date_format);

  // const subjectBodyDispatch = {
  //   ...props.content,
  //   disputeSubject: `${
  //     _.get(props, "cna", "") ? _.get(props, "cna", "") : "-"
  //   } : Dispute : ${
  //     findingValue && findingValue.label ? findingValue.label : "-"
  //   } Raised on ${disputeRaisedOn}`,
  //   disputeBody: `Hi,<br /><br />Find below the details of the Dispute Raised by ${
  //     _.get(props, "cna", "") ? _.get(props, "cna", "") : "-"
  //   }<br />Dispute Type: ${
  //     findingValue && findingValue?.label ? findingValue?.label : "-"
  //   }<br />Dispute Details: ${
  //     _.get(props, "content.dispute_details", "")
  //       ? _.get(props, "content.dispute_details", "")
  //       : "-"
  //   }<br />Disputed Invoice Numbers: ${
  //     disputeInvoices ? disputeInvoices : "-"
  //   }<br /><br />Dispute Status : ${
  //     findingStatus?.label ? findingStatus?.label : "-"
  //   }<br />Dispute Review Date : ${
  //     timezoneBasedDate ? timezoneBasedDate : "-"
  //   }<br />User Comments :  ${
  //     props.comment ? props.comment : "-"
  //   }<br /><br />Regards<br />${
  //     userInfo && userInfo?.display_name ? userInfo?.display_name : "-"
  //   }`,
  // };

  const format = useFormat();

  switch (type) {
    case "ADP":
    case APP_PAYMENT: {
      const { action_taken, name = "", content } = props;
      return (
        <TimelineCondensedWrapper
          {...props}
          icon={"payment"}
          name={`Payment ${action_taken}`}
          content={`${_.get(
            props,
            "content.portal_payment_amount.currency",
            ""
          )} ${format.currency({
            amount: _.get(props, "content.portal_payment_amount.value", ""),
          })}  Via - ${_.get(
            props,
            "content.portal_payment_method",
            ""
          )} - ${action_taken}`}
        />
      );
    }
    case "LGA": {
      return (
        <TimelineCondensedWrapper
          {...props}
          icon={"legal_action"}
          name={`Legal`}
          content={` ${_.get(props, "content.lst", "")} - ${_.get(
            props,
            "content.ltdeb.currency",
            ""
          )} ${format.currency({
            amount: _.get(props, "content.ltdeb.value", ""),
          })}  - ${
            _.get(props, "content.ldef", []).length > 0
              ? _.get(props, "content.ldef", []).length + " " + "Defendants"
              : "-"
          }`}
        />
      );
    }

    case BANKRUCPTCY: {
      const filedDate = props?.content?.bnkfldt
        ? moment
            .utc(props?.content?.bnkfldt)
            ?.tz(timeZone)
            ?.format(currentDefaultFormatDetails.date_format)
        : "";
      return (
        <TimelineCondensedWrapper
          {...props}
          icon={"bankruptcy"}
          name={`Bankruptcy - ${_.get(props, "content.bnkstatus", "")}`}
          content={`Bankruptrcy File on ${filedDate}  ${_.get(
            props,
            "content.bnkchp",
            ""
          )}`}
        />
      );
    }
    case BUSINESS_UNIT: {
      const { name = "", content } = props;
      return (
        <TimelineCondensedWrapper
          {...props}
          icon="business_unit"
          name="Client"
          content={`${name} - Assigned as Client & ${_.get(
            props,
            "content.business_unit_location",
            ""
          )} - Location`}
        />
      );
    }
    case REVERSE_PAYMENT_TL: {
      let reverseAmount = format.currency({
        amount: _.get(props, "content.portal_payment_amount.value", null),
      });
      let positiveFormattedAmount = reverseAmount.replace("-", "");
      return (
        <TimelineCondensedWrapper
          {...props}
          icon="payment"
          name={`Payment Reversed`}
          content={`${currentDefaultFormatDetails?.default_currency}  ${
            _.get(props, "content.portal_payment_amount.value", null)
              ? positiveFormattedAmount
              : ""
          } Via ${_.get(
            props,
            "content.portal_payment_method",
            ""
          )} - Reversed`}
        />
      );
    }
    case CALL: {
      let calloutcome = props && props.content && props?.content?.call_outcome;
      return <CallOutcome data={props} calloutcome={calloutcome} />;
    }
    case FIELDVISIT: {
      const visit_status = _.get(props, "content.visit_status", "");
      let visitOutcome =
        visit_status === FIELD_UNSUCCESSFUL
          ? FIELD_UNSUCCESSFUL
          : _.get(props, "content.visit_outcome", "");
      return <FieldOutCome data={props} visitOutcome={visitOutcome} />;
    }
    case GENERIC_ACTION: {
      return <GenericActionOutCome data={props} />;
    }
    case EDIT_PROCESS_ACTION:
    case EDIT_CONSUMER_ACTION: {
      return <EditProcessActionOutcome data={props} />;
    }

    case CALL_RECORDING: {
      const { content } = props;
      return (
        <TimelineCondensedWrapper
          {...props}
          icon="call_recording"
          name="Call Recording"
          audio={content?.call_detail?.recording_url}
          content={`${
            content?.call_detail?.from_number
              ? `(From: ${content?.call_detail?.from_number})`
              : ""
          }`}
        />
      );
    }

    case CONTACTS: {
      const { name = "", content, action_taken } = props;
      return (
        <TimelineCondensedWrapper
          {...props}
          icon="contacts"
          name={`Contact ${action_taken}`}
          content={`${name} - Contact ${action_taken}`}
        />
      );
    }

    case CUSTOMER_NAME_UPDATE: {
      const { content } = props;
      return (
        <TimelineCondensedWrapper
          {...props}
          icon="contacts_status"
          name="Consumer Updated"
          content={`Consumer Name Changed to ${content?.customer_new_name}`}
        />
      );
    }

    case CUSTOMER_STATUS: {
      const { name = "", content } = props;
      return (
        <TimelineCondensedWrapper
          {...props}
          icon="contacts_status"
          name="Consumer Status"
          content={`${name} -  ${
            _.get(props, "comment")
              ? _.get(props, "comment").replace(/&amp;/g, "&")
              : " - "
          }`}
        />
      );
    }

    case CUSTOMERPORTAL: {
      const { name = "", content } = props;
      return (
        <TimelineCondensedWrapper
          {...props}
          icon={CUSTOMERPORTAL}
          color="#0FB158"
          name="Portal Visit"
          content={`The Consumer Visited the Portal and performed Action - ${
            content && content?.portal_actions.length > 1
              ? content?.portal_actions.map((ac) => ac?.toString())
              : "NIL"
          } `}
        />
      );
    }

    case DISPUTE: {
      const { name = "", content } = props;
      const orgRef = referenceData?.organizations?.find(
        ({ id }) => id === currentOrganization
      );
      let disputeStatus = referenceData["dispute_status"];
      const disputeInvoices = content?.dispute_invoices
        ?.map((i) => i.toString())
        .toString()
        .replaceAll(",", ", ");

      let refType = referenceData["dispute_type"];
      const timeZone = orgRef?.time_zone;

      const findingStatus = disputeStatus?.find(
        (i) => i.id === content.dispute_status
      );
      const findingValue = refType?.find((i) => i.id === content.dispute_type);
      const disputeRaisedOn = moment
        .utc(props.executed_on)
        ?.tz(timeZone)
        ?.format(currentDefaultFormatDetails.date_format);

      const timezoneBasedDate = moment
        .utc(props.content.dispute_review_date)
        ?.tz(timeZone)
        ?.format(currentDefaultFormatDetails.date_format);
      const subjectBodyDispatch = {
        ...props.content,
        type: DISPUTE,
        disputeSubject: `${
          _.get(props, "cna", "") ? _.get(props, "cna", "") : "-"
        } : Dispute : ${
          findingValue && findingValue.label ? findingValue.label : "-"
        } Raised on ${disputeRaisedOn}`,
        disputeBody: `Hi,<br /><br />Find below the details of the Dispute Raised by ${
          _.get(props, "cna", "") ? _.get(props, "cna", "") : "-"
        }<br />Dispute Type: ${
          findingValue && findingValue?.label ? findingValue?.label : "-"
        }<br />Dispute Details: ${
          _.get(props, "content.dispute_details", "")
            ? _.get(props, "content.dispute_details", "")
            : "-"
        }<br />Disputed Invoice Numbers: ${
          disputeInvoices ? disputeInvoices : "-"
        }<br /><br />Dispute Status : ${
          findingStatus?.label ? findingStatus?.label : "-"
        }<br />Dispute Review Date : ${
          timezoneBasedDate ? timezoneBasedDate : "-"
        }<br />User Comments :  ${
          props.comment ? props.comment : "-"
        }<br /><br />Regards<br />${
          userInfo && userInfo?.display_name ? userInfo?.display_name : "-"
        }`,
      };

      return (
        <TimelineCondensedWrapper
          {...props}
          icon="dispute"
          name={`Dispute ${
            _.get(props, "content.dispute_status", "") ? "-" : ""
          } ${_.capitalize(_.get(props, "content.dispute_status", ""))}`}
          disputeForward={
            space && space?.role_permissions?.includes(EMAIL_REPLY)
          }
          subjectBodyDispatch={subjectBodyDispatch}
          content={`${
            content.dispute_type
              ? format.rd({ name: "dispute_type", id: content.dispute_type })
              : ""
          } ${_.get(content, "dispute_review_date", "") ? "&" : ""} ${
            _.get(props, "content.dispute_review_date", null)
              ? `Review Date - ${format.date({
                  value: _.get(props, "content.dispute_review_date", null),
                })}`
              : ""
          } (${
            _.get(content, "dispute_invoices", [])
              ? `${_.get(content, "dispute_invoices", []).length} invoice ${
                  _.get(props, "content.amt", null)
                    ? `, ${
                        currencyList[
                          currentDefaultFormatDetails?.default_currency
                        ]
                      } ${format.currency({
                        amount: _.get(props, "content.amt", null),
                      })}`
                    : ""
                }`
              : ""
          })`}
        />
      );
    }

    case EMAIL: {
      const { name = "", deliverablity_status, content } = props;

      const status =
        deliveryStatus && deliveryStatus[deliverablity_status]
          ? deliveryStatus[deliverablity_status]
          : null;

      return (
        <TimelineCondensedWrapper
          {...props}
          icon="email"
          iconClassName={
            status && status.iconClassName ? status.iconClassName : ""
          }
          name={
            status && status.title
              ? status.title
              : props && props.is_receiver
              ? "Email Reply"
              : ""
          }
          nameClassName={
            status && status.textClassName ? status.textClassName : ""
          }
          content={name}
          isReply={space && space?.role_permissions?.includes(EMAIL_REPLY)}
          // isReplayPermission={space && space?.role_permissions?.includes(SMS_REPLY)}
          maniData={content}
          subData={props}
        />
      );
    }

    case HOLDACTION: {
      return (
        <TimelineCondensedWrapper
          {...props}
          icon="hold_action"
          name="Hold Action"
          content={`Hold Action Till ${
            _.get(props, "content.hold_action_till", "")
              ? format.date({
                  isOrg: true,
                  value: _.get(props, "content.hold_action_till", ""),
                })
              : "-"
          } - for Reason
                 ${format.rd({
                   id: props && props?.content && props?.content?.hold_reason,
                   name: "hold_action_reason",
                 })}`}
        />
      );
    }

    case INVOICE_UPDATE: {
      return (
        <TimelineCondensedWrapper
          {...props}
          icon="invoice_update"
          name="Invoice Edit"
          content={`${_.get(props, "content.invoice_number", "")}`}
        />
      );
    }
    //
    case INSTANT_PAYMENT: {
      const { content } = props;

      const status =
        deliveryStatus && deliveryStatus[content?.promise_status]
          ? deliveryStatus[content?.promise_status]
          : null;
      return (
        <TimelineCondensedWrapper
          {...props}
          icon="promise_to_pay"
          name={`Promise - ${_.get(status, "title", "-")}`}
          content={`Promised Payment of ${_.get(
            props,
            "content.promise_amount.currency",
            ""
          )} ${format.currency({
            amount: _.get(props, "content.promise_amount.value", "-"),
          })} by ${format.date({
            value: _.get(props, "executed_on", "-"),
          })}`}
        />
      );
    }

    case LETTER: {
      const { name = "", content } = props;
      const status =
        deliveryStatus && deliveryStatus[content.delivery_status]
          ? deliveryStatus[content.delivery_status]
          : null;

      return (
        <TimelineCondensedWrapper
          {...props}
          icon="letter"
          name={"Letter"}
          content={`${name} ${
            content && content.delivery_status === "TRANSIT"
              ? "- In Transit"
              : content && content.delivery_status === "RETURNED"
              ? "- Returned"
              : content && content.delivery_status === "FAILED"
              ? "- Failed"
              : "- Delivered"
          }`}
        />
      );
    }

    case NEXTACTION: {
      const { name = "", content } = props;
      const nextActionPlannedOn = moment
        .utc(props?.content?.planned_on)
        ?.tz(timeZone)
        ?.format(currentDefaultFormatDetails.date_format);
      return (
        <TimelineCondensedWrapper
          {...props}
          icon="next_action"
          name="Next Action"
          content={`Next Action -  ${format.rd({
            id: content?.next_action_type,
            name: "workflow_type",
          })} and on ${nextActionPlannedOn}`}
        />
      );
    }

    case NOTES: {
      const { name = "", content } = props;
      return (
        <TimelineCondensedWrapper
          {...props}
          icon="note"
          name={`Notes ${_.get(props, "action_taken", "")}`}
          content={`${name} -  ${
            _.get(props, "comment")
              ? _.get(props, "comment").replace(/&amp;/g, "&")
              : " - "
          }`}
        />
      );
    }

    case "NOTE_ALERT": {
      const { name = "", note_type = "", category = "", notes = "" } = props;
      const notesIcon = {
        INFORMATIONAL: "",
        ALERT: "alert",
        CRITICAL: "",
      };

      return (
        <>
          {disableNotes ? (
            <TimelineCondensedWrapper
              {...props}
              icon={notesIcon[note_type] ? notesIcon[note_type] : "note"}
              name={format.rd({ id: category, name: "notes_category" })}
              containerClassName="co-timeline-condensed_card--alert"
              content={notes.replace(/(<([^>]+)>)/gi, "")}
            />
          ) : (
            <></>
          )}
        </>
      );
    }

    case PAYMENT: {
      const { action_taken } = props;
      return (
        <TimelineCondensedWrapper
          {...props}
          icon="payment"
          name={`Payment ${action_taken}`}
          content={`Call - ${_.get(
            props,
            "content.portal_payment_gateway_name",
            ""
          )} Amount:${_.get(
            props,
            "content.portal_payment_amount.currency",
            ""
          )} ${format.currency({
            amount: _.get(props, "content.portal_payment_amount.value"),
          })}`}
        />
      );
    }

    case PORTAL_PAYMENT: {
      const { content, action_taken } = props;
      return (
        <TimelineCondensedWrapper
          {...props}
          icon="payment"
          color={action_taken == "Success" ? "#0FB158" : "#333860"}
          name={`Payment ${action_taken}`}
          content={`${_.get(
            props,
            "content.portal_payment_amount.currency",
            ""
          )} Via ${content.portal_payment_method} ${action_taken}`}
        />
      );
    }

    case PORTAL_PAYMENT_PLAN: {
      return (
        <TimelineCondensedWrapper
          {...props}
          icon={"promise_to_pay"}
          name="Portal - PPL"
          content={` ${_.get(
            props,
            "content.payment_plan_frequency",
            ""
          )} Payment Plan with Installment Amount ${format.currency({
            amount: _.get(
              props,
              "content.payment_plan_instalation_amount.value"
            ),
          })}starting from ${format.date({
            value: _.get(props, "content.payment_plan_start_date", 0),
            isOrg: true,
          })} to ${format.date({
            isOrg: true,
            value: _.get(props, "content.payment_plan_end_date", 0),
          })} `}
        />
      );
    }

    case RELEASED: {
      return (
        <TimelineCondensedWrapper
          {...props}
          icon="release_action"
          name="Resume Action"
          content={`Resume Consumer Action`}
        />
      );
    }

    case RESCHEDULE_NEXT_ACTION_DATE: {
      return (
        <TimelineCondensedWrapper
          {...props}
          name="WF Rescheduled"
          icon="reschedule"
          content={`Rescheduled Date:${format.date({
            isOrg: true,
            value: _.get(props, "content.reschedule_date", ""),
          })}`}
        />
      );
    }

    case RM_ASSIGNED: {
      return (
        <TimelineCondensedWrapper
          {...props}
          icon={RM_ASSIGNED}
          name="RM Assign :"
          content={`${props && props?.name} assigned as RM`}
        />
      );
    }

    case UNASSIGNED_RM: {
      return (
        <TimelineCondensedWrapper
          {...props}
          icon={RM_ASSIGNED}
          name="RM Unassign :"
          content={`${props && props?.name} Unassigned`}
        />
      );
    }

    case SKIPPEDTIMELINE: {
      const { name = "", content } = props;
      return (
        <TimelineCondensedWrapper
          {...props}
          icon="skip_action"
          iconClassName="mx-icon--path-fill-error"
          name="Skip Action"
          content={name}
        />
      );
    }

    case SMS: {
      const { name = "", content } = props;
      const deliverablity_status =
        content &&
        content.to &&
        Array.isArray(content.to) &&
        content.to[0] &&
        content.to[0].status
          ? content.to[0].status
          : "";
      const status =
        deliveryStatus && deliveryStatus[deliverablity_status]
          ? deliveryStatus[deliverablity_status]
          : null;

      return (
        <TimelineCondensedWrapper
          {...props}
          icon="sms"
          iconClassName={
            status && status.iconClassName ? status.iconClassName : ""
          }
          allContent={props}
          name={
            props && props.is_receiver
              ? "SMS Reply"
              : status && status.title
              ? status.title
              : ""
          }
          nameClassName={
            status && status.textClassName ? status.textClassName : ""
          }
          content={
            // props && props.is_receiver
            //   ? _.get(props, "content.body", "-")
            //   : name
            _.get(props, "content.body", "-")
          }
          isReplayOnly={space && space?.role_permissions?.includes(SMS_REPLY)}
          // isReplayPermission={}
        />
      );
    }

    case UPDATE_PAYMENT_PLAN_DATE:
    case UPDATE_PROMISE_DATE: {
      return (
        <TimelineCondensedWrapper
          {...props}
          icon="promise_to_pay"
          name={`${
            props.type === "UPDATE_PAYMENT_PLAN_DATE"
              ? "Payment Plan"
              : "Promise"
          } Edit`}
          content={
            <>
              Promised Date Changed from{" "}
              <DateComponent
                type={DATE_ORG}
                value={props.content?.planned_date}
              />{" "}
              to{" "}
              <DateComponent
                type={DATE_ORG}
                value={props?.content?.revised_date}
              />{" "}
            </>
          }
        />
      );
    }
    case INSTANT_PROCESS_PAYMENT: {
      const { action_taken } = props;
      return (
        <TimelineCondensedWrapper
          {...props}
          icon="payment"
          name={`Instant Payment`}
          content={`Call - ${_.get(
            props,
            "content.portal_payment_method",
            ""
          )} Amount:${_.get(
            props,
            "content.portal_payment_amount.currency",
            ""
          )} ${format.currency({
            amount: _.get(props, "content.portal_payment_amount.value"),
          })}  ${_.get(props, "action_taken", "")}  `}
        />
      );
    }
    case WHATSAPP: {
      return (
        <TimelineCondensedWrapper
          {...props}
          whatsupData={props}
          icon="whatsapp"
          name={props && props.is_sender ? "WhatsApp" : "WhatsApp - Reply"}
          whatsupIcon={true}
          content={`${
            _.get(props, "is_adhoc_action", "") ? " (QA) " : ""
          } ${_.get(props, "content.body", "")}`}
        />
      );
    }
    case WORKFLOW: {
      const { name = "", is_assigned } = props;
      let assigned =
        is_assigned && is_assigned
          ? `Assigned with Start Date ${
              (_.get(props, "content.workflow_start_date", ""),
              format.date({
                isOrg: true,
                value: _.get(props, "content.workflow_start_date", ""),
              }))
            }`
          : "Unassigned";
      return (
        <TimelineCondensedWrapper
          {...props}
          icon={WORKFLOW}
          name={`WF ${is_assigned && is_assigned ? "Assigned" : "Unassigned"} `}
          content={`${name} - ${assigned} `}
        />
      );
    }
    case WORKFLOW_STRATEGY: {
      const { name = "", is_assigned } = props;
      return (
        <TimelineCondensedWrapper
          {...props}
          icon={WORKFLOW}
          name={`Workflow Strategy`}
          content={`${name}`}
        />
      );
    }
    case "CLARIFICATION_REQUEST_INFO":
    case "DOCUMENT_REQUEST_INFO":
      const { name = "", is_assigned } = props;
      let assigned = `${
        props.type === "CLARIFICATION_REQUEST_INFO"
          ? "Clarification Requested"
          : "Document Requested"
      } ${_.capitalize(
        _.replace(_.get(props, "content.req_info_type", ""), "_", " ")
      )}`;

      // is_assigned && is_assigned
      //   ? `Assigned with Start Date ${
      //       (props?.content?.workflow_start_date,
      //       DateFn(
      //         DATE_ORG,
      //         props && props.content && props.content?.workflow_start_date
      //       ))
      //     }`
      //   : "Unassigned";
      return (
        <TimelineCondensedWrapper
          {...props}
          icon={"request_info"}
          content={`${name} - ${assigned} `}
        />
      );

    // case "INVA_ADJ":
    //   return (
    //     <TimelineCondensedWrapper
    //       {...props}
    //       icon="invoice_update"
    //       name="Invoice Update"
    //       content={`Invoice Update on ${format.date({
    //         isOrg: true,
    //         value: props?.executed_on,
    //       })}`}
    //     />
    //   );

    case INVA_ADJ:
      return <InvAdjCon {...props} />;

    default:
      return <></>;
  }
};

export default function COTimeline(props) {
  const { currentOrganization } = useContext(MaxyfiContext);
  const { customerId } = useParams();
  let dispatch = useDispatch();
  const [page, setPage] = useState(1);
  const [internalAction, setInternalAction] = useState(false);
  let [queryFilter, setQueryFilter] = useState({});
  let { isOpen } = useSelector((e) => e.timelineReducer);

  const initialData = useSelector(
    (s) => s.invoiceOverviewReducer.invoiceDetails
  );
  const customerData = queryClient.getQueryData(
    `${CUSTOMER_OVERVIEW_}${customerId}`
  );

  const timeline = useQuery(
    [
      `${CO_TIMELINE_}${customerId}`,
      {
        filters: queryFilter,
        page,
        internalAction,
      },
    ],
    async ({ queryKey }) => {
      let { filters, page, internalAction } = queryKey[1];
      return await getCustomerOverviewTimelineList({
        organization: currentOrganization,
        id: customerId,
        entity: "customer",
        page_size: 5,
        page,
        ...filters,
        is_hide_internal: !internalAction,
      });
    }
  );

  const timelineData = useMemo(() => {
    const notes = _.get(customerData, "data.doc.alert_notes", []);
    const alertNote =
      notes && notes[0]
        ? [
            {
              ...notes[0],
              type: "NOTE_ALERT",
              note_type: notes[0].type,
              executed_by: notes[0].created_by,
              executed_on: notes[0].created_at,
            },
          ]
        : [];

    return timeline &&
      timeline.data &&
      timeline.data.data &&
      timeline.data.data.docs &&
      Array.isArray(timeline.data.data.docs)
      ? [...alertNote, ...timeline.data.data.docs]
      : [...alertNote];
  }, [timeline.isFetched, timeline.isRefetching, customerData?.data]);

  const updateQueryFilter = (activeFilters) => {
    if (activeFilters.length > 0) {
      setQueryFilter({
        type: [...activeFilters],
      });
    } else {
      setQueryFilter({});
    }
  };

  const setDrawer = (type, data) => {
    if (type) {
      dispatch(setDrawerState({ active: true, type, data }));
    }
  };

  return (
    <div className="co-timeline-section_wrapper">
      {/* SECTION HEADER */}
      <div className="co-timeline-section_header">
        <div className="co-timeline-titile-filter_header">
          <Typography type="h3">Timeline</Typography>
          <ChipFilterNew
            filters={[
              { label: "SMS", value: "sms" },
              { label: "Email", value: "email" },
              { label: "Call", value: "call" },
              { label: "WhatsApp", value: "whatsapp" },
              { label: "Letter", value: "letter" },
              { label: "Next Action", value: "NEXT_ACTION" },
              { label: "Inv Adj", value: "INVA_ADJ" },
              // { label: "Letter", value: "letter" },
              // { label: "Invoice", value: "invoice" },
              // { label: "Payment", value: "payment" },
              // { label: "Dispute", value: "dispute" },
              // { label: "Hold Action", value: "hold_action" },
              // { label: "Skip Action", value: "action_skipped" },
              // { label: "Invoice Update", value: "INVOICE_UPDATE" },
              // { label: "Review Promise", value: "reviewpromise" },
            ]}
            setFilter={updateQueryFilter}
          />
        </div>
        <div className="co-prv-nxt_wraper">
          <CheckBoxBaseweb
            checked={internalAction}
            checkmarkType={STYLE_TYPE.toggle_round}
            labelPlacement={LABEL_PLACEMENT.right}
            onChange={(evt) => setInternalAction(evt.target.checked)}
          >
            <ParagraphSmall style={{ color: "#333860", marginTop: "3px" }}>
              Internal Actions
            </ParagraphSmall>
          </CheckBoxBaseweb>
          <div style={{ display: "flex", alignItems: "baseline", gap: "5px" }}>
            <Icon
              icon="chevron_left_circle"
              isPressable={page > 1}
              onClick={() => {
                if (page > 1) {
                  setPage(page - 1);
                }
              }}
            />
            <Icon
              icon="chevron_right_circle"
              isPressable={
                timeline &&
                timeline.data &&
                timeline.data.data &&
                timeline.data.data.hasNextPage
                  ? true
                  : false
              }
              onClick={() => {
                if (
                  timeline &&
                  timeline.data &&
                  timeline.data.data &&
                  timeline.data.data.hasNextPage
                    ? true
                    : false
                ) {
                  setPage(page + 1);
                }
              }}
            />
          </div>
          <Icon
            icon="enlarge"
            isPressable
            onClick={() => setDrawer(TIMELINE_DRAWER)}
          />
        </div>
      </div>
      {/* SECTION HEADER */}

      <div className="co-timeline-section_body">
        {/* Timeline Row */}
        {timelineData.map((data) => {
          return <TimelineCondensed {...data} isOverView={props.isOverView} />;
        })}
        {/* Timeline Row */}
      </div>
    </div>
  );
}
